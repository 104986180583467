
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const PitchFeedback = () => {
  return (
    <UseCaseTemplate 
      title="Startup Pitch Feedback"
      prompt="Create 5 questions for investors to provide feedback after hearing a startup pitch."
      questions={[
        "What aspects of the business model or value proposition did you find most compelling?",
        "What questions or concerns do you have about the product, market, or strategy that weren't addressed in the pitch?",
        "How would you evaluate the team's ability to execute on this vision based on what you've heard?",
        "What would you suggest as the most important next steps or areas to focus on improving?",
        "What aspects of the pitch presentation itself were effective, and what could be strengthened?"
      ]}
      icon="💡"
      description="Investors offering thoughts naturally. Voice responses capture nuanced feedback and genuine enthusiasm (or concerns) that might be lost in written formats."
      isPersonal={false}
    />
  );
};

export default PitchFeedback;
