
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const HouseCleanerOnboarding = () => {
  return (
    <UseCaseTemplate 
      title="House Cleaner Onboarding"
      prompt="Create 5 questions to understand a house cleaner's experience, schedule preferences, and approach to cleaning."
      questions={[
        "Can you tell me about your experience with residential cleaning and any specialties you have?",
        "What are your preferred days and times for cleaning appointments?",
        "How do you prioritize cleaning tasks when there's limited time available?",
        "What cleaning products do you typically use, and are you open to using client-provided eco-friendly alternatives?",
        "How do you handle special requests or areas that need extra attention?"
      ]}
      icon="🧹"
      description="Quick questions about schedule preferences and prior experience. Voice responses help assess reliability, attention to detail, and communication style."
      isPersonal={true}
    />
  );
};

export default HouseCleanerOnboarding;
