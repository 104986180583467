
import React from "react";
import TestimonialCard from "../components/TestimonialCard.tsx";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs.tsx";

interface BaseTestimonial {
  quote: string;
  author: string;
  role: string;
  avatar: string;
  rating: number;
}

interface PersonalTestimonial extends BaseTestimonial {}

interface ProfessionalTestimonial extends BaseTestimonial {
  company: string;
}

const Testimonials = () => {
  const personalTestimonials: PersonalTestimonial[] = [
    {
      quote: "I found the perfect babysitter for my twins in half the time it usually takes. Being able to hear their voice and how they talk about childcare made all the difference.",
      author: "Jessica T.",
      role: "Parent",
      avatar: "/placeholder.svg",
      rating: 5
    },
    {
      quote: "Collecting family stories from my grandparents has never been easier. They could record at their own pace, and I got authentic memories I'll cherish forever.",
      author: "Michael K.",
      role: "Family Historian",
      avatar: "/placeholder.svg",
      rating: 5
    },
    {
      quote: "As someone looking for a roommate, hearing how potential matches talked about cleanliness and schedules was so much more informative than text messages.",
      author: "Sophia R.",
      role: "Apartment Owner",
      avatar: "/placeholder.svg",
      rating: 4
    }
  ];

  const professionalTestimonials: ProfessionalTestimonial[] = [
    {
      quote: "We've cut our first-round interview time by 70% while actually getting better candidates through to the next round. The voice responses tell us so much about communication skills.",
      author: "David L.",
      role: "Hiring Manager",
      company: "TechFlow Inc.",
      avatar: "/placeholder.svg",
      rating: 5
    },
    {
      quote: "The customer testimonials we collected have transformed our marketing. Real voices sharing real experiences - our conversion rate is up 35% on pages with these audio clips.",
      author: "Rachel M.",
      role: "Marketing Director",
      company: "GrowthBox",
      avatar: "/placeholder.svg",
      rating: 5
    },
    {
      quote: "For UX research, the voice feedback captured nuances we never would have gotten through surveys. You can hear the frustration or excitement that text just can't convey.",
      author: "Alex J.",
      role: "Product Designer",
      company: "UserFirst Design",
      avatar: "/placeholder.svg",
      rating: 4
    }
  ];

  return (
    <section id="testimonials" className="py-16 px-4">
      <div className="container mx-auto max-w-6xl">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">What Our Users Say</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Discover how yourinterviewer has transformed interview experiences for people like you
          </p>
        </div>

        <Tabs defaultValue="all" className="w-full mb-8">
          <TabsList className="grid w-full max-w-md grid-cols-3 mx-auto">
            <TabsTrigger value="all">All</TabsTrigger>
            <TabsTrigger value="personal">Personal</TabsTrigger>
            <TabsTrigger value="professional">Professional</TabsTrigger>
          </TabsList>
          
          <TabsContent value="all" className="mt-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                ...personalTestimonials.slice(0, 1), 
                ...professionalTestimonials.slice(0, 2)
              ].map((testimonial, index) => {
                // Check if it's a professional testimonial with company property
                const isProfessional = 'company' in testimonial;
                
                return (
                  <TestimonialCard
                    key={index}
                    quote={testimonial.quote}
                    author={testimonial.author}
                    role={testimonial.role}
                    company={isProfessional ? (testimonial as ProfessionalTestimonial).company : undefined}
                    avatar={testimonial.avatar}
                    rating={testimonial.rating}
                  />
                );
              })}
            </div>
          </TabsContent>
          
          <TabsContent value="personal" className="mt-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {personalTestimonials.map((testimonial, index) => (
                <TestimonialCard
                  key={index}
                  quote={testimonial.quote}
                  author={testimonial.author}
                  role={testimonial.role}
                  avatar={testimonial.avatar}
                  rating={testimonial.rating}
                />
              ))}
            </div>
          </TabsContent>
          
          <TabsContent value="professional" className="mt-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {professionalTestimonials.map((testimonial, index) => (
                <TestimonialCard
                  key={index}
                  quote={testimonial.quote}
                  author={testimonial.author}
                  role={testimonial.role}
                  company={testimonial.company}
                  avatar={testimonial.avatar}
                  rating={testimonial.rating}
                />
              ))}
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </section>
  );
};

export default Testimonials;
