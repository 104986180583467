import './InterviewLinkEditor.css';
import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FaCaretLeft, FaCreditCard, FaMicrophone, FaPaperclip, FaPaperPlane, FaPlus, FaTimes } from 'react-icons/fa'; // Add this import for the microphone icon
import ElevenLabsWidget from './components/ElevenLabsWidget';
import ElevenLabsModal from './components/ElevenLabsModal';
import VoiceAgentPreview from './components/VoiceAgentPreview';
import { getAgentFromReferenceId, addInterviewLinkQuestion, updateInterviewLinkQuestion, getInterviewLinkInvites, addInterviewLinkInvite, updateInterviewLinkInvite, sendInterviewLinkInvites, updateInterviewLink, getPaymentMethods, checkHasPaymentMethod, getAvailableFreeCredits,
  purchaseInterviewLink
 } from '../../utils/api';
import { CheckCircle } from 'lucide-react'; // Import the success icon

const InterviewLinkEditor = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Extract the id from the query string
  const [agentId, setAgentId] = useState(id); // Set the state variable to the id
  const [agentParams, setAgentParams] = useState({
    title: '',
    description: '',
    systemPrompt: '',
    userPrompt: '',
    temperature: 0.7,
  });
  const [questions, setQuestions] = useState([
    'What is your greatest strength?',
    'Can you describe a challenging situation and how you handled it?',
    'Where do you see yourself in five years?',
  ]);
  const [agent, setAgent]  = useState(null);
  const [agentTitle, setAgentTitle] = useState('');
  const [agentDescription, setAgentDescription] = useState('');
  const [status, setStatus] = useState('');
  const [isFadingIn, setIsFadingIn] = useState(false);
  const [showRecipients, setShowRecipients] = useState(false);
  const [recipients, setRecipients] = useState([{ name: '', email: '' }]);
  const [showModal, setShowModal] = useState(false);
  const [modalStatus, setModalStatus] = useState('');
  const [newQuestionIndex, setNewQuestionIndex] = useState(null);
  const [selectedVoice, setSelectedVoice] = useState('Default');
const [scriptLoaded, setScriptLoaded] = useState(false);
const [showWidget, setShowWidget] = useState(false);
const [existingRecipients, setExistingRecipients] = useState([]);
const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
const [newQuestionText, setNewQuestionText] = useState('');
const [showAddRecipientModal, setShowAddRecipientModal] = useState(false);
const [newRecipient, setNewRecipient] = useState({ name: '', email: '' });
const [submittingQuestion, setSubmittingQuestion] = useState(false);
const [submittingRecipient, setSubmittingRecipient] = useState(false); 
const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
const [questionToDelete, setQuestionToDelete] = useState(null);
const [showSendConfirmation, setShowSendConfirmation] = useState(false);
const [showSuccessModal, setShowSuccessModal] = useState(false);
const [submitting, setSubmitting] = useState(false);
const [submittingAgent, setSubmittingAgent] = useState(false);  
const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
const [paymentMethods, setPaymentMethods] = useState([]);
const [loaded, setLoaded] = useState(false);
const [loadedPaymentCheck, setLoadedPaymentCheck] = useState(false);
const [showBulkImportModal, setShowBulkImportModal] = useState(false);
const [bulkEmails, setBulkEmails] = useState('');
const [submittingBulkImport, setSubmittingBulkImport] = useState(false);
const [freeCredits, setFreeCredits] = useState(0);
const [showError, setShowError] = useState(false);
const [errorMessage, setErrorMessage] = useState('');
const [couponCode, setCouponCode] = useState('');

async function loadInvites(){
  let res = await getInterviewLinkInvites(agentId);

  if (res.success) {
    setExistingRecipients(res.data);
  }

  else{
    alert('err');
  }
}

async function loadFreeCredits() {
  const res = await getAvailableFreeCredits(); 
  if (res.success) {
    setFreeCredits(res.data);
  } else {
    setFreeCredits(0);
  }
}

useEffect(() => {
    // get source from query string

    async function loadPaymentMethod(){
      let res = await checkHasPaymentMethod();

      if (res.success){
        setHasPaymentMethod(res.data);
        setLoadedPaymentCheck(true);
      }
    }

    async function loadPaymentMethods(){
      let res = await getPaymentMethods();
      
      if (res.success){
        setPaymentMethods(res.data);
      }

      setLoaded(true);
    }

    loadPaymentMethod();
    loadPaymentMethods();
  }, []);

  useEffect(() => {
    async function loadAgent() {
      // Logic to load agent using agentId
      let res = await getAgentFromReferenceId(agentId);

      if (res.success) {
        setAgent(res.data);
        setAgentTitle(res.data.title);
        setAgentDescription(res.data.description);
        setQuestions(res.data.questions);
        setStatus(res.data.status);
      }

      else{
        alert('err');
      }
    }

    async function loadInvites(){
      let res = await getInterviewLinkInvites(agentId);

      if (res.success) {
        setExistingRecipients(res.data);
      }

      else{
        alert('err');
      }
    }

    async function loadFreeCredits() {
      const res = await getAvailableFreeCredits(); 
      if (res.success) {
        setFreeCredits(res.data);
      } else {
        setFreeCredits(0);
      }
    }

    setIsFadingIn(true);
    loadAgent();
    loadInvites();
    loadFreeCredits();
  }, [agentId]);

  const handleParamChange = (key, value) => {
    setAgentParams({ ...agentParams, [key]: value });
  };

  const handleVoiceChange = (voice) => {
    setSelectedVoice(voice);
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = { ...updatedQuestions[index], question_text: value };
    setQuestions(updatedQuestions);

    // Show the 'Save' button for the specific question
    const questionElements = document.querySelectorAll('.question-group');
    const saveButton = questionElements[index]?.querySelector('.save-question-btn');
    if (saveButton) {
      saveButton.style.display = 'inline-block';
    }
  };

  const handleSaveQuestionChange = async (index, interview_link_question_id) => {
    const updatedQuestion = questions[index];
    let res = await updateInterviewLinkQuestion(interview_link_question_id, agentId, { question_text: updatedQuestion.question_text });

    if (res.success) {
      // Hide the 'Save' button after successful save
      const questionElements = document.querySelectorAll('.question-group');
      const saveButton = questionElements[index]?.querySelector('.save-question-btn');
      if (saveButton) {
        saveButton.style.display = 'none';
      }
    } else {
      alert('Error saving question.');
    }
  };

  const confirmDeleteQuestion = (index, interview_link_question_id) => {
    setQuestionToDelete({ index, interview_link_question_id });
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirmed = async () => {
    if (questionToDelete) {
      const { index, interview_link_question_id } = questionToDelete;
      let res = await updateInterviewLinkQuestion(interview_link_question_id, agentId, { is_active: false });

      if (res.success) {
        const questionElement = document.querySelectorAll('.question-group')[index];
        if (questionElement) {
          questionElement.classList.add('fade-out');
          setTimeout(() => {
            const updatedQuestions = questions.filter((_, i) => i !== index);
            setQuestions(updatedQuestions);
          }, 500); // Match the duration of the fade-out animation
        }
      } else {
        alert('Error deleting question.');
      }
      setShowDeleteConfirmation(false);
      setQuestionToDelete(null);
    }
  };

  const addRecipient = () => {
    setShowAddRecipientModal(true);
  };

  const handleSaveRecipient = async () => {
    if (newRecipient.name.trim() && newRecipient.email.trim()) {
      setSubmittingRecipient(true);
      let res = await addInterviewLinkInvite(agentId, newRecipient.name, newRecipient.email);

      if (res.success) {
        setNewRecipient({ name: '', email: '' });
        setShowAddRecipientModal(false);

        // Refresh the list of recipients
        const updatedRecipients = await getInterviewLinkInvites(agentId);
        if (updatedRecipients.success) {
          setExistingRecipients(updatedRecipients.data);
          setSubmittingRecipient(false);
        } else {
          alert('Failed to refresh recipients list.');
        }
      } else {
        alert('Error adding recipient.');
      }
    } else {
      alert('Both name and email are required.');
    }
  };

  const moveQuestion = (index, direction) => {
    const newQuestions = [...questions];
    const targetIndex = index + direction;

    if (targetIndex >= 0 && targetIndex < newQuestions.length) {
      [newQuestions[index], newQuestions[targetIndex]] = [newQuestions[targetIndex], newQuestions[index]];
      setQuestions(newQuestions);

      // Add animation class to the moved question
      const questionElements = document.querySelectorAll('.question-group');
      questionElements[targetIndex].classList.add('move-highlight');
      setTimeout(() => {
        questionElements[targetIndex].classList.remove('move-highlight');
      }, 500); // Match the duration of the animation
    }
  };

  const handleSelectRecipients = () => {
    const questionsSection = document.querySelector('.questions-section');
    if (questionsSection) {
      questionsSection.classList.add('fade-out');
      setTimeout(() => setShowRecipients(true), 500); // Match the fade-out duration
    }
  };

  const handleBackToQuestions = () => {
    const recipientsSection = document.querySelector('.recipients-section');
    if (recipientsSection) {
      recipientsSection.classList.add('fade-out');
      setTimeout(() => {
        setShowRecipients(false);
        const questionsSection = document.querySelector('.questions-section');
        if (questionsSection) {
          questionsSection.classList.remove('fade-out');
          questionsSection.classList.add('fade-in');
        }
      }, 500); // Match the fade-out duration
    }
  };

  const handleDeleteExistingRecipient = async (index, interview_link_invite_id) => {
    let res = await updateInterviewLinkInvite(interview_link_invite_id, {is_active: false});

    if (res.success){
    const updatedRecipients = existingRecipients.filter((_, i) => i !== index);
    setExistingRecipients(updatedRecipients);
    }
    else{
      alert('err');
    }
  };

  const handleAddQuestion = () => {
    setShowAddQuestionModal(true);
  };

  const handleSaveQuestion = async () => {
    if (newQuestionText.trim()) {
      setSubmittingQuestion(true);

      let res = await addInterviewLinkQuestion(id, newQuestionText);
      setQuestions([...questions, { question_text: newQuestionText }]);
      setNewQuestionText('');
      setShowAddQuestionModal(false);
      setSubmittingQuestion(false);
    } else {
      alert('Question text cannot be empty.');
    }
  };

  const confirmSendInterviewLink = () => {
    setShowSendConfirmation(true);
  };

  const calculateCost = () => {
    const pendingRecipients = existingRecipients.filter(item => item.status === 'Pending').length;
    return (pendingRecipients * 0.5).toFixed(2); // Calculate cost at $0.50 per recipient
  };

  const calculateFinalCost = () => {
    const pendingRecipients = existingRecipients.filter(item => item.status === 'Pending').length;
    const freeEmailsUsed = Math.min(freeCredits, pendingRecipients);
    const remainingRecipients = pendingRecipients - freeEmailsUsed;
    return (remainingRecipients * 0.5).toFixed(2); // Calculate cost at $0.50 per remaining recipient
  };

  const handleSendConfirmed = async () => {
    setSubmitting(true);
    setModalStatus('Sending...');
    let res = await sendInterviewLinkInvites(agentId, couponCode);

    if (res.success) {
    setTimeout(() => {
      setModalStatus('');
      setShowSendConfirmation(false);
      setSubmitting(false);
      loadInvites(); // Refresh the list of recipients  
      setShowSuccessModal(true); // Show success modal after sending
      loadFreeCredits();
    }, 1000); // Simulate a delay for sending
  }

  else{
    setSubmitting(false);
    setShowError(true);
    setErrorMessage(res.message);
  }
  };

  async function handleUpdateAgent(){
    setSubmittingAgent(true);

    let res = await updateInterviewLink(agent.interview_link_id, {title: agentTitle, description: agentDescription});
    
    if (res.success) {
      setSubmittingAgent(false);
    }
    else {
      setSubmittingAgent(false);
      alert('Error updating agent.');
    }
  }

  const handleBulkImport = async () => {
    if (bulkEmails.trim()) {
      setSubmittingBulkImport(true);
      const emailList = bulkEmails.split('\n').map(email => email.trim()).filter(email => email);
      const promises = emailList.map(email => addInterviewLinkInvite(agentId, '', email)); // Add recipients without names
      const results = await Promise.all(promises);
  
      if (results.every(res => res.success)) {
        setBulkEmails('');
        setShowBulkImportModal(false);
        loadInvites(); // Refresh the list of recipients
      } else {
        alert('Error importing some emails.');
      }
      setSubmittingBulkImport(false);
    } else {
      alert('Please enter at least one email.');
    }
  };

  async function handleApplyCoupon(){

  }

  return (
    <div className={`interview-editor-container ${isFadingIn ? 'fade-in' : ''}`}>
      {/* Left Navigation */}
      <div className="left-nav">
        <div>
          <Link className='link' to="/interviewlink/list">
            <FaCaretLeft /> Back to Interview Links
          </Link>
        </div>

        <h2>Agent Parameters</h2>
        <div className="param-group">
          <label>Title</label>
          <input
            type="text"
            value={agentTitle}
            placeholder="Enter the title of the interview"
            onChange={(e) => setAgentTitle(e.target.value)}
          />
        </div>
        <div className="param-group">
          <label>Description</label>
          <textarea
          style={{minHeight: '170px'}}
            value={agentDescription}
            placeholder="Provide a brief description of the interview"
            onChange={(e) => setAgentDescription(e.target.value)}
          />
        </div>
       
        <div className="param-group">
          <label>Character Voice</label>
          <select
            value={selectedVoice}
            onChange={(e) => handleVoiceChange(e.target.value)}
          >
            <option value="Default">Default</option>
            <option value="Friendly">Friendly</option>
            <option value="Professional">Professional</option>
            <option value="Casual">Casual</option>
          </select>
        </div>

        <div className='mt-6'>
          <button className="py-3 px-4 w-full text-center align-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-hidden focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none" onClick={handleUpdateAgent}
          disabled={submittingAgent}>
            {submittingAgent ? (
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"></path>
                </svg>
              ) : (
                'Update'
              )}
          </button>
        </div>

          {agent != null && (
          <div>
            <VoiceAgentPreview agentId={agent.agent_id} isOpen={showModal} onClose={() => setShowModal(false)} />
          </div>
          )}
            {/* <ElevenLabsModal setShowModal={setShowModal} agent_id={agent.agent_id} /> */}


      </div>

      {/* Right Content */}
      <div className="right-content relative">

        <div className="mb-2">
          {status == 'Draft' && (
            <span className="interviewlinklist__status--active inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-green-600/20 ring-inset">Draft</span>
          )}

          {status == 'Published' && (
            <span className="interviewlinklist__status--active inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-green-600/20 ring-inset">Published</span>
          )}

          {freeCredits > 0 && (
            <span className="ml-2 inline-flex items-center rounded-full bg-blue-100 px-3 py-1 text-xs font-medium text-blue-800">
              {freeCredits} Free Credits
            </span>
            )}
        </div>
       
       <div className='absolute right-0 top-0 mt-4 mr-4'>
       <div className="preview-button-container flex items-center gap-2">
        <button onClick={() => navigate('/interviewlink')} className='preview-btn'>
          <FaPlus /> New
        </button>

          <button className="preview-btn ml-4" onClick={() => setShowModal(true)}>
            <FaMicrophone className="microphone-icon" /> Preview
          </button>
        </div>
       </div>

        <div className={`questions-section ${isFadingIn ? 'fade-in' : ''}`}>
          <h2>Interview Questions - {`(${questions.length})`}</h2>

          <p>What questions would you like your agent to ask?</p>

          {questions.map((question, index) => (
            <div
              key={index}
              className={`question-group ${newQuestionIndex === index ? 'fade-in' : ''}`}
            >
              <textarea
                value={question.question_text}
                onChange={(e) => handleQuestionChange(index, e.target.value)}
                placeholder={`Question ${index + 1}`}
              />
              <div className="question-menu">
                <button
                  className="drag-up-btn"
                  onClick={() => moveQuestion(index, -1)}
                  disabled={index === 0}
                >
                  ↑
                </button>
                <button
                  className="drag-down-btn"
                  onClick={() => moveQuestion(index, 1)}
                  disabled={index === questions.length - 1}
                >
                  ↓
                </button>
                <button
                  className="delete-question-btn"
                  onClick={() => confirmDeleteQuestion(index, question.interview_link_question_id)}
                >
                  Delete
                </button>
                <button
                  className="save-question-btn"
                  style={{ display: 'none' }}
                  onClick={() => handleSaveQuestionChange(index, question.interview_link_question_id)}
                >
                  Save
                </button>
              </div>
            </div>
          ))}
          <button className="preview-btn" onClick={handleAddQuestion}>
            + Add Question
          </button>

          <div className='mt-4'>
          <button
            className="py-3 px-4 w-full text-center align-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-hidden focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            onClick={handleSelectRecipients}
          >
            Step 2: Select Recipients
          </button>
          </div>
        </div>

        {showAddQuestionModal && (
          <>
          <div className='overlay'></div>

          <div className="modal">
            <div className="">
              <h3>Add New Question</h3>
              <textarea
                value={newQuestionText}
                onChange={(e) => setNewQuestionText(e.target.value)}
                placeholder="Enter your question here"
                style={{ width: '100%', padding: '10px', minHeight: '100px', marginBottom: '10px' }}
              />

              <button className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-hidden focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none" 
              disabled={submittingQuestion}
              onClick={handleSaveQuestion}>
                {submittingQuestion ? (
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"></path>
                </svg>
              ) : (
                'Add Question'
              )}
              </button>

              <button className="cancel-btn" onClick={() => setShowAddQuestionModal(false)}>
                Cancel
              </button>
            </div>
          </div>
          </>
        )}

        {showRecipients && (
          <div className="recipients-section fade-in mt-2">
                        <button className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-2xs hover:bg-gray-50 focus:outline-hidden focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none" onClick={handleBackToQuestions}>
              <FaCaretLeft /> Back to Questions
            </button>

            {/* Existing Recipients Section */}
            {existingRecipients.length > 0 ? (
              <div className="existing-recipients">
                <h3>Recipient list - {existingRecipients.length}</h3>

                <p>
                  Who would you like to send this interview link to? You can add multiple recipients.
                </p>

                <table className="recipients-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {existingRecipients.map((recipient, index) => (
                      <tr key={index}>
                        <td>{recipient.name}</td>
                        <td>{recipient.email}</td>
                        <td>
                          {recipient.status === 'Pending' ? (
                            <span>Not Sent</span>
                          ) : (
                            <span className="status-badge">{recipient.status}</span>
                          )}

                        </td>
                        <td>
                          <button
                            className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-red-600/10 ring-inset"
                            onClick={() => handleDeleteExistingRecipient(index, recipient.interview_link_invite_id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                  <h3>Recipient list</h3>

                  <p>
                    Who would you like to send this interview link to? You can add multiple recipients.
                  </p>

                  <p className='mt-10 text-bold font-bold'>No recipients added yet.</p>
              </>
            )}

            <div className='mt-2 flex gap-4 items-center'>
            <button className="preview-btn" onClick={addRecipient} disabled={submittingRecipient}>
            {submittingRecipient ? (
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"></path>
                </svg>
              ) : (
                <>
                  <span><FaPlus /> Add Recipient</span>
                </>
              )}
            </button>

            <button className="preview-btn ml-2" onClick={() => setShowBulkImportModal(true)}>
              <FaPaperclip /> Bulk Import
            </button>
            </div>

            {existingRecipients.length > 0 && (
            <div className='mt-4'>
            <button className="py-3 px-4 w-full text-center align-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-hidden focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none" onClick={confirmSendInterviewLink}>
              Send Interview Link <FaPaperPlane />
            </button>
              </div>
            )}
          </div>
        )}

        {showAddRecipientModal && (
          <>
          <div className='overlay'></div>
            <div className="modal align-left text-left">
              <h3>Add New Recipient</h3>
              <div className="form-group">
                <label htmlFor="recipient-name">Name</label>
                <input
                  id="recipient-name"
                  type="text"
                  value={newRecipient.name}
                  onChange={(e) => setNewRecipient({ ...newRecipient, name: e.target.value })}
                  placeholder="Enter recipient's name"
                  style={{ width: '100%', marginBottom: '10px', padding: '10px' }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="recipient-email">Email</label>
                <input
                  id="recipient-email"
                  type="email"
                  value={newRecipient.email}
                  onChange={(e) => setNewRecipient({ ...newRecipient, email: e.target.value })}
                  placeholder="Enter recipient's email"
                  style={{ width: '100%', marginBottom: '10px', padding: '10px' }}
                />
              </div>

              <div className='mt-2'>
              <button className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-hidden focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none" onClick={handleSaveRecipient}>
                <FaPlus /> Add Recipient
              </button>
              </div>

              <button className="cancel-btn" onClick={() => setShowAddRecipientModal(false)}>
                Cancel
              </button>
            </div>
          </>
        )}

        {showBulkImportModal && (
          <>
            <div className='overlay'></div>
            <div className="modal align-left text-left">
              <h3>Bulk Import Recipients</h3>
              <p>Paste a list of email addresses, one per line:</p>
              <textarea
                value={bulkEmails}
                onChange={(e) => setBulkEmails(e.target.value)}
                placeholder="example1@example.com\nexample2@example.com"
                style={{ width: '100%', padding: '10px', minHeight: '150px', marginBottom: '10px' }}
              />
              <div className='mt-2'>
                <button
                  className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-hidden focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                  onClick={handleBulkImport}
                  disabled={submittingBulkImport}
                >
                  {submittingBulkImport ? (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"></path>
                    </svg>
                  ) : (
                    'Import Recipients'
                  )}
                </button>
                <button className="cancel-btn ml-2" onClick={() => setShowBulkImportModal(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </>
        )}

        {showDeleteConfirmation && (
          <>
            <div className="overlay"></div>
            <div className="modal">
              <h3>Confirm Deletion</h3>
              <p>Are you sure you want to delete this question?</p>
              <button
                className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 focus:outline-hidden focus:bg-red-700"
                onClick={handleDeleteConfirmed}
              >
                Confirm
              </button>
              <button
                className="cancel-btn"
                onClick={() => setShowDeleteConfirmation(false)}
              >
                Cancel
              </button>
            </div>
          </>
        )}

        {showSendConfirmation && (
          <>
            <div className="overlay"></div>
            <div className="modal">
              <h3>Confirm Sending</h3>
              <p>Review the recipient breakdown below.</p>

              <div className="checkout-preview">
                <table className="checkout-table">
                  <tbody>
                    <tr>
                      <td>Number of Recipients:</td>
                      <td>{existingRecipients.filter(item => item.status === 'Pending').length}</td>
                    </tr>
                    <tr>
                      <td>Cost per Email:</td>
                      <td>$0.50</td>
                    </tr>
                    {freeCredits > 0 && (
                      <tr>
                        <td>Free Emails Applied:</td>
                        <td>{Math.min(freeCredits, existingRecipients.filter(item => item.status === 'Pending').length)}</td>
                      </tr>
                    )}
                    <tr className='border-t'>
                      <td><strong>Final Cost:</strong></td>
                      <td><strong>${calculateFinalCost()}</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Coupon Code Field */}
              <div className="mt-4 flex items-center gap-2">
                <input
                  type="text"
                  placeholder="Enter coupon code (optional)"
                  className="border rounded-md px-3 py-2 w-full"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                />
                <button
                  className="py-2 px-4 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-hidden focus:bg-blue-700"
                  onClick={handleApplyCoupon}
                >
                  Apply
                </button>
              </div>

                {loadedPaymentCheck && existingRecipients.length > 0 && parseFloat(calculateFinalCost()) === 0 && (
                  <p className='mt-4' style={{fontSize: '12px', fontStyle: 'italic', color: 'green'}}>Your free credits cover the total cost. No payment is required.</p>
                )}

              <div className='mt-8'>
                {loadedPaymentCheck && hasPaymentMethod && existingRecipients.length > 0 && (
                  <>
                    <button
                      className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-hidden focus:bg-blue-700"
                      onClick={handleSendConfirmed}
                      disabled={submitting}
                    >
                      {submitting ? (
                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"></path>
                        </svg>
                      ) : (
                        'Purchase and Send'
                      )}
                    </button>

                    {showError && (
                      <div className="error-message mt-4 text-red-600">
                        {errorMessage}
                      </div>
                    )}

                    <div style={{fontSize: '11px', fontStyle: 'italic', marginTop: '10px'}}>
                      By clicking "Purchase and Send", you are agreeing to be charged the final amount shown above. You also agree to our <Link to="/terms" className="text-blue-600">Terms of Service</Link> and <Link to="/privacy" className="text-blue-600">Privacy Policy</Link>.
                    </div>
                  </>
                )}

                {loadedPaymentCheck && !hasPaymentMethod && parseFloat(calculateFinalCost()) > 0 && (
                  <>
                    <span>
                      <Link to='/account/payment-method'  className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-hidden focus:bg-blue-700">
                        <FaCreditCard /> Add Payment Method
                      </Link>
                    </span>
                  
                  </>
                )}

              {loadedPaymentCheck && !hasPaymentMethod && parseFloat(calculateFinalCost()) == 0 && (
                  <>
                    <span>
                    <button
                      className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-hidden focus:bg-blue-700"
                      onClick={handleSendConfirmed}
                      disabled={submitting}
                    >
                      {submitting ? (
                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"></path>
                        </svg>
                      ) : (
                        'Confirm and Send'
                      )}
                    </button>
                    </span>
                  
                  </>
                )}

              <button
                className="cancel-btn"
                onClick={() => {
                  setShowSendConfirmation(false);
                  setShowError(false);
                  setErrorMessage('');
                }}
              >
                Cancel
              </button>
              </div>
            </div>
          </>
        )}

        {showSuccessModal && (
          <>
            <div className="overlay"></div>
            <div className="modal center">
              <CheckCircle size={48} color="green" />
              <h3>Success!</h3>
              <p>Your interview link has been sent successfully.</p>
              <button
                className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-green-600 text-white hover:bg-green-700 focus:outline-hidden focus:bg-green-700"
                onClick={() => setShowSuccessModal(false)}
              >
                Close
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InterviewLinkEditor;