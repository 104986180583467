import React from 'react';
import { useState, useEffect } from 'react';
import {Link, useParams} from 'react-router-dom';
import './InterviewLinkInvite.css';
import ElevenLabsModal from './components/ElevenLabsModal';
import { getInterviewLinkInvite, saveConversationId } from '../../utils/api';
import { FaMicrophone } from 'react-icons/fa';
import VoiceAgentSession from './components/VoiceAgentSession';

const InterviewLinkInvite = () => {
  const [showModal, setShowModal] = useState(false);
  const [sessionToken, setSessionToken] = useState('');
  const [invite, setInvite] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (id) {

      async function fetchInvite() {
        const res = await getInterviewLinkInvite(id);
        if (res.success) {
          if (res.data != null){
            setInvite(res.data);
            setIsValid(true);
          }

          else{
            setIsValid(false);
          }
        } else {
          alert('Error fetching interview link invite');
        }
      }
      
      fetchInvite();
      setSessionToken(id);
    }
  }, [id]);

    return (
        <div className="interview-card">
          {isValid && (
            <div className="interview-content">
                <h2>{invite.title}</h2>

                <p className="interview-description text-sm">{invite.description}</p>

                <p>
                  You have been invited to take a quick interview. Please click the button below to get started.
                </p>

                <div>
                  <VoiceAgentSession agentId={invite.agent_id} session_token={id} />
                </div>

                {/* <button className="get-started-btn" onClick={() => setShowModal(true)}><FaMicrophone /> Get Started</button> */}
            </div>
          )}

            {!isValid && (
              <div className="interview-content">
                <h2>Invalid Invite</h2>
                <p>The invite link you used is invalid or has expired.</p>
              </div>
            )}

            {showModal && (
              <ElevenLabsModal setShowModal={setShowModal} agent_id={invite.agent_id} />
            )}
        </div>
    );
};

export default InterviewLinkInvite;
