
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const ClientDiscovery = () => {
  return (
    <UseCaseTemplate 
      title="Client Discovery Call Alternative"
      prompt="Create 5 questions to understand a new client's needs and goals for a website redesign project."
      questions={[
        "What are the main business objectives you're hoping to achieve with this website redesign?",
        "What aspects of your current website are you most dissatisfied with, and why?",
        "How would you describe your ideal customer, and how should the website serve their specific needs?",
        "What are 2-3 competitor or inspiration websites you admire, and what specifically do you like about them?",
        "How will you measure the success of this redesign six months after launch?"
      ]}
      icon="🤝"
      description="Asynchronous insights in the client's voice. Voice responses allow clients to express needs and vision naturally, while giving you flexibility to review outside of scheduled meetings."
      isPersonal={false}
    />
  );
};

export default ClientDiscovery;
