import { useState, useEffect, useCallback } from "react";
import { getUserSubscriptions } from "../utils/api";

const useSubscriberStatus = () => {
  const [subscriberStatus, setSubscriberStatus] = useState(null);
  const [subscriberLoading, setSubscriberLoading] = useState(false);
  const [subscriberError, setSubscriberError] = useState(null);
  const [subscriberData, setSubscriberData] = useState(null);

  const fetchSubscriberStatus = useCallback(async () => {
    setSubscriberLoading(true);
    setSubscriberError(null);

    try {
      const response = await getUserSubscriptions();

      if (response.success){
        setSubscriberStatus(response.data.status); // Adjust based on your API response
        setSubscriberData(response.data);
      }
    } catch (err) {
      setSubscriberError(err.message || "Failed to fetch subscriber status");
    } finally {
      setSubscriberLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSubscriberStatus();
  }, [fetchSubscriberStatus]);

  return { subscriberStatus, subscriberLoading, subscriberData, subscriberError, refetch: fetchSubscriberStatus };
};

export default useSubscriberStatus;
