
import React from "react";
import {Button} from "../components/ui/button.tsx";
import { Mic, Menu, X } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";

interface NavbarProps {
  onUseCasesClick?: () => void;
  onFeaturesClick?: () => void;
  onHowItWorksClick?: () => void;
  onTestimonialsClick?: () => void;
}

const Navbar = ({ 
  onUseCasesClick, 
  onFeaturesClick, 
  onHowItWorksClick, 
  onTestimonialsClick 
}: NavbarProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleUseCasesClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (onUseCasesClick) {
      onUseCasesClick();
      setIsMenuOpen(false);
    }
  };

  const handleFeaturesClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (onFeaturesClick) {
      onFeaturesClick();
      setIsMenuOpen(false);
    }
  };

  const handleHowItWorksClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (onHowItWorksClick) {
      onHowItWorksClick();
      setIsMenuOpen(false);
    }
  };

  const handleTestimonialsClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (onTestimonialsClick) {
      onTestimonialsClick();
      setIsMenuOpen(false);
    }
  };

  return (
    <nav className="fixed top-0 w-full bg-white/80 backdrop-blur-md z-50 shadow-sm">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <Mic className="h-6 w-6 text-brand-purple mr-2" />
          <span className="font-bold text-xl">yourinterviewer</span>
        </Link>
        
        {/* Desktop menu */}
        <div className="hidden md:flex items-center space-x-6">
          <a href="#" onClick={handleFeaturesClick} className="text-gray-600 hover:text-brand-purple transition-colors">
            Features
          </a>
          <a href="#" onClick={handleUseCasesClick} className="text-gray-600 hover:text-brand-purple transition-colors">
            Use Cases
          </a>
          <Link to="/pricing" className="text-gray-600 hover:text-brand-purple transition-colors">
            Pricing
          </Link>
          <a href="#" onClick={handleHowItWorksClick} className="text-gray-600 hover:text-brand-purple transition-colors">
            How It Works
          </a>
          <a href="#" onClick={handleTestimonialsClick} className="text-gray-600 hover:text-brand-purple transition-colors">
            Testimonials
          </a>
          <Button variant="outline" asChild className="mr-2">
            <Link to="/login">Log in</Link>
          </Button>
          <Button className="bg-gradient-to-r from-brand-purple to-brand-blue text-white" asChild>
            <Link to="/signup">Sign up free</Link>
          </Button>
        </div>
        
        {/* Mobile menu button */}
        <div className="md:hidden">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="p-2"
            aria-label="Toggle menu"
          >
            {isMenuOpen ? (
              <X className="h-6 w-6 text-gray-600" />
            ) : (
              <Menu className="h-6 w-6 text-gray-600" />
            )}
          </button>
        </div>
      </div>
      
      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-white py-4 px-4 shadow-md">
          <div className="flex flex-col space-y-3">
            <a 
              href="#" 
              className="text-gray-600 py-2 hover:text-brand-purple"
              onClick={(e) => {
                handleFeaturesClick(e);
                setIsMenuOpen(false);
              }}
            >
              Features
            </a>
            <a 
              href="#" 
              className="text-gray-600 py-2 hover:text-brand-purple"
              onClick={handleUseCasesClick}
            >
              Use Cases
            </a>
            <Link
              to="/pricing"
              className="text-gray-600 py-2 hover:text-brand-purple"
              onClick={() => setIsMenuOpen(false)}
            >
              Pricing
            </Link>
            <a 
              href="#" 
              className="text-gray-600 py-2 hover:text-brand-purple"
              onClick={(e) => {
                handleHowItWorksClick(e);
                setIsMenuOpen(false);
              }}
            >
              How It Works
            </a>
            <a 
              href="#" 
              className="text-gray-600 py-2 hover:text-brand-purple"
              onClick={(e) => {
                handleTestimonialsClick(e);
                setIsMenuOpen(false);
              }}
            >
              Testimonials
            </a>
            <div className="pt-2 flex flex-col space-y-3">
              <Button variant="outline" asChild>
                <Link to="/login">Log in</Link>
              </Button>
              <Button className="bg-gradient-to-r from-brand-purple to-brand-blue text-white" asChild>
                <Link to="/signup">Sign up free</Link>
              </Button>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
