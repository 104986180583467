import React, { useState } from "react";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../components/ui/tabs.tsx";
import { User, Users } from "lucide-react";
import { Button } from "../components/ui/button.tsx";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "../components/ui/carousel.tsx";
import UseCaseCard from "../components/UseCaseCard.tsx";
import UseCaseDialog from "../components/UseCaseDialog.tsx";
import PathSelectionCard from "../components/PathSelectionCard.tsx";
import { personalUseCases, professionalUseCases } from "../components/UseCaseData.ts";
import { NavigationMenu, NavigationMenuList, NavigationMenuItem, NavigationMenuTrigger, NavigationMenuContent, NavigationMenuLink } from "../components/ui/navigation-menu.tsx";
import { cn } from "../lib/utils.ts";

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";

const UseCaseExperience = () => {
  const [selectedPath, setSelectedPath] = useState<"personal" | "professional" | null>(null);
  const [showDetail, setShowDetail] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<"personal" | "professional">("personal");

  const handleShowUseCases = (type: "personal" | "professional") => {
    setDialogType(type);
    setDialogOpen(true);
  };

  return (
    <section id="use-cases" className="py-16 px-4">
      <div className="container mx-auto max-w-6xl">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Choose Your Path</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            yourinterviewer adapts to both your personal needs and professional requirements. 
            Select a path below to see how it can help you.
          </p>
        </div>

        <Tabs defaultValue="path" className="w-full mb-12">
          <TabsList className="grid w-full md:w-[400px] grid-cols-2 mx-auto">
            <TabsTrigger 
              value="path" 
              onClick={() => {
                setShowDetail(false);
                setSelectedPath(null);
              }}
              className="text-base"
            >
              Choose Your Path
            </TabsTrigger>
            <TabsTrigger 
              value="all" 
              onClick={() => setShowDetail(true)}
              className="text-base"
            >
              Browse All Use Cases
            </TabsTrigger>
          </TabsList>
          
          <TabsContent value="path" className="mt-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <PathSelectionCard 
                isPersonal={true} 
                onShowUseCases={() => handleShowUseCases("personal")} 
                useCases={personalUseCases}
              />
              <PathSelectionCard 
                isPersonal={false} 
                onShowUseCases={() => handleShowUseCases("professional")} 
                useCases={professionalUseCases}
              />
            </div>
          </TabsContent>
          
          <TabsContent value="all" className="mt-8 space-y-10">
            <div className="hidden lg:block">
              <NavigationMenu className="mx-auto">
                <NavigationMenuList>
                  <NavigationMenuItem>
                    <NavigationMenuTrigger className={`${selectedPath === 'personal' ? 'bg-pink-100 text-pink-600 hover:bg-pink-200 hover:text-pink-700' : 'bg-gray-100 text-gray-700 hover:bg-gray-200 hover:text-gray-900'}`}>
                      <User className="h-4 w-4 mr-2" /> Personal Use Cases
                    </NavigationMenuTrigger>
                    <NavigationMenuContent>
                      <ul className="grid w-[500px] gap-3 p-4 grid-cols-2">
                        {personalUseCases.map((useCase, index) => (
                          <ListItem key={index} title={useCase.title} href={useCase.path}>
                            <div className="flex items-center">
                              <span className="mr-2 text-lg">{useCase.icon}</span>
                              {useCase.description}
                            </div>
                          </ListItem>
                        ))}
                      </ul>
                    </NavigationMenuContent>
                  </NavigationMenuItem>
                  
                  <NavigationMenuItem>
                    <NavigationMenuTrigger className={`${selectedPath === 'professional' ? 'bg-brand-purple/10 text-brand-purple hover:bg-brand-purple/20 hover:text-brand-purple' : 'bg-gray-100 text-gray-700 hover:bg-gray-200 hover:text-gray-900'}`}>
                      <Users className="h-4 w-4 mr-2" /> Professional Use Cases
                    </NavigationMenuTrigger>
                    <NavigationMenuContent>
                      <ul className="grid w-[500px] gap-3 p-4 grid-cols-2">
                        {professionalUseCases.map((useCase, index) => (
                          <ListItem key={index} title={useCase.title} href={useCase.path}>
                            <div className="flex items-center">
                              <span className="mr-2 text-lg">{useCase.icon}</span>
                              {useCase.description}
                            </div>
                          </ListItem>
                        ))}
                      </ul>
                    </NavigationMenuContent>
                  </NavigationMenuItem>
                </NavigationMenuList>
              </NavigationMenu>
            </div>

            <div className="lg:hidden space-y-10">
              <div>
                <div className="flex items-center mb-4">
                  <div className={`${selectedPath === 'personal' ? 'bg-pink-100 text-pink-600' : 'bg-gray-100 text-gray-600'} p-2 rounded-lg`}>
                    <User className="h-5 w-5" />
                  </div>
                  <h3 className={`text-xl font-bold ml-2 ${selectedPath === 'personal' ? 'text-pink-600' : 'text-gray-600'}`}>Personal Use Cases</h3>
                  <Button 
                    variant="ghost" 
                    size="sm" 
                    className="ml-auto"
                    onClick={() => setSelectedPath('personal')}
                  >
                    {selectedPath === 'personal' ? 'Selected' : 'Select'}
                  </Button>
                </div>
                <Carousel className="w-full">
                  <CarouselContent>
                    {personalUseCases.map((useCase, index) => (
                      <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3">
                        <UseCaseCard
                          title={useCase.title}
                          description={useCase.description}
                          icon={useCase.icon}
                          link={useCase.path}
                          isPersonal={true}
                        />
                      </CarouselItem>
                    ))}
                  </CarouselContent>
                  <div className="hidden sm:flex">
                    <CarouselPrevious />
                    <CarouselNext />
                  </div>
                </Carousel>
              </div>
              
              <div>
                <div className="flex items-center mb-4">
                  <div className={`${selectedPath === 'professional' ? 'bg-brand-purple/10 text-brand-purple' : 'bg-gray-100 text-gray-600'} p-2 rounded-lg`}>
                    <Users className="h-5 w-5" />
                  </div>
                  <h3 className={`text-xl font-bold ml-2 ${selectedPath === 'professional' ? 'text-brand-purple' : 'text-gray-600'}`}>Professional Use Cases</h3>
                  <Button 
                    variant="ghost" 
                    size="sm" 
                    className="ml-auto"
                    onClick={() => setSelectedPath('professional')}
                  >
                    {selectedPath === 'professional' ? 'Selected' : 'Select'}
                  </Button>
                </div>
                <Carousel className="w-full">
                  <CarouselContent>
                    {professionalUseCases.map((useCase, index) => (
                      <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3">
                        <UseCaseCard
                          title={useCase.title}
                          description={useCase.description}
                          icon={useCase.icon}
                          link={useCase.path}
                          isPersonal={false}
                        />
                      </CarouselItem>
                    ))}
                  </CarouselContent>
                  <div className="hidden sm:flex">
                    <CarouselPrevious />
                    <CarouselNext />
                  </div>
                </Carousel>
              </div>
            </div>
            
            <div className="hidden lg:block space-y-10">
              <div>
                <div className="flex items-center mb-6">
                  <div className={`${selectedPath === 'personal' ? 'bg-pink-100 text-pink-600' : 'bg-gray-100 text-gray-600'} p-2 rounded-lg`}>
                    <User className="h-5 w-5" />
                  </div>
                  <h3 className={`text-xl font-bold ml-2 ${selectedPath === 'personal' ? 'text-pink-600' : 'text-gray-600'}`}>Personal Use Cases</h3>
                  <Button 
                    variant="ghost" 
                    size="sm" 
                    className="ml-4"
                    onClick={() => setSelectedPath('personal')}
                  >
                    {selectedPath === 'personal' ? 'Selected' : 'Select'}
                  </Button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {personalUseCases.map((useCase, index) => (
                    <UseCaseCard
                      key={index}
                      title={useCase.title}
                      description={useCase.description}
                      icon={useCase.icon}
                      link={useCase.path}
                      isPersonal={true}
                    />
                  ))}
                </div>
              </div>
              
              <div>
                <div className="flex items-center mb-6">
                  <div className={`${selectedPath === 'professional' ? 'bg-brand-purple/10 text-brand-purple' : 'bg-gray-100 text-gray-600'} p-2 rounded-lg`}>
                    <Users className="h-5 w-5" />
                  </div>
                  <h3 className={`text-xl font-bold ml-2 ${selectedPath === 'professional' ? 'text-brand-purple' : 'text-gray-600'}`}>Professional Use Cases</h3>
                  <Button 
                    variant="ghost" 
                    size="sm" 
                    className="ml-4"
                    onClick={() => setSelectedPath('professional')}
                  >
                    {selectedPath === 'professional' ? 'Selected' : 'Select'}
                  </Button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {professionalUseCases.map((useCase, index) => (
                    <UseCaseCard
                      key={index}
                      title={useCase.title}
                      description={useCase.description}
                      icon={useCase.icon}
                      link={useCase.path}
                      isPersonal={false}
                    />
                  ))}
                </div>
              </div>
            </div>
          </TabsContent>
        </Tabs>

        <UseCaseDialog 
          isOpen={dialogOpen} 
          onOpenChange={setDialogOpen} 
          isPersonal={dialogType === "personal"}
        />
      </div>
    </section>
  );
};

export default UseCaseExperience;
