
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const UxResearch = () => {
  return (
    <UseCaseTemplate 
      title="UX Research"
      prompt="Generate 5 questions to gather user feedback on our new mobile app interface experience."
      questions={[
        "Please describe your first impression when using our app and what stood out to you most.",
        "Can you walk me through how you completed the main task and any challenges you encountered?",
        "How would you compare this experience to similar apps you've used before?",
        "What features did you find most valuable, and which ones seemed unnecessary or difficult to use?",
        "If you could change one thing about the app to make it better for you, what would it be and why?"
      ]}
      icon="🧪"
      description="Gathering user feedback in a more human, narrative form. Voice responses capture emotional reactions and nuanced opinions that text feedback might miss."
      isPersonal={false}
    />
  );
};

export default UxResearch;
