
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const DogWalkerIntro = () => {
  return (
    <UseCaseTemplate 
      title="Dog Walker Introduction"
      prompt="Create 5 questions to understand a potential dog walker's experience and comfort with different breeds."
      questions={[
        "Please describe your experience with walking dogs, including any specific breeds you've worked with regularly.",
        "How do you handle situations where a dog becomes aggressive or overly excited during a walk?",
        "What's your approach to maintaining a walking schedule and communicating with pet owners?",
        "Can you explain how you would introduce yourself to a new dog and build trust?",
        "What safety precautions do you take when walking dogs in different weather conditions?"
      ]}
      icon="🐕"
      description="Hear confidence and affection for animals without needing a full call. Voice responses help assess genuine care for pets and knowledge of proper handling."
      isPersonal={true}
    />
  );
};

export default DogWalkerIntro;
