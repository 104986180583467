import { Routes, Route, Link, Outlet } from 'react-router-dom';
import './AccountWrapper.css';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { isBuyer, isSeller, getCurrentUser, isAuthed, isEventHost, isAdmin} from '../../services/auth.service';
import { FaComment, FaDot, FaCommentAlt, FaRegComment, FaUser, FaUsers, FaChartPie, FaRegEnvelope, FaUserCog, FaRegComments, FaLink, FaShapes, FaSearch, FaDotCircle, FaStar, FaMoneyCheck, FaRegStar, FaChartLine, FaSlidersH, FaVectorSquare, FaRegUser, FaTicketAlt, FaUserLock, FaDollarSign, FaQuestionCircle, FaUserCircle, FaRobot, FaAudible, FaVoicemail, FaMicrophoneAlt, FaStarOfLife, FaArtstation, FaSpeakerDeck, FaMapMarkerAlt, FaMapMarkedAlt, FaPaste, FaHeadset, FaLinkedin, FaCrown, FaRegNewspaper, FaPaperclip} from 'react-icons/fa';
import { useEffect } from 'react';
import { getFeedUnreplyCount, getUserCompany, getMessageUnreadCount, hasUnreadMessages, getInviteCode, adminCheck, checkSubscriptionStatus, getUserSubscriptions } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import FadeIn from '../../utils/fadein';
import Support from '../../components/support/Support';
import icon_settings from '../../assets/design/account/icon_settings.png';
import useLicenseStatus from '../../hooks/useLicenseStatus';

function AccountWrapper({view}) {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [unreplyCount, setUnreplyCount] = useState(0);
  const [userCompany, setUserCompany] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [hasBuyerUnread, setHasBuyerUnread] = useState(false);
  const [hasSellerUnread, setHasSellerUnread] = useState(false);
  const [hasUnread, setHasUnread] = useState(false);
  const [hasCompany, setHasCompany] = useState(true);
  const [unreadCount, setUnreadCount] = useState(0);
  const [shortMenu, setShortMenu] = useState(false);
  const [inviteCodeEnabled, setInviteCodeEnabled] = useState(false);
  const [inviteCode, setInviteCode] = useState('');
  const [hasSubscription, setHasSubscription] = useState(false);
  const [loadedSubscriptionCheck, setLoadedSubscriptionCheck] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loadedSubscriptions, setLoadedSubscriptions] = useState(false);
  const currentUser = getCurrentUser();
  const {licenseStatus, licenseLoading, licenseData, licenseError, refetch: fetchLicenseStatus} = useLicenseStatus();

  async function loadUserCompany(){
    let res = await getUserCompany();

    if (res.success){
      setUserCompany(res.data);
    }

    else if (res.status === 403){
      await refresh();
      let res = await getUserCompany();

      if (res.success){
        setUserCompany(res.data);
      }
    }

    else{
      setHasCompany(false);
    }

    setLoaded(true);
  }

  useEffect(() => {
    async function loadUserSubscriptions(){
      let res = await getUserSubscriptions();

      if (res.success){
        setSubscriptions(res.data);
        setLoadedSubscriptions(true);
      }
    }

    async function loadSubscriptionCheck(){
      let res = await checkSubscriptionStatus();

      if (res.success){
        setHasSubscription(res.data);
        setLoadedSubscriptionCheck(true);
      }
    }

    async function loadInviteCode(){
      let res = await getInviteCode();

      if (res.success){
        setInviteCode(res.data.token);
        setInviteCodeEnabled(res.data.is_enabled);
      }
    }

    loadUserSubscriptions();
    loadSubscriptionCheck();
    loadInviteCode();
    loadUserCompany();
  }, []);

  async function handleCopy(){
    navigator.clipboard.writeText(inviteCode)
        .then(() => {
          document.getElementById('btnCopy').innerHTML = 'Copied!';
          setTimeout(function(e) {
            document.getElementById('btnCopy').innerHTML = `Copy`;
          }, [1000])
        })
        .catch(err => {
        });
  }

  return (
    <>
    <div className='accountwrapper'>
      <div className={`accountmenu ${shortMenu == true? 'short': ''}`}>
        {isAuthed() == false && isBuyer() === false && isSeller() === false && (
          <>
            <div className='accountwrapper__menu-links center'>
              <div>
                <button className='btn accountwrapper__btn-signup'>Create Free Account</button>
              </div>

              <div className='accountwrapper__btn-login'>
                <Link to='/login'>Sign in</Link>
              </div>
            </div>
          </>
        )}

        <div className='accountwrapper__menu-links'>
        <Link to='/dashboard' className={view==="journeys"?"selected":""}>
          <span className='accountwrapper__icon'>
             <FaVectorSquare />
          </span>{' '}

          <span className='accountwrapper__menu-item-name'>Dashboard</span>
        </Link>

          <Link to='/content' className={view==="studio"?"selected":""}>
              <span className='accountwrapper__icon'>
                <FaLinkedin />
              </span>{' '}

              <span className='accountwrapper__menu-item-name'>Content Studio</span>
            </Link>

            <Link to='/interviews' className={view==="interviews"?"selected":""}>
              <span className='accountwrapper__icon'>
                <FaMicrophoneAlt />
              </span>{' '}

              <span className='accountwrapper__menu-item-name'>Completed Interviews</span>
            </Link>

            <Link to='/all-content' className={view==="allcontent"?"selected":""}>
              <span className='accountwrapper__icon'>
                <FaRegNewspaper />
              </span>{' '}

              <span className='accountwrapper__menu-item-name'>View All Content</span>
            </Link>

            <Link to='/transcript/upload' className={view==="transcripts"?"selected":""}>
              <span className='accountwrapper__icon'>
                <FaPaperclip />
              </span>{' '}

              <span className='accountwrapper__menu-item-name'>My Uploads</span>
            </Link>
        
        <Link to='/account/home' className={view==="settings"?"selected":""}>
          <span className='accountwrapper__icon'>
            <img src={icon_settings} alt='' />
          </span>{' '}
          
          <span className='accountwrapper__menu-item-name'>
            Settings
          </span>
        </Link>

        <Link to='/account/configure' className={view==="configure"?"selected":""}>
          <span className='accountwrapper__icon'>
            <FaPaste />
          </span>{' '}

          <span className='accountwrapper__menu-item-name'>Configure</span>
        </Link>

        <Link to='/contact' className={view==="support"?"selected":""}>
          <span className='accountwrapper__icon'>
            <FaHeadset />
          </span>{' '}
          
          <span className='accountwrapper__menu-item-name'>
            Support
          </span>
        </Link>

        <div className='accountwrapper__subscription-status'>
          <div>
            <strong>Your plan</strong>
          </div>

          <div className='mt-10'>
            {loadedSubscriptions == true && (
              <div>
                {subscriptions.length > 0 && (
                  <>
                  <div className='inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium text-white bg-indigo-600'><FaCrown /> {subscriptions[0].name}</div>

                  {subscriptions[0].name == 'Pro' && (
                    <div className='mt-2'>
                    <Link to='/upgrade' className='text-xs underline' style={{textDecoration: 'underline'}}>Upgrade</Link>
                    </div>
                  )}
                  </>
                )}

                {subscriptions.length == 0 && licenseLoading == false && licenseData.length == 0 &&  (
                  <div className='accountwrapper__basic-subscription'>FREE</div>
                )}

                {subscriptions.length == 0 && licenseLoading == false && licenseData.length > 0 && (
                  <div>
                    <div className='inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium text-white bg-indigo-600'><FaCrown /> {licenseData[0].type}</div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {inviteCodeEnabled == true && (
        <div className='accountwrapper__early-access-box'>
          <div className='accountwrapper__early-access-header'>
           Refer a friend
          </div>

          <div className='mt-10'>
            Invite your community to use our platform with your exclusive invite code.
          </div>

          <div className='accountwrapper__early-access-code'>
            {inviteCode} <span id='btnCopy' className='accountwrapper__btn-copy' onClick={() => handleCopy()}>Copy</span>
          </div>
        </div>
        )}

        </div>

      
      </div>

      <div className='account__content'>
        <Outlet />
      </div>

      <Support />
    </div>
    </>
  );
}

export default AccountWrapper;
