
import React, { useState } from "react";
import Navbar from "../components/Navbar.tsx";
import Footer from "../components/Footer.tsx";
import { Button } from "../components/ui/button.tsx";
import { 
  Upload, 
  FileText, 
  Share2, 
  MessageSquare, 
  FileType,
  Check
} from "lucide-react";
import { Input } from "../components/ui/input.tsx";
import { Textarea } from "../components/ui/textarea.tsx";
import { Label } from "../components/ui/label.tsx";
import { toast } from "sonner";
import ScrollToTop from "../components/layout/ScrollToTop.js";
import { useNavigate } from "react-router-dom";

const UploadContent = () => {
  const [activeContentType, setActiveContentType] = useState(null);
  const [transcriptText, setTranscriptText] = useState("");
  const [fileName, setFileName] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [generatedContent, setGeneratedContent] = useState(null);
  const navigate = useNavigate();

  const handleFileUpload = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setFileName(file.name);
      // In a real app, we'd process the file here
      toast.success("File uploaded successfully");
    }
  };
  
  const handleContentGenerate = () => {
    if (!transcriptText && !fileName) {
      toast.error("Please upload a file or enter text first");
      return;
    }
    
    if (!activeContentType) {
      toast.error("Please select a content type");
      return;
    }
    
    setIsProcessing(true);
    
    // Simulate API call with timeout
    setTimeout(() => {
      setIsProcessing(false);
      
      const contentTemplates = {
        blog: {
          title: "Creating Engaging Content: A Guide Based on Your Uploaded Text",
          content: "Introduction: Text is the foundation of all digital content. Whether you're working with transcripts, notes, or draft ideas, transforming raw text into engaging content is a skill worth mastering. In this comprehensive guide, we'll explore strategies for elevating your uploaded text into compelling narratives that captivate your audience..."
        },
        social: {
          title: "LinkedIn Post",
          content: "📝 Just transformed my raw text into engaging content! Here are three key insights I discovered in the process:\n\n1. Structure creates clarity\n2. Voice establishes connection\n3. Formatting enhances readability\n\nHow do you transform your ideas into polished content? Comment below! #ContentCreation #DigitalMarketing"
        },
        newsletter: {
          title: "Weekly Content Insights Newsletter",
          content: "Dear Content Enthusiasts,\n\nThis week, we're diving into the art of transformation—specifically, how to turn raw text into polished, professional content that resonates with your audience.\n\nHighlights include:\n- The psychology behind engaging text\n- Tools that streamline the content creation process\n- Case studies of successful content transformations"
        }
      };
      
      setGeneratedContent(contentTemplates[activeContentType]);
      toast.success(`${activeContentType.charAt(0).toUpperCase() + activeContentType.slice(1)} generated successfully!`);
    }, 2000);
  };
  
  const handleContentButtonClick = (type) => {
    setActiveContentType(type);
    setGeneratedContent(null);
  };
  
  return (
    <div className="min-h-screen bg-white">
      <ScrollToTop />      
      <section className="pt-32 pb-16 px-4">
        <div className="container mx-auto max-w-7xl">
          <div className="text-center mb-12">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Upload Your Own <span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 to-blue-500">Content</span>
            </h1>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              Transform your existing text, transcripts, or notes into polished, professional content with just a few clicks.
            </p>
          </div>
          
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-12">
            <div className="lg:col-span-1 order-2 lg:order-1">
              <div className="bg-white p-6 rounded-xl shadow-lg border border-gray-100 sticky top-24">
                <div className="flex items-center mb-6">
                  <div className="bg-indigo-100 p-2 rounded-full mr-3">
                    <Share2 className="h-5 w-5 text-indigo-600" />
                  </div>
                  <h3 className="font-semibold text-lg">Content Types</h3>
                </div>
                
                <div className="space-y-4 mb-8">
                  <div 
                    className={`p-4 rounded-lg border cursor-pointer transition-all ${activeContentType === "blog" ? "bg-indigo-50 border-indigo-200" : "border-gray-200 hover:border-indigo-200"}`}
                    onClick={() => handleContentButtonClick("blog")}
                  >
                    <div className="flex items-center">
                      <div className={`p-2 rounded-full mr-3 ${activeContentType === "blog" ? "bg-indigo-100" : "bg-gray-100"}`}>
                        <FileText className={`h-4 w-4 ${activeContentType === "blog" ? "text-indigo-600" : "text-gray-500"}`} />
                      </div>
                      <div>
                        <h4 className="font-medium">Blog Post</h4>
                        <p className="text-sm text-gray-500">Comprehensive, detailed content</p>
                      </div>
                      {activeContentType === "blog" && (
                        <Check className="h-5 w-5 text-indigo-600 ml-auto" />
                      )}
                    </div>
                  </div>
                  
                  <div 
                    className={`p-4 rounded-lg border cursor-pointer transition-all ${activeContentType === "social" ? "bg-indigo-50 border-indigo-200" : "border-gray-200 hover:border-indigo-200"}`}
                    onClick={() => handleContentButtonClick("social")}
                  >
                    <div className="flex items-center">
                      <div className={`p-2 rounded-full mr-3 ${activeContentType === "social" ? "bg-indigo-100" : "bg-gray-100"}`}>
                        <Share2 className={`h-4 w-4 ${activeContentType === "social" ? "text-indigo-600" : "text-gray-500"}`} />
                      </div>
                      <div>
                        <h4 className="font-medium">Social Media Posts</h4>
                        <p className="text-sm text-gray-500">Short, engaging content</p>
                      </div>
                      {activeContentType === "social" && (
                        <Check className="h-5 w-5 text-indigo-600 ml-auto" />
                      )}
                    </div>
                  </div>
                  
                  <div 
                    className={`p-4 rounded-lg border cursor-pointer transition-all ${activeContentType === "newsletter" ? "bg-indigo-50 border-indigo-200" : "border-gray-200 hover:border-indigo-200"}`}
                    onClick={() => handleContentButtonClick("newsletter")}
                  >
                    <div className="flex items-center">
                      <div className={`p-2 rounded-full mr-3 ${activeContentType === "newsletter" ? "bg-indigo-100" : "bg-gray-100"}`}>
                        <MessageSquare className={`h-4 w-4 ${activeContentType === "newsletter" ? "text-indigo-600" : "text-gray-500"}`} />
                      </div>
                      <div>
                        <h4 className="font-medium">Newsletter</h4>
                        <p className="text-sm text-gray-500">Formatted email content</p>
                      </div>
                      {activeContentType === "newsletter" && (
                        <Check className="h-5 w-5 text-indigo-600 ml-auto" />
                      )}
                    </div>
                  </div>
                </div>
                
                <div className="mt-6">
                  <Button 
                    onClick={handleContentGenerate}
                    disabled={isProcessing || (!transcriptText && !fileName) || !activeContentType}
                    className="w-full bg-indigo-600 hover:bg-indigo-700"
                  >
                    {isProcessing ? (
                      <>
                        <div className="animate-pulse mr-2">Processing...</div>
                      </>
                    ) : (
                      <>Generate Content</>
                    )}
                  </Button>
                </div>
              </div>
            </div>
            
            <div className="lg:col-span-2 order-1 lg:order-2">
              <div className="bg-gradient-to-r from-indigo-50 to-blue-50 p-8 rounded-xl border border-indigo-100 mb-8">
                <h2 className="text-2xl font-semibold mb-6 flex items-center">
                  <FileType className="h-5 w-5 text-indigo-600 mr-2" />
                  Upload or Paste Your Text
                </h2>
                
                <div className="mb-6">
                  <Label htmlFor="transcript" className="text-sm text-gray-700 mb-2 block">
                    Paste your text, transcript, or notes here:
                  </Label>
                  <Textarea 
                    id="transcript"
                    placeholder="Paste your content here..."
                    className="min-h-32 bg-white border-indigo-200 focus:border-indigo-300"
                    value={transcriptText}
                    onChange={(e) => setTranscriptText(e.target.value)}
                  />
                </div>
                
                <div className="flex items-center relative my-6">
                  <span className="mx-3 text-sm text-gray-500">OR</span>
                  <div className="flex-1 h-px bg-indigo-200"></div>
                </div>
                
                <div>
                  <Label htmlFor="file-upload" className="text-sm text-gray-700 mb-2 block">
                    Upload a text file or transcript:
                  </Label>
                  <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed border-indigo-300 rounded-lg hover:bg-indigo-50 transition-colors">
                    <div className="space-y-2 text-center">
                      <Upload className="mx-auto h-12 w-12 text-indigo-400" />
                      <div className="flex text-sm text-gray-600 justify-center">
                        <label htmlFor="file-upload" className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500">
                          <span>Upload a file</span>
                          <Input 
                            id="file-upload" 
                            name="file-upload" 
                            type="file" 
                            className="sr-only" 
                            accept=".txt,.doc,.docx,.pdf"
                            onChange={handleFileUpload}
                          />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs text-gray-500">
                        TXT, DOC, DOCX, PDF up to 10MB
                      </p>
                      {fileName && (
                        <div className="flex items-center justify-center text-sm text-indigo-600">
                          <FileText className="h-4 w-4 mr-1" />
                          {fileName}
                          <Button 
                            variant="ghost" 
                            className="h-5 w-5 p-0 ml-1 text-gray-400 hover:text-red-500"
                            onClick={() => setFileName("")}
                          >
                            &times;
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              
              {generatedContent && (
                <div className="bg-white p-6 rounded-xl border border-indigo-200 shadow-md animate-fade-in">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="font-semibold text-lg flex items-center">
                      {activeContentType === "blog" && <FileText className="h-5 w-5 mr-2 text-indigo-600" />}
                      {activeContentType === "social" && <Share2 className="h-5 w-5 mr-2 text-indigo-600" />}
                      {activeContentType === "newsletter" && <MessageSquare className="h-5 w-5 mr-2 text-indigo-600" />}
                      Generated {activeContentType === "blog" ? "Blog Post" : activeContentType === "social" ? "Social Post" : "Newsletter"}
                    </h3>
                    
                    <div className="flex space-x-2">
                      <Button size="sm" variant="outline" className="text-indigo-600 border-indigo-200">
                        Edit
                      </Button>
                      <Button size="sm" className="bg-indigo-600 hover:bg-indigo-700">
                        Copy
                      </Button>
                    </div>
                  </div>
                  
                  <div className="border-b border-gray-100 mb-4 pb-2">
                    <h4 className="font-medium text-gray-700">Title:</h4>
                    <p className="text-gray-800">{generatedContent.title}</p>
                  </div>
                  
                  <div>
                    <h4 className="font-medium text-gray-700 mb-2">Content:</h4>
                    <div className="bg-gray-50 p-4 rounded-lg max-h-64 overflow-y-auto whitespace-pre-line">
                      {generatedContent.content}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      
      {/* <Footer /> */}
    </div>
  );
};

export default UploadContent;
