import './Addons.css';
import { useEffect, useState } from 'react';
import { refresh } from '../../services/auth.service';
import { getAddons, enableAddon, disableAddon, checkHasPaymentMethod, verifyLtd, getPurchasedLicense } from '../../utils/api';
import FadeIn from '../../utils/fadein';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import { FaComment, FaUser, FaChartPie, FaRegEnvelope, FaUserCog, FaRegComments, FaLink, FaShapes, FaRegCheckCircle, FaTimes, FaRegCreditCard, FaTicketAlt } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';
import close from '../../assets/design/dashboard/close.png';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../components/layout/ScrollToTop';

const PurchasedLicense = () => {
  const [addons, setAddons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmDisable, setShowConfirmDisable] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false);
  const [showDisableCongrats, setShowDisableCongrats] = useState(false);
  const [currentAddonId, setCurrentAddonId] = useState(null);
  const [enabling, setEnabling] = useState(false);
  const [disabling, setDisabling] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [paymentCheckLoaded, setPaymentCheckLoaded] = useState(false);
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
  const [licenses, setLicenses] = useState([]);
  const [loadedLicenses, setLoadedLicenses] = useState(false);
  const [code, setCode] = useState('');
  const [hasError, setHasError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function loadHasPayment() {
      let res = await checkHasPaymentMethod();

      if (res.success) {
        setHasPaymentMethod(res.data);
        setPaymentCheckLoaded(true);
      }
    }

    async function loadLicenses() {
      let res = await getPurchasedLicense();

      if (res.success) {
        setLicenses(res.data);
        setLoadedLicenses(true);
      }
    }

    loadLicenses();
    loadHasPayment();
  }, []);


  async function validateLicense() {
    setHasError(false);
    // validate license code
    if (code === '') {
      setHasError(true);
    }

    else {
      setSubmitting(true);
      let res = await verifyLtd(code);

      if (res.success) {
        setShowCongrats(true);
        setHasError(false);
        setSubmitting(false);
      }

      else {
        setHasError(true);
      }
    }
  }

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>Purchased License</title>
      </Helmet>
      <div className='addons'>
        <div><button className='btn' onClick={() => navigate(-1)}>Back</button></div>

        <div className="account__header">Purchased License <FaTicketAlt />
        </div>

        <div className='addons__description'>
          <p>Manage your purchased licenses.</p>
        </div>

        {loadedLicenses == false && (
          <Spinner />
        )}

        {loadedLicenses == true && licenses.length > 0 && (
          <div>
            {licenses.map((license, index) => (
              <div key={index} className='addons__item'>
                <div className='addons__item__title'>{license.type}</div>
                <div className='addons__item__actions'>
                  <span className='text-xs'>
                    <strong>Key: </strong>{license.license_code}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className='mt-4'>
          <label>Enter license code</label>

          <div>
            <input type='text' value={code} onChange={(e) => setCode(e.target.value)} className='input form-control' placeholder='Enter license code' />
          </div>

          <div className='mt-2'>
            {submitting == false && (
            <button className='btn btn-primary' onClick={() => validateLicense()}>Activate</button>
            )}

            {submitting == true && (
              <Spinner />
            )}
          </div>

          {hasError && <div className='mt-2 text-danger'>Invalid license code.</div>}
        </div>

      </div>

      {showCongrats && (
        <>
          <div className='overlay'> </div>
          <div className='modal'>
            <div className='modal__header'>
              <img src={close} alt='close' className='modal__close' onClick={() => setShowCongrats(false)} />
            </div>

            <div className='modal__body'>
              <div className='text-center'>
                <h3>Congratulations!</h3>
                <p>Your license has been activated.</p>

                <p>
                  You now have access to all the features of the software.
                </p>

                <p className='mt-4'>
                  <a href='/dashboard' className='btn btn--primary'>Go to dashboard</a>
                </p>
              </div>
            </div>
          </div>
        </>
      )}

    </>
  )
}

export default PurchasedLicense;