
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const CustomerTestimonials = () => {
  return (
    <UseCaseTemplate 
      title="Customer Testimonials"
      prompt="Create 5 prompts to collect authentic audio testimonials from satisfied customers for marketing materials."
      questions={[
        "Please share your experience with our product/service and how it has helped you or your business.",
        "What specific problem were you trying to solve, and how did our solution address that need?",
        "What was the most surprising or impressive aspect of working with us?",
        "How would you describe the difference our product/service has made for you in terms of time saved, results achieved, or other benefits?",
        "What would you say to someone who is considering using our product/service but is on the fence?"
      ]}
      icon="⭐"
      description="Collecting audio testimonials for use in marketing. Voice responses capture genuine enthusiasm and specific details that make testimonials more credible and persuasive."
      isPersonal={false}
    />
  );
};

export default CustomerTestimonials;
