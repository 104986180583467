import React, { useState, useEffect } from 'react';
import './JourneyInterviewContent.css';
import { useRef } from "react";
import { getAllContent, getCompletedInterviews, getUserOutputTypes, getInterviewsForOutputType, getContentByOutputType, getUserUsage, getUserSubscriptions } from '../../utils/api';
import Spinner from '../../components/layout/Spinner';
import * as FaIcons from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import FadeIn from '../../utils/fadein';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import useUsage from '../../hooks/useUsage';
import ScrollToTop from '../../components/layout/ScrollToTop';
import useSubscriberStatus from '../../hooks/useSubscriberStatus';
import useLicenseStatus from '../../hooks/useLicenseStatus';

const JourneyInterviewCompleted = () => {
  //state variables
  const [data, setData] = useState([]);
  const [outputTypes, setOutputTypes] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedOutputTypeId, setSelectedOutputTypeId] = useState(null);
  const [selectedOutputType, setSelectedOutputType] = useState(null);
  const [content, setContent] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [usage, setUsage] = useState([]);
  const [loadedUsage, setLoadedUsage] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const navigate = useNavigate();
  const targetSectionRef = useRef(null);
  const { usageData, usageLoading } = useUsage();
  const { licenseData, licenseLoading } = useLicenseStatus();
  const { subscriberData, subscriberLoading } = useSubscriberStatus();
  const [hasAccess, setHasAccess] = useState(false);
  const [accessLoaded, setAccessLoaded] = useState(false);
  const [planName, setPlanName] = useState('');

  useEffect(() => {

    if (subscriberLoading == false && licenseLoading == false && licenseData != null && subscriberData != null) {
      if (subscriberData.length > 0) {
        setHasAccess(true);
        setPlanName(subscriberData[0].name);
      }

      if (licenseData.length > 0) {
        setHasAccess(true);
        setPlanName(licenseData[0].type);
      }

      setAccessLoaded(true);
    }

  }, [licenseData, subscriberData, licenseLoading, subscriberLoading]);

  const IconDisplay = ({ iconName }) => {
    if (iconName == null || iconName.length == 0) {
      return <p>No icon.</p>
    }

    const IconComponent = FaIcons[iconName];

    if (IconComponent) {
      return <IconComponent />;
    }

    return <p>Icon not found</p>;
  };

  useEffect(() => {
    async function checkSubscriptionStatus() {
      let res = await getUserSubscriptions();

      if (res.success) {
        setSubscriptions(res.data);
      }
    }

    async function loadUsage() {
      let res = await getUserUsage();

      if (res.success) {
        setUsage(res.data);
        setLoadedUsage(true);
      }
    }

    async function loadData() {
      let res = await getCompletedInterviews();

      if (res.success) {
        setData(res.data);
        setCompleted(res.data);
        setLoaded(true);
      }
    }

    async function loadOutputTypes() {
      let res = await getUserOutputTypes();

      if (res.success) {
        setOutputTypes(res.data);
      }
    }

    checkSubscriptionStatus();
    loadData();
    loadUsage();
    loadOutputTypes();
  }, []);

  useEffect(() => {
    if (selectedOutputTypeId != null && targetSectionRef.current) {
      targetSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedOutputTypeId]);

  async function loadContent(id) {
    let res = await getContentByOutputType(id);

    if (res.success) {
      setContent(res.data);
    }
  }

  return (
    <>
      <ScrollToTop />
      <div className='contentstudio'>
        <h2 className="flex justify-between align-center items-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
          <div>Your Interviews</div>
          <div>
            <Link to='/dashboard' className='py-3 px-4 inline-flex items-center gap-x-2 text-sm font-bold rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'><FaIcons.FaMicrophoneAlt /> New Interview </Link>

            <Link to='/content' className='ml-10 py-3 px-4 inline-flex items-center gap-x-2 text-sm font-bold rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'><FaIcons.FaRegFolder /> My Content </Link>
          </div>
        </h2>

        <div className="mt-10 bg-white border border-gray-200 rounded-lg p-4" role="alert" tabIndex="-1" aria-labelledby="hs-discovery-label">

          <div className="flex items-center justify-between">

            <div className='flex items-center gap-x-2'>
              <div className="shrink-0">
                <svg className="shrink-0 size-4 text-blue-600" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="12" cy="12" r="10"></circle>
                  <path d="M12 16v-4"></path>
                  <path d="M12 8h.01"></path>
                </svg>
              </div>

              {usageLoading == false && usageData != null && (
                <div className="ms-3">
                  <div id="hs-discovery-label" className="text-gray-800 text-[14px] font-semibold">
                    <strong>Completed interviews:</strong> {usageData.total_interviews}
                  </div>
                </div>
              )}
            </div>

            {accessLoaded == true && hasAccess == false && (
              <div>
                <Link to='/pricing' className='rounded-md bg-indigo-600 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>UPGRADE</Link>
              </div>
            )}
          </div>
        </div>

        {loaded == false && (
          <Spinner />
        )}

        {loaded == true && completed.length == 0 && (
          <FadeIn delay={300} duration={300}>
            <div className='mt-20'>
              <p className='py-2 text-sm'>You have not generated any content yet.</p>
              <p className='py-2 text-sm'>Get started by taking your <Link className='link' to='/dashboard'>your first interview</Link>.</p>

            </div>
          </FadeIn>
        )}

        {completed.length > 0 && (
          <>
            <FadeIn delay={300} duration={300} className='mt-30'>
              <h2 className='mb-4 mt-6 text-2xl font-bold tracking-tight text-gray-900 dark:text-white items-center flex'><FaIcons.FaMicrophoneAlt /> <span className='ml-10'>Your <span className='bg-clip-text bg-gradient-to-tl from-blue-600 to-violet-600 text-transparent'>completed interviews</span></span></h2>

              <p className='text-xs/5 text-gray-500 font-bold'>Click on an interview to start generating content.</p>

              <div className='dashboardoutputtypes__interviews my-4 min-w-full divide-y divide-gray-200 dark:divide-neutral-700'>
                {completed.sort((a, b) => new Date(b.date_created) - new Date(a.date_created)).map((item, index) => (
                  <div key={index} className='cursor-pointer dashboardoutputtypes__interview' onClick={() => navigate(`/account/studio/${item.call_id}/4`)}>
                    <div>
                      <div className='dashboardoutputtypes__interview-image'>
                        <img src={item.image_url} />
                      </div>

                      <div className='p-4'>
                        <div className="">
                          {item.date_created ? (
                            <p className="mt-1 text-xs/5 text-gray-500 font-bold">
                              <FaIcons.FaMicrophoneAlt /> Interview date: <time dateTime={item.date_created}>{new Date(item.date_created).toLocaleDateString()}</time>
                            </p>
                          ) : (
                            <div className="mt-1 flex items-center gap-x-1.5">
                              <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                                <div className="size-1.5 rounded-full bg-emerald-500" />
                              </div>
                            </div>
                          )}
                        </div>

                        <div className='text-xs mb-1 mt-10'>
                          {item.journey_name}
                        </div>

                        <div className='dashboardoutputtypes__interview-title'>
                          <h2>{index + 1}. {item.name}</h2>

                          <div className='mt-2 text-sm font-normal'>
                            {item.description}
                          </div>
                        </div>

                        <p className="mt-4 truncate inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-800/30 dark:text-blue-500">{item.content_count} items</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </FadeIn>
          </>
        )}

        {loaded == true && selectedOutputTypeId != null && content.length > 0 && (
          <FadeIn delay={300} duration={300}>
            <h2 className='mb-4 mt-6 text-2xl font-bold tracking-tight text-gray-900 dark:text-white items-center flex'>Generated <span className='mx-2 bg-clip-text bg-gradient-to-tl from-blue-600 to-violet-600 text-transparent'>{selectedOutputType.name}</span></h2>
            <div className='flex my-4 min-w-full divide-y divide-gray-200 dark:divide-neutral-700'>
              {content.slice(0, 5).map((item, index) => (
                <div
                  key={index}
                  style={{
                    margin: '5px',
                    borderBottom: 'solid 1px #ddd'
                  }}
                  className="w-1/2 cursor-pointer relative border-b-2 border-grey-500 rounded-md flex justify-between gap-x-6 px-5 py-5 bg-white hover:border-gray-400 hover:bg-gray-200 transition-all duration-200"

                  onClick={() => navigate(`/content/editor/${item.journey_interview_content_id}`)}
                >

                  <div className="flex min-w-0 gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <p className='mt-1 truncate text-xs/5 text-gray-500 font-bold'>
                        Date Generated: {new Date(item.date_created).toLocaleDateString()}
                      </p>

                      <p className="mt-1 truncate text-xs/5 text-gray-500 text-sm">
                        <Markdown>{item.markdown_content}</Markdown>

                      </p>

                      {/* <div className="mt-1">
                      <button id={`btncopy_${item.journey_interview_content_id}`} className='bg-white hover:bg-gray-100 text-gray-800 font-semibold py-1 px-4 border border-gray-400 rounded-md shadow' onClick={() => handleCopy(item.markdown_content, item.journey_interview_content_id)}>Copy</button>
                    </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </FadeIn>
        )}
      </div>
    </>
  );
};

export default JourneyInterviewCompleted;
