import {useState,useEffect} from 'react';
import './ContentStudio.css';
import { useLocation } from 'react-router-dom';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { buyerConfirmsDemo, getJourneySteps, updateUser, getOnboardingStatus, getPreferredName, getCompletedInterviews, getJourneysWithCompleted,getOutputTypesForInterview } from '../../utils/api';
import { FaArrowLeft, FaBrain, FaBriefcase, FaCalendarAlt, FaCloud, FaLock, FaRegCalendarCheck, FaRegLightbulb, FaSoundcloud, FaSpinner, FaTrophy, FaUnlockAlt } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import * as FaIcons from 'react-icons/fa';
import ContentEditor from './components/ContentEditor';
import ScrollToTop from '../../components/layout/ScrollToTop';

const ContentStudioItemEditor = ({content_id}) => {
  const [preferredName, setPreferredName] = useState('');
  const [loadedName, setLoadedName] = useState(false);
  const [journeys, setJourneys] = useState([]);
  const [interviews, setInterviews] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loadedJourneys, setLoadedJourneys] = useState(false);
  const [selectedJourneyId, setSelectedJourneyId] = useState(null);
  const [selectedInterviewId, setSelectedInterviewId] = useState(null);
  const [outputTypes, setOutputTypes] = useState([]);
  const [loadedOutputTypes, setLoadedOutputTypes] = useState(false);
  const navigate = useNavigate();
  const {id} = useParams();

  const IconDisplay = ({ iconName }) => {
    if (iconName == null || iconName.length == 0){
      return <p>No icon.</p>
    }
  
    // Dynamically get the icon component from the FaIcons object
    const IconComponent = FaIcons[iconName];
  
    // If the icon is found, render it
    if (IconComponent) {
      return <IconComponent />;
    }
  
    // Fallback if icon is not found
    return <p>Icon not found</p>;
  };

  useEffect(() => {
    async function loadInterviews(){
      let res = await getCompletedInterviews(1);

      if (res.success){
        setInterviews(res.data);
        setLoaded(true);
      }
    }

    async function loadJourneys(){
      let res = await getJourneysWithCompleted();

      if (res.success){
        setJourneys(res.data);
        setLoadedJourneys(true);
      }
    }

    async function loadName(){
      let res = await getPreferredName();

    if (res.success){
      setPreferredName(res.data);
      setLoadedName(true);
    }
    }
    loadName();
    loadJourneys();
    loadInterviews();
  }, []);

  return (
    <div className='contentstudio'>
      <ScrollToTop />
      <ContentEditor />
    </div>
  );
};

export default ContentStudioItemEditor;
