import React, { useRef, useState } from "react";
import Navbar from "../../components/Navbar.tsx";
import HeroSection from "../../components/HeroSection.tsx";
import FeatureCard from "../../components/FeatureCard.tsx";
import UseCaseExperience from "../../components/UseCaseExperience.tsx";
import HowItWorks from "../../components/HowItWorks.tsx";
import CallToAction from "../../components/CallToAction.tsx";
import Footer from "../../components/Footer.tsx";
import Testimonials from "../../components/Testimonials.tsx";
import { 
  Mic, 
  Sparkles, 
  BarChart3, 
  Clock, 
  Shield, 
  Share2,
  PieChart,
  FileText,
  LineChart,
  ChevronRight
} from "lucide-react";
import { Button } from "../../components/ui/button.tsx";

const InterviewLinkLanding = () => {
  const useCasesRef = useRef<HTMLDivElement>(null);
  const featuresRef = useRef<HTMLDivElement>(null);
  const howItWorksRef = useRef<HTMLDivElement>(null);
  const testimonialsRef = useRef<HTMLDivElement>(null);
  
  const features = [
    {
      icon: Mic,
      title: "Voice Responses",
      description: "Collect authentic voice feedback to hear tone, emotion, and honesty that text can't convey.",
      iconColor: "text-brand-purple",
      iconBgColor: "bg-brand-purple/10"
    },
    {
      icon: Sparkles,
      title: "AI Question Generation",
      description: "Create interview questions instantly with our AI-powered prompt system for any need.",
      iconColor: "text-brand-blue",
      iconBgColor: "bg-brand-blue/10"
    },
    {
      icon: Clock,
      title: "Time-Saving Automation",
      description: "Save hours with automated analysis instead of manually reviewing each response individually.",
      iconColor: "text-rose-500",
      iconBgColor: "bg-rose-500/10"
    },
    {
      icon: BarChart3,
      title: "Analytics & Insights",
      description: "Get detailed analytics and pull direct quotes from voice responses without manual review.",
      iconColor: "text-brand-accent",
      iconBgColor: "bg-brand-accent/10"
    },
    {
      icon: PieChart,
      title: "Automated Reporting",
      description: "Generate comprehensive reports including NPS scores, candidate screening, and event planning metrics.",
      iconColor: "text-green-500",
      iconBgColor: "bg-green-500/10"
    },
    {
      icon: Share2,
      title: "Easy Sharing",
      description: "Share your interview links or results with teammates, family members, or other stakeholders.",
      iconColor: "text-amber-500",
      iconBgColor: "bg-amber-500/10"
    }
  ];

  const moreFeatures = [
    {
      icon: Shield,
      title: "Secure Data Handling",
      description: "All voice data and analysis are securely stored and accessible only to authorized users.",
      iconColor: "text-cyan-500",
      iconBgColor: "bg-cyan-500/10"
    },
    {
      icon: FileText,
      title: "Testimonial Mining",
      description: "Automatically extract the strongest customer testimonials and feedback quotes for your marketing.",
      iconColor: "text-amber-500",
      iconBgColor: "bg-amber-500/10"
    },
    {
      icon: LineChart,
      title: "Trend Tracking",
      description: "Monitor response patterns over time to identify trends in feedback, hiring, or personal projects.",
      iconColor: "text-indigo-500",
      iconBgColor: "bg-indigo-500/10"
    }
  ];

  const [showMoreFeatures, setShowMoreFeatures] = useState(false);
  
  const scrollToUseCases = () => {
    useCasesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToFeatures = () => {
    featuresRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToHowItWorks = () => {
    howItWorksRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToTestimonials = () => {
    testimonialsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen bg-white overflow-x-hidden">
      <Navbar 
        onUseCasesClick={scrollToUseCases} 
        onFeaturesClick={scrollToFeatures}
        onHowItWorksClick={scrollToHowItWorks}
        onTestimonialsClick={scrollToTestimonials}
      />
      
      <HeroSection onShowUseCasesClick={scrollToUseCases} />
      
      <div ref={useCasesRef}>
        <UseCaseExperience />
      </div>
      
      <section id="features" ref={featuresRef} className="py-16 px-4 bg-gray-50">
        <div className="container mx-auto max-w-6xl">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Why Choose yourinterviewer?</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Our platform combines the power of voice communication with advanced AI analytics to give you deeper insights for both work and personal needs.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <FeatureCard 
                key={index}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
                iconColor={feature.iconColor}
                iconBgColor={feature.iconBgColor}
              />
            ))}
            
            {!showMoreFeatures && (
              <div 
                className="bg-white rounded-xl p-6 shadow-sm border border-dashed border-gray-300 hover:border-brand-purple/50 hover:shadow-md transition-all duration-300 flex flex-col justify-center items-center cursor-pointer h-full"
                onClick={() => setShowMoreFeatures(true)}
              >
                <div className="bg-brand-purple/10 p-3 rounded-lg inline-block mb-4">
                  <ChevronRight className="h-6 w-6 text-brand-purple" />
                </div>
                <h3 className="text-xl font-semibold mb-3 text-center">More Features</h3>
                <p className="text-gray-600 mb-4 text-center">Click to see additional features and benefits of using yourinterviewer</p>
              </div>
            )}
          </div>
          
          {showMoreFeatures && (
            <div className="mt-8">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {moreFeatures.map((feature, index) => (
                  <FeatureCard 
                    key={index}
                    icon={feature.icon}
                    title={feature.title}
                    description={feature.description}
                    iconColor={feature.iconColor}
                    iconBgColor={feature.iconBgColor}
                  />
                ))}
              </div>
              <div className="mt-8 text-center">
                <Button 
                  variant="outline" 
                  onClick={() => setShowMoreFeatures(false)}
                >
                  Show Less
                </Button>
              </div>
            </div>
          )}
        </div>
      </section>
      
      <div ref={howItWorksRef}>
        <HowItWorks />
      </div>
      
      <div ref={testimonialsRef}>
        <Testimonials />
      </div>
      
      <CallToAction />
      
      <Footer />
    </div>
  );
};

export default InterviewLinkLanding;
