
import React from "react";
import Navbar from "../components/Navbar.tsx";
import { Button } from "../components/ui/button.tsx";
import { ArrowRight, Mic, Wand2, Volume2 } from "lucide-react";
import HowItWorks from "../components/HowItWorks.tsx";
import CallToAction from "../components/CallToAction.tsx";
import Footer from "../components/Footer.tsx";
import { useNavigate } from "react-router-dom";

interface UseCaseTemplateProps {
  title: string;
  prompt: string;
  questions: string[];
  icon: string;
  description: string;
  isPersonal: boolean;
}

const UseCaseTemplate = ({
  title,
  prompt,
  questions,
  icon,
  description,
  isPersonal
}: UseCaseTemplateProps) => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen bg-white overflow-x-hidden">
      
      <section className="pt-28 pb-16 md:pt-32 md:pb-24 px-4">
        <div className="container mx-auto max-w-7xl">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="text-center lg:text-left">
              <div className={`inline-flex items-center ${isPersonal ? 'bg-pink-100/90' : 'bg-brand-purple/10'} backdrop-blur-sm px-4 py-2 rounded-full shadow-sm mb-6`}>
                <span className="inline-block">{icon}</span>
                <span className={`ml-2 font-medium text-sm ${isPersonal ? 'text-pink-600' : 'text-brand-purple'}`}>
                  {isPersonal ? 'Personal Use Case' : 'Professional Use Case'}
                </span>
              </div>
              
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold leading-tight mb-6">
                {title} <span className="text-transparent bg-clip-text bg-gradient-to-r from-brand-purple to-brand-blue">Made Simple</span>
              </h1>
              
              <p className="text-lg text-gray-600 mb-4 max-w-xl mx-auto lg:mx-0">
                {description}
              </p>
              
              <div className="py-3 px-4 bg-gray-50 rounded-lg border border-gray-100 mb-4 max-w-xl mx-auto lg:mx-0">
                <p className="text-sm text-gray-600 flex items-start">
                  <span className="text-brand-purple font-bold mr-2 mt-0.5">📊</span>
                  <span>
                    <strong>Fact:</strong> People are approximately 3 times more likely to provide truthful responses when using voice versus text.
                    <span className="block mt-1 text-xs text-gray-500">Journal of Experimental Psychology, 2020</span>
                  </span>
                </p>
              </div>
              
              <div className="flex flex-col sm:flex-row justify-center lg:justify-start gap-4 mb-6">
                <Button onClick={() => navigate('/join-now')} className="bg-gradient-to-r from-brand-purple to-brand-blue text-white text-lg py-6 px-8 rounded-xl flex items-center">
                  Try for free
                  <ArrowRight className="ml-2 h-5 w-5" />
                </Button>

                <Button onClick={() => navigate('/how-it-works')} variant="outline" className="text-lg py-6 px-8 rounded-xl">
                  See how it works
                </Button>
              </div>
              
              <div className="mt-4 text-sm text-gray-500 flex items-center justify-center lg:justify-start gap-x-4">
                <span className="inline-flex items-center">
                  <svg className="w-4 h-4 mr-1 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
                  </svg>
                  No credit card required
                </span>
                <span className="inline-flex items-center">
                  <svg className="w-4 h-4 mr-1 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
                  </svg>
                  Free personal plan available
                </span>
              </div>
            </div>
            
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-radial from-brand-purple/30 to-transparent rounded-full blur-3xl -z-10"></div>
              <div className="bg-white rounded-xl shadow-xl p-6 border border-gray-100 transition-all duration-300 hover:shadow-2xl">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center">
                    <div className={`${isPersonal ? 'bg-pink-100 text-pink-500' : 'bg-brand-purple/10 text-brand-purple'} p-2 rounded-lg flex items-center justify-center`}>
                      <span className="text-xl">{icon}</span>
                    </div>
                    <h3 className="ml-3 font-semibold">{title}</h3>
                  </div>
                  <Button 
                    size="sm" 
                    className="bg-brand-purple text-white"
                  >
                    Generate
                  </Button>
                </div>
                
                <div className="bg-gray-50 p-4 rounded-lg mb-4 h-24 flex items-center">
                  <p className="text-gray-600 italic">
                    {prompt}
                  </p>
                </div>
                
                <div className="space-y-4">
                  {questions.map((question, index) => (
                    <div 
                      key={index} 
                      className="bg-white p-4 rounded-lg border border-gray-200"
                    >
                      <div className="flex items-start">
                        <div className="bg-brand-blue/10 p-1.5 rounded-full">
                          <span className="text-sm font-bold text-brand-blue">{index + 1}</span>
                        </div>
                        <p className="ml-3 text-gray-800">
                          {question}
                        </p>
                      </div>
                      {index === 0 && (
                        <div className="ml-9 mt-2 flex items-center">
                          <Volume2 className="h-4 w-4 text-red-500 mr-2 animate-pulse" />
                          <div className="h-1.5 bg-gray-200 rounded-full w-full overflow-hidden">
                            <div className="bg-red-500 h-full w-3/4 animate-pulse-slow"></div>
                          </div>
                          <span className="ml-2 text-xs text-gray-500">0:45</span>
                        </div>
                      )}
                    </div>
                  ))}
                  
                  <div className="text-center pt-2">
                    <span className="text-gray-400 text-sm">+ 3 more questions</span>
                  </div>
                </div>
                
                <div className="mt-6 text-center">
                  <Button className="bg-brand-blue text-white w-full">Send to {isPersonal ? 'Candidates' : 'Participants'}</Button>
                </div>
                
                <div className="mt-4 flex items-center justify-center text-xs text-gray-500">
                  <div className="w-6 h-6 rounded-full bg-gradient-to-r from-brand-purple to-brand-blue flex items-center justify-center text-white text-xs font-bold mr-2">J</div>
                  <span>Jessica will get audio responses from all your {isPersonal ? 'candidates' : 'participants'}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <HowItWorks />
      
      <CallToAction />
      
      <Footer />
    </div>
  );
};

export default UseCaseTemplate;