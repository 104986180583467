import './InterviewLinkListItem.css';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getInterviewLinkInvites } from '../../utils/api';
import { FaCog, FaListAlt, FaPlug, FaPlus, FaRegEdit, FaTimes } from 'react-icons/fa';

const InterviewLinkListItem = ({ interview, onClose }) => {
  const [isFadingIn, setIsFadingIn] = useState(false);
  const [interviewLinkInvites, setInterviewLinkInvites] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useState(() => {
    async function fetchInterviewLinkInvites() {
      const res = await getInterviewLinkInvites(interview.reference_id);
      if (res.success) {
        setInterviewLinkInvites(res.data);

      } else {
        alert('Error fetching interview link invites');
      }
      setLoading(false);
    }

    fetchInterviewLinkInvites();
    setIsFadingIn(true);
  }, []);

  return (
    <div className={` ${isFadingIn ? 'fade-in' : ''}`}>
      <div className="details-header">
        <h2>{interview.title}</h2>
      </div>

      <div className="details-invites">
        <h3 className="text-lg font-semibold mt-4">Invites</h3>
        {loading ? (
          <p>Loading invites...</p>
        ) : interviewLinkInvites.length > 0 ? (
          <table className="table-auto w-full border-collapse border border-gray-300 mt-2 text-sm">
            <thead>
              <tr className="bg-gray-100">
                <th className='border border-gray-300 px-4 py-2 text-left'>Sent on</th>
                <th className="border border-gray-300 px-4 py-2 text-left">Name</th>
                <th className="border border-gray-300 px-4 py-2 text-left">Email</th>
                <th className="border border-gray-300 px-4 py-2 text-left">Status</th>
                <th className="border border-gray-300 px-4 py-2 text-left">Link</th>
                <th className="border border-gray-300 px-4 py-2 text-left"></th>
              </tr>
            </thead>
            <tbody>
              {interviewLinkInvites.map((invite, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td>
                    <div className="text-sm font-medium text-gray-500">
                      {new Date(invite.date_created).toLocaleDateString()}
                    </div>
                  </td>

                  <td className="border border-gray-300 px-4 py-2">{invite.name}</td>
                  <td className="border border-gray-300 px-4 py-2">{invite.email}</td>

                  <td className="border border-gray-300 px-4 py-2">
                    {invite.status == 'Sent' && (
                      <span className="text-blue-500 font-semibold">
                        Sent
                      </span>
                    )}

                    {invite.status == 'Pending' && (
                      <span className="text-yellow-500 font-semibold">
                        Not Sent
                      </span>
                    )}

                    {invite.status == 'Complete' && (
                      <span className="text-green-500 font-semibold">
                        Completed
                      </span>
                    )}
                    </td>

                    <td>
                      <Link to={`/interviewlink/invite/${invite.session_token}`} className="text-blue-500 hover:underline">
                        Interview Link
                      </Link>
                    </td>

                    <td>
                      {invite.status == 'Complete' && (
                        <button
                          className="py-1 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 text-gray-500 hover:border-blue-600 hover:text-blue-600 focus:outline-hidden focus:border-blue-600 focus:text-blue-600 disabled:opacity-50 disabled:pointer-events-none"
                          onClick={() => navigate(`/interviewlink/details/${invite.session_token}`)}
                        >
                          <FaListAlt /> Details
                        </button>
                      )}
                    </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <>
          <p>No invites sent.</p>
            <div>
              <button onClick={() => navigate(`/interviewlink/editor/${interview.reference_id}`)}><FaCog  /> Continue Setup</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InterviewLinkListItem;
