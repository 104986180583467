
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const ExitInterview = () => {
  return (
    <UseCaseTemplate 
      title="Exit Interview"
      prompt="Create 5 questions for departing employees to gather honest feedback about their experience with the company."
      questions={[
        "What factors influenced your decision to leave the company at this time?",
        "What aspects of working here did you enjoy most, and what would you change if you could?",
        "How would you describe the company culture, and did it align with what you were expecting?",
        "Did you feel you had the resources, support, and training needed to succeed in your role?",
        "What advice would you give to management that could improve the work environment for current and future employees?"
      ]}
      icon="🚪"
      description="Allowing employees to speak freely without typing. Voice responses often capture more candid feedback and emotional nuances that written surveys might miss."
      isPersonal={false}
    />
  );
};

export default ExitInterview;
