import { useState, useEffect } from 'react';
import React, { useRef } from "react";
import './DemoConfirmation.css';
import './Dashboard.css';
import './DashboardOutputTypes.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import { buyerConfirmsDemo, getJourneySteps, updateUser, getOnboardingStatus, addUserPreference, removeUserPreference, getJourneys, checkSubscriptionStatus, getAllJourneySteps, getAllJourneyStepsByOutputType, getAllJourneyStepsNotInOutputType, getAvailableOutputTypes, getCompletedInterviews, getUserUsage, getProOutputTypes } from '../../utils/api';
import { FaBrain, FaBriefcase, FaCalendarAlt, FaCaretLeft, FaCloud, FaLock, FaRegCalendarCheck, FaRegLightbulb, FaRegStar, FaSoundcloud, FaSpinner, FaStar, FaTimes, FaTrophy, FaUnlockAlt } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';
import FirstAssistant from './FirstAssistant';
import FadeIn from '../../utils/fadein';
import TypeWriter from './components/TypeWriter';
import imgLogo from '../../assets/design/yi_logo.png';
import * as FaIcons from 'react-icons/fa';
import useSubscriberStatus from '../../hooks/useSubscriberStatus';
import useLicenseStatus from '../../hooks/useLicenseStatus';

function DashboardOutputTypes() {
  const [referenceId, setReferenceId] = useState('');
  const [journeys, setJourneys] = useState([]);
  const [loadedJourneys, setLoadedJourneys] = useState(false);
  const [allSteps, setAllSteps] = useState([]);
  const [steps, setSteps] = useState([]);
  const [otherSteps, setOtherSteps] = useState([]);
  const [demo, setDemo] = useState({});
  const [onboardingStatus, setOnboardingStatus] = useState(false);
  const [loadedOnboarding, setLoadedOnboarding] = useState(false);
  const [preferredName, setPreferredName] = useState('');
  const search = useLocation().search;
  const [loaded, setLoaded] = useState(false);
  const [loadedOtherSteps, setLoadedOtherSteps] = useState(false);
  const [verified, setVerified] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showOnboarding1, setShowOnboarding1] = useState(false);
  const [showOnboarding2, setShowOnboarding2] = useState(false);
  const [showOnboarding3, setShowOnboarding3] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [selectedStep, setSelectedStep] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentJourneyId, setCurrentJourneyId] = useState(null);
  const [selectedJourney, setSelectedJourney] = useState(null);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [loadedSubscriptionCheck, setLoadedSubscriptionCheck] = useState(false);
  const [feedbackValue, setFeedbackValue] = useState(0);
  const [outputTypes, setOutputTypes] = useState([]);
  const [selectedOutputType, setSelectedOutputType] = useState(null);
  const [selectedOutputTypeId, setSelectedOutputTypeId] = useState(null);
  const [showOther, setShowOther] = useState(false);
  const [showExamples, setShowExamples] = useState(false);
  const [examples, setExamples] = useState([]);
  const [completedInterviews, setCompletedInterviews] = useState([]);
  const [usage, setUsage] = useState(null);
  const [loadedUsage, setLoadedUsage] = useState(false);
  const [proOutputTypes, setProOutputTypes] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id'); // Retrieves the 'id' parameter
  const navigate = useNavigate();
  const targetSectionRef = useRef(null);
  const { subscriberStatus, subscriberLoading, subscriberData, subscriberError, refetch: fetchSubscriberStatus } = useSubscriberStatus();
  const { licenseStatus, licenseLoading, licenseData, licenseError, refetch: fetchLicenseStatus } = useLicenseStatus();
  const [hasAccess, setHasAccess] = useState(false);
  const [planName, setPlanName] = useState('');
  const [accessLoaded, setAccessLoaded] = useState(false);

  useEffect(() => {

    if (subscriberLoading == false && licenseLoading == false && licenseData != null && subscriberData != null) {
      if (subscriberData.length > 0) {
        setHasAccess(true);
        setPlanName(subscriberData[0].name);
      }

      if (licenseData.length > 0) {
        setHasAccess(true);
        setPlanName(licenseData[0].type);
      }

      setAccessLoaded(true);
    }

  }, [licenseData, subscriberData, licenseLoading, subscriberLoading]);

  const IconDisplay = ({ iconName }) => {
    if (iconName == null || iconName.length == 0) {
      return <p>No icon.</p>
    }

    // Dynamically get the icon component from the FaIcons object
    const IconComponent = FaIcons[iconName];

    // If the icon is found, render it
    if (IconComponent) {
      return <IconComponent />;
    }

    // Fallback if icon is not found
    return <p>Icon not found</p>;
  };

  const scrollToSection = () => {
    console.log('dog', targetSectionRef.current);
    if (targetSectionRef.current) {
      targetSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    async function loadUsage() {
      let res = await getUserUsage();

      if (res.success) {
        setUsage(res.data);
        setLoadedUsage(true);
      }
    }
    async function loadSubscriptionCheck() {
      let res = await checkSubscriptionStatus();

      if (res.success) {
        setHasSubscription(res.data);
        setLoadedSubscriptionCheck(true);
      }

      else {
        setHasSubscription(false);
        setLoadedSubscriptionCheck(true);
      }
    }

    async function loadCompleted() {
      let res = await getCompletedInterviews();

      if (res.success) {
        setCompletedInterviews(res.data);
      }
    }

    async function loadOutputTypes() {
      let res = await getAvailableOutputTypes();

      if (res.success) {
        setOutputTypes(res.data);
      }
    }

    async function loadProOutputTypes() {
      let res = await getProOutputTypes();

      if (res.success) {
        setProOutputTypes(res.data);
      }
    }

    async function loadJourneys() {
      let res = await getJourneys();

      if (res.success) {
        setJourneys(res.data);
        setLoadedJourneys(true);

        if (id != null) {
          setCurrentJourneyId(id);
          setSelectedJourney(res.data.filter(x => x.journey_id == id)[0])
        }
      }
    }

    async function checkOnboarding() {
      let res = await getOnboardingStatus();

      if (res.success) {
        setOnboardingStatus(res.data);
        setLoadedOnboarding(true);
      }
    }

    async function loadSteps() {
      let res = await getAllJourneySteps();
      if (res.success) {
        setAllSteps(res.data);
        setLoaded(true);
      }
    }

    loadOutputTypes();
    loadSubscriptionCheck();
    checkOnboarding();
    loadJourneys();
    loadCompleted();
    // checkToken();
    loadSteps();
    loadUsage();
    loadProOutputTypes();
  }, []);

  async function loadSteps() {
    let res = await getAllJourneySteps();
    if (res.success) {
      setSteps(res.data);
      setLoaded(true);
    }
  }

  function loadOnboarding() {
    const timer = setTimeout(() => {
      setShowOnboarding(true);
      setShowOnboarding1(true);
    }, 1000);
    return () => clearTimeout(timer);
  }

  async function handleNextStep(step) {
    if (step == 1) {
      setCurrentStep(step);
      setShowOnboarding1(false);
      setShowOnboarding2(true);
    }

    else if (step == 2) {
      let res = await updateUser({ preferred_name: preferredName });

      if (res.success) {
        setShowOnboarding2(false);
        setShowOnboarding3(true);
      }
    }

    else if (step == 3) {
      let res = await updateUser({ onboarding_complete: true });

      if (res.success) {
        setShowOnboarding2(false);
        setShowOnboarding3(false);
        setShowOnboarding(false);
      }
    }
  }

  useEffect(() => {
    if (loadedOnboarding == true && onboardingStatus == false) {
      loadOnboarding();
    }
  }, [loadedOnboarding]);

  async function handleShowChat(id, item) {
    setSelectedStepId(id);
    setSelectedStep(item);
    setShowChat(true);
  }

  async function handleShowUnlock(id, item) {
    setShowUnlockModal(true);
    setSelectedStepId(id);
    setSelectedStep(item);
  }

  async function handleOutputTypeClick(id, item) {
    let res = await getAllJourneyStepsByOutputType(id);

    if (res.success) {
      setSteps(res.data);
      setSelectedOutputType(item);
      setSelectedOutputTypeId(id);
      //  scrollToSection();
    }

    let res2 = await getAllJourneyStepsNotInOutputType(id);

    if (res2.success) {
      setOtherSteps(res2.data);
    }
  }

  async function handleProOutputTypeClick(id, item) {
    if (accessLoaded == true && hasAccess == false) {
      setShowUnlockModal(true);
      return;
    }

    let res = await getAllJourneyStepsByOutputType(id);

    if (res.success) {
      setSteps(res.data);
      setSelectedOutputType(item);
      setSelectedOutputTypeId(id);
    }

    let res2 = await getAllJourneyStepsNotInOutputType(id);
    if (res2.success) {
      setOtherSteps(res2.data);
    }
  }

  useEffect(() => {
    if (steps.length > 0 && targetSectionRef.current) {
      targetSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [steps]);

  async function handlePreference(name) {
    let element = document.getElementById(`pref_${name}`);
    element.classList.toggle('selected');

    if (element.classList.contains('selected')) {
      let res = await addUserPreference(name);
    }

    else {
      let res = await removeUserPreference(name);
    }
  }

  async function handleJourneyClick(id, item) {

    setCurrentJourneyId(id);
    setSelectedJourney(item);
  }

  useEffect(() => {
    if (currentJourneyId != null) {
      setSteps([]);
      loadSteps();
    }
  }, [currentJourneyId]);

  return (
    <div>
      {loadedJourneys == false && currentJourneyId == null && (
        <div>
          <Spinner />
        </div>
      )}

      {outputTypes.length > 0 && (
        <FadeIn delay={300} duration={300}>
          <div className='mb-10'>
            <div className="text-gray-500 sm:text-lg dark:text-gray-400 flex flex-row items-center justify-between sm:flex-row">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">What would you like to create today?</h2>

              <div>
                <button onClick={() => navigate('/content')} type="button" className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-bold rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                  <FaIcons.FaRegFolder /> My Content
                </button>

                <button onClick={() => navigate('/all-content')} type="button" className="ml-2 py-3 px-4 inline-flex items-center gap-x-2 text-sm font-bold rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
                  <FaIcons.FaRegNewspaper /> View All Content
                </button>
              </div>
            </div>
          </div>

          <div className='dashboardoutputtypes__how-it-works'>
            <div className='flex items-center'>
              <div style={{ fontSize: '20px' }} className=''><FaIcons.FaShapes /></div>

              <div className='ml-10'>
                <div className='text-lg font-bold'>1. Choose what to generate</div>
                <div>
                  From LinkedIn posts to business plans, tailor your creation to your audience.

                </div>
              </div>
            </div>

            <div className='flex items-center'>
              <div style={{ fontSize: '20px' }}><FaIcons.FaMicrophoneAlt /></div>

              <div className='ml-10'>
                <div className='text-lg font-bold'>2. Select an interview</div>
                <div>
                  Decide on the content format that aligns with your goals.

                </div>
              </div>
            </div>

            <div className='flex items-center'>
              <div style={{ fontSize: '20px' }}><FaIcons.FaRegEdit /></div>

              <div className='ml-10'>
                <div className='text-lg font-bold'>3. Refine your work</div>
                <div>
                  Perfect your content with our tools and get it ready to shine.


                </div>
              </div>
            </div>
          </div>

          {selectedOutputTypeId != null && (
            <div className='flex align-center mt-10'>
              <span className='link' onClick={() => {
                setSelectedOutputType(null);
                setSelectedOutputTypeId(null);
                setShowOther(false);
              }}>&lt; Back</span>
            </div>
          )}

          <ul className='mt-10' style={{ marginTop: '40px' }}>
            {selectedOutputTypeId == null && (
              <h2 className='flex items-center text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white'><span className='mx-2'>Available content types {` (${outputTypes.length})`}</span></h2>
            )}

            {outputTypes.map((item, index) => (
              <>
                {(selectedOutputTypeId == null || selectedOutputTypeId == item.output_type_id) && (
                  <li
                    key={index}
                    style={{
                      margin: '5px',
                      alignItems: 'center'
                    }}
                    className="cursor-pointer rounded-md flex justify-between gap-x-6 px-5 py-5 bg-white hover:border-b-2 hover:border-gray-400 hover:bg-gray-200 transition-all duration-200"
                    onClick={() => handleOutputTypeClick(item.output_type_id, item)}
                  >

                    <div className="flex min-w-0 gap-x-4" style={{ alignItems: 'center' }}>
                      <span style={{ fontSize: '30px' }}><IconDisplay iconName={item.fa_icon} /></span>

                      <div className="min-w-0 flex-auto">
                        <p className="mb-2 text-1xl font-bold tracking-tight text-gray-900 dark:text-white">{item.name}</p>

                        <p className='mt-1 truncate text-xs/5 text-gray-500'>
                          {item.description}
                        </p>

                        <div className="mt-1 mt-1 mt-4 truncate inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-800/30 dark:text-blue-500">
                          {item.interview_count} Interviews Available
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              </>
            ))}
          </ul>

          <ul className='mt-10' style={{ marginTop: '40px' }}>

            {selectedOutputTypeId == null && (
              <>
                <h2 className='mb-4 flex items-center text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white'><span className='mx-2'><span className='bg-clip-text bg-gradient-to-tl from-blue-600 to-violet-600 text-transparent'>PRO</span> content types</span></h2>

                {accessLoaded == true && (
                  <p className='text-sm'>
                    Available to PRO users only.

                    {hasAccess == false && accessLoaded == true && (
                      <Link to='/upgrade' className='ml-10 link'>Upgrade to PRO</Link>
                    )}
                  </p>
                )}
              </>
            )}

            {proOutputTypes.map((item, index) => (
              <>
                {(selectedOutputTypeId == null || selectedOutputTypeId == item.output_type_id) && (
                  <li
                    key={index}
                    style={{
                      margin: '5px',
                      alignItems: 'center'
                    }}
                    className="cursor-pointer rounded-md flex justify-between gap-x-6 px-5 py-5 bg-white hover:border-b-2 hover:border-gray-400 hover:bg-gray-200 transition-all duration-200"
                    onClick={() => handleProOutputTypeClick(item.output_type_id, item)}
                  >

                    <div className="flex min-w-0 gap-x-4" style={{ alignItems: 'center' }}>
                      <span style={{ fontSize: '30px' }}><IconDisplay iconName={item.fa_icon} /></span>

                      <div className="min-w-0 flex-auto">
                        <p className="mb-2 text-1xl font-bold tracking-tight text-gray-900 dark:text-white">{item.name}</p>

                        <p className='mt-1 truncate text-xs/5 text-gray-500'>
                          {item.description}
                        </p>

                        <div className="mt-1 mt-1 mt-4 truncate inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-800/30 dark:text-blue-500">
                          {item.interview_count} Interviews Available
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              </>
            ))}
          </ul>
        </FadeIn>
      )}

      {selectedOutputTypeId != null && (
        <div ref={targetSectionRef} className=' mt-10'>
          <h2 className="mb-4 mt-6 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Available Interviews</h2>

          <p className='text-sm'>
            These interviews are more likely to produce the type of content that you are looking for.
          </p>
          {steps != null && steps.length > 0 && (

            <FadeIn delay={200} duration={200} className='dashboardoutputtypes__interviews'>
              {steps.map((item, index) => (
                <div key={index} className='dashboardoutputtypes__interview'>
                  <div>
                    <div className='dashboardoutputtypes__interview-image'>
                      <img src={item.image_url} />
                    </div>

                    <div className='p-4'>
                      <div className='text-xs mb-1'>
                        {item.journey_name}
                      </div>

                      <div className='dashboardoutputtypes__interview-title'>
                        <h2>{index + 1}. {item.name}</h2>

                        <div className='mt-2 text-sm font-normal'>
                          {item.description}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='dashboard__journey-cta px-4 py-4'>
                    {accessLoaded == true && hasAccess == true && (
                      <>
                        <button className='cursor-pointer mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowChat(item.journey_step_id, item)}>
                          <FaIcons.FaMicrophoneAlt /> Start Interview
                        </button>
                      </>
                    )}

                    {accessLoaded == true && hasAccess == false && item.is_free == false && (
                      <>
                        <button className='cursor-pointer bg-indigo-600 mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowUnlock(item.journey_step_id, item)}>
                          <FaLock /> Unlock
                        </button>
                      </>
                    )}

                    {accessLoaded == true && hasAccess == false && item.is_free == true && (
                      <>
                        <button className='cursor-pointer mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowChat(item.journey_step_id, item)}>
                          <FaIcons.FaMicrophoneAlt /> Start Interview
                        </button>
                      </>
                    )}

                    {/* {item.is_completed != true && item.is_free == true && (
                      <button className='cursor-pointer mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowChat(item.journey_step_id, item)}>
                        <FaIcons.FaMicrophoneAlt /> Start Interview
                      </button>
                    )}

                    {item.is_completed != true && item.is_free == false && loadedSubscriptionCheck == true && hasSubscription == false && (
                      <button className='cursor-pointer bg-indigo-600 mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowUnlock(item.journey_step_id, item)}>
                        <FaLock /> Unlock
                      </button>
                    )}

                    {item.is_completed != true && item.is_free == false && loadedSubscriptionCheck == true && hasSubscription == true && (
                      <button className='cursor-pointer mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowChat(item.journey_step_id, item)}>
                        <FaIcons.FaMicrophoneAlt /> Start Interview
                      </button>
                    )}

                    {item.is_completed == true && item.is_free == true && (
                      <span className='cursor-pointer mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowChat(item.journey_step_id, item)}>
                        <FaIcons.FaMicrophoneAlt /> Start Interview
                      </span>
                    )}

                    {item.is_completed == true && item.is_free == false && (
                      <span className='cursor-pointer mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowChat(item.journey_step_id, item)}>
                        <FaIcons.FaMicrophoneAlt /> Start Interview
                      </span>
                    )} */}
                  </div>

                </div>
              ))}
            </FadeIn>
          )}

          {otherSteps.length > 0 && (
            <div className='center mt-10'>
              <button onClick={() => setShowOther(true)} type="button" className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-800 text-gray-800 hover:border-gray-500 hover:text-gray-500 focus:outline-none focus:border-gray-500 focus:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:border-white dark:text-white dark:hover:text-neutral-300 dark:hover:border-neutral-300">
                Load other interviews
              </button>
            </div>
          )}

        </div>
      )}

      {showOther == true && (
        <>
          <div className='mt-10'>
            <h2 className="mb-4 mt-6 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Other Interviews</h2>
          </div>

          <p className='text-sm'>
            These interviews fall outside of the category you selected, but they might still produce high quality content.
          </p>

          <FadeIn delay={200} duration={200} className='dashboardoutputtypes__interviews mt-8'>
            {otherSteps.slice(0, 6).map((item, index) => (
              <div key={index} className='dashboardoutputtypes__interview'>
                <div>
                  <div className='dashboardoutputtypes__interview-image'>
                    <img src={item.image_url} />
                  </div>

                  <div className='p-4'>
                    <div className='text-xs mb-1'>
                      {item.journey_name}
                    </div>

                    <div className='dashboardoutputtypes__interview-title'>
                      <h2>{index + 1}. {item.name}</h2>

                      <div className='mt-2 text-sm font-normal'>
                        {item.description}
                      </div>
                    </div>

                    <div className='text-[12px] mt-2'>
                      Taken: 2 times
                    </div>
                  </div>
                </div>

                <div className='dashboard__journey-cta px-4 py-4'>
                  {item.is_completed != true && item.is_free == true && (
                    <button className='cursor-pointer mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center bg-black justify-center' onClick={() => handleShowChat(item.journey_step_id, item)}>
                      <FaIcons.FaMicrophoneAlt /> Start Interview
                    </button>
                  )}

                  {item.is_completed != true && item.is_free == false && loadedSubscriptionCheck == true && hasSubscription == false && (
                    <button className='cursor-pointer bg-indigo-600 mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowUnlock(item.journey_step_id, item)}>
                      <FaLock /> Unlock
                    </button>
                  )}

                  {item.is_completed != true && item.is_free == false && loadedSubscriptionCheck == true && hasSubscription == true && (
                    <button className='cursor-pointer mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowChat(item.journey_step_id, item)}>
                      <FaIcons.FaMicrophoneAlt /> Start Interview
                    </button>
                  )}

                  {item.is_completed == true && (
                    <span className='cursor-pointer mt-6 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-black text-white hover:bg-gray-800  border-2 border-transparent flex gap-1 items-center justify-center' onClick={() => handleShowChat(item.journey_step_id, item)}>
                      <FaIcons.FaMicrophoneAlt /> Start Interview
                    </span>
                  )}

                  {/* <button className='cursor-pointer mt-2 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-white-100 text-indigo-600 hover:bg-gray-100  border-2 border-transparent flex gap-1 items-center justify-center'>
               View Examples
             </button> */}
                </div>

              </div>
            ))}
          </FadeIn>
        </>
      )}

      {completedInterviews.length > 0 && (
        <div className='mt-30' style={{ marginTop: '40px' }}>
          <h2 className='flex items-center gap-2 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white'><FaIcons.FaMicrophoneAlt /> Your latest <span className='bg-clip-text bg-gradient-to-tl from-blue-600 to-violet-600 text-transparent'>completed interviews</span> <span className='text-sm'><Link to={'/interviews'}>View all</Link></span></h2>

          <p className='text-sm mt-20 mb-20'>
            Use your exisitng interviews to create new content.
          </p>

          <FadeIn delay={200} duration={200} className='dashboardoutputtypes__interviews'>
            {completedInterviews.sort((a, b) => new Date(b.date_created) - new Date(a.date_created)).slice(0, 3).map((item, index) => (
              <div onClick={() => navigate(`/account/studio/${item.call_id}/4`)} key={index} className='cursor-pointer dashboardoutputtypes__interview'>
                <div>
                  <div className='dashboardoutputtypes__interview-image'>
                    <img src={item.image_url} />
                  </div>

                  <div className='p-4'>
                    <div className="">
                      {item.date_created ? (
                        <p className="mt-1 text-xs/5 text-gray-500 font-bold">
                          <FaIcons.FaMicrophoneAlt /> Interview date: <time dateTime={item.date_created}>{new Date(item.date_created).toLocaleDateString()}</time>
                        </p>
                      ) : (
                        <div className="mt-1 flex items-center gap-x-1.5">
                          <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                            <div className="size-1.5 rounded-full bg-emerald-500" />
                          </div>
                        </div>
                      )}
                    </div>

                    {/* <div className='text-xs mb-1'>
                      {item.journey_name}
                    </div> */}

                    <div className='dashboardoutputtypes__interview-title'>
                      <h2>{index + 1}. {item.name}</h2>

                      <div className='mt-2 text-sm font-normal'>
                        {item.description}
                      </div>
                    </div>

                    <p className="mt-4 truncate inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-800/30 dark:text-blue-500">{item.content_count} items</p>
                  </div>
                </div>

                <div className='dashboard__journey-cta px-4 py-4'>

                  <Link to={`/account/studio/${item.call_id}/4`} className='cursor-pointer mt-2 w-full text-sm font-bold text-center rounded-lg text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-1.5 bg-white-100 text-indigo-600 hover:bg-gray-100  border-2 border-transparent flex gap-1 items-center justify-center'>
                    View your content
                  </Link>
                </div>

              </div>
            ))}
          </FadeIn>
        </div>
      )}

      {/* {loadedJourneys == true && currentJourneyId == null && (
      <FadeIn delay={300} duration={300}>
        <div className='dashboard__top-header mt-20'>
          <h1>Available Journeys</h1>
        </div>

        <p className='mt-20'>
          Choose a journey to begin crafting content.
        </p>

        <div className='dashboard__journey-items mt-10'>
          {journeys.map((item, index) => (
            <div key={index} className='dashboard__journey-item' style={{
              backgroundImage: `url("${item.image_url}")`,
              backgroundSize: 'cover'
            }} onClick={() => handleJourneyClick(item.journey_id, item)}>
              <div className='dashboard__journey-item-body'>
                <div className='dashboard__journey-item-name'>
                  {index + 1}. {item.name}
                </div>

                <div className='dashboard__journey-item-btn'>
                  <button className='btn'>{item.completed_steps} / {item.step_count} Interviews</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </FadeIn>
    )} */}

      {currentJourneyId != null && (
        <>
          <div className='mt-10'>
            <span className='link' onClick={() => {
              setCurrentJourneyId(null);
              setSelectedJourney(null);
              setSteps([])
            }}><FaCaretLeft /> Back to Journeys</span>
          </div>

          <div className='dashboard__top-header mt-20'>
            <h1>{selectedJourney.name}</h1>
          </div>

          <p className='mt-10'>
            <i>Start your interview journey.</i>
          </p>

          {loaded == false && (
            <div className='center'>
              <FaSpinner />
            </div>
          )}

          <div className='dashboard__journeys mt-10'>
            {steps != null && steps.length > 0 && (
              <>
                {steps.map((item, index) => (
                  <div key={index} className='dashboard__journey' style={{
                    backgroundImage: `url("${item.image_url}")`,
                    backgroundSize: 'cover',
                  }}>

                    <div className='dashboard__journey-title'>
                      <h2>{index + 1}. {item.name}</h2>

                      <div className='dashboard__journey-description'>
                        {item.description}
                      </div>

                      {/* <div className='dashboard__journey-description'>
                    Interview length: {item.min_duration}-{item.max_duration} mins
                  </div> */}
                    </div>

                    <div className='dashboard__journey-cta'>
                      {item.is_completed != true && item.is_free == true && (
                        <button className='btn dashboard__journey-btn' onClick={() => handleShowChat(item.journey_step_id, item)}>
                          Get Started
                        </button>
                      )}

                      {item.is_completed != true && item.is_free == false && loadedSubscriptionCheck == true && hasSubscription == false && (
                        <button className='btn dashboard__journey-btn' onClick={() => handleShowUnlock(item.journey_step_id, item)}>
                          <FaLock /> Unlock
                        </button>
                      )}

                      {item.is_completed != true && item.is_free == false && loadedSubscriptionCheck == true && hasSubscription == true && (
                        <button className='btn dashboard__journey-btn' onClick={() => handleShowChat(item.journey_step_id, item)}>
                          Get Started
                        </button>
                      )}

                      {item.is_completed == true && (
                        <span className='dashboard__journey-completed-flag' onClick={() => handleShowChat(item.journey_step_id, item)}>
                          Completed
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      )}

      <div>
        {showChat && (
          <FadeIn delay={300} duration={300}>
            <div>
              <FirstAssistant showChat={showChat} setShowChat={setShowChat} journey_step_id={selectedStepId} selectedStep={selectedStep} loadSteps={loadSteps} />
            </div>
          </FadeIn>
        )}

        {showOnboarding && (
          <FadeIn delay={300} duration={300}>
            <div className='overlay'></div>
            <div className='dashboard__onboarding'>
              <div style={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                zIndex: '50000'
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 100" height="40">
                  <polyline
                    points="
      40,50 
      50,30 60,70 
      70,20 80,80 
      90,35 100,65 
      110,45 120,55 
      130,48 140,52 
      150,49 160,51"
                    fill="none"
                    stroke="#2563eb "
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />

                  <circle cx="170" cy="50" r="3" fill="#FF3366" />
                </svg>
              </div>

              <div>
                <h2></h2>
              </div>

              {showOnboarding1 && (
                <>
                  <h2>
                    <div>
                      <TypeWriter text="Hello! Let's setup your new interviewer!" speed={60} />
                    </div>
                  </h2>


                  <p className='mt-30'>
                    <button className='btn' onClick={() => handleNextStep(1)}>Next</button>
                  </p>
                </>
              )}

              {showOnboarding2 && (
                <FadeIn delay={300} duration={300}>
                  <div className='overlay'></div>
                  <div className='dashboard__onboarding'>
                    <h2>
                      <div>
                        <TypeWriter text="What would you like your interviewer to call you?" speed={60} />
                      </div>
                    </h2>

                    <p style={{
                      width: '50%',
                      margin: '20px auto'
                    }} className='mt-30'>
                      <input type='text' style={{
                        width: '400px'
                      }} className='input form-control' placeholder='Name' value={preferredName} onChange={(e) => setPreferredName(e.target.value)} />
                    </p>

                    <p >
                      <button className='btn' onClick={() => handleNextStep(2)}>Next</button>
                    </p>
                  </div>
                </FadeIn>
              )}

              {showOnboarding3 && (
                <FadeIn delay={300} duration={300}>
                  <div className='overlay'></div>
                  <div className='dashboard__onboarding'>
                    <h2>
                      <div>
                        <TypeWriter text="Select the tone that you would like your interviewer to have" speed={60} />
                      </div>
                    </h2>

                    <div className='dashboard__onboarding-tone-items'>
                      <div id='pref_Friendly' className='dashboard__onboarding-tone-item' onClick={() => handlePreference('Friendly')}>
                        Friendly
                      </div>

                      <div id='pref_Personal' className='dashboard__onboarding-tone-item' onClick={() => handlePreference('Personal')}>
                        Personal
                      </div>

                      <div id='pref_Political' className='dashboard__onboarding-tone-item' onClick={() => handlePreference('Political')}>
                        Political
                      </div>

                      <div id='pref_Edgy' className='dashboard__onboarding-tone-item' onClick={() => handlePreference('Edgy')}>
                        Edgy
                      </div>

                      <div id='pref_Controversial' className='dashboard__onboarding-tone-item' onClick={() => handlePreference('Controversial')}>
                        Controversial
                      </div>
                    </div>

                    <p>
                      <button className='btn' onClick={() => handleNextStep(3)}>Next</button>
                    </p>
                  </div>
                </FadeIn>
              )}
            </div>
          </FadeIn>
        )}


      </div>

      {showUnlockModal && (
        <>
          <div className='overlay'></div>
          <div className='modal center'>
            <div className='modal__close' onClick={() => setShowUnlockModal(false)}>
              <FaTimes />
            </div>

            <div className='center' style={{ fontSize: '70px' }}>
              <FaIcons.FaCrown />
            </div>

            <h2 className='modal__h2'>UPGRADE TO <span className='highlight'>PRO</span></h2>

            <p>
              Unlock this {'(and all other)'} interviews by upgrading to a Pro account today.
            </p>
            {/* <div className='upgrade__plan-price'>
        <span className='upgrade__plan-price-amount'>$59</span> / month
      </div> */}

            <div className='mt-10'>
              <Link to={'/pricing'} className='w-full bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500 mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10'>UPGRADE</Link>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default DashboardOutputTypes;