
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const NannyShare = () => {
  return (
    <UseCaseTemplate 
      title="Nanny Share Setup"
      prompt="Generate 5 questions for families to align expectations when setting up a nanny share arrangement."
      questions={[
        "What are your top priorities regarding your children's care and daily routines?",
        "How do you prefer to handle scheduling, especially for holidays, sick days, and other special circumstances?",
        "What are your thoughts on discipline approaches and how consistent rules should be maintained across both families?",
        "How would you like to handle shared expenses beyond the nanny's salary, such as activities, snacks, or supplies?",
        "What communication style works best for you regarding daily updates and addressing any concerns that arise?"
      ]}
      icon="👪"
      description="Use mutual interview prompts to align two families' expectations. Voice responses help identify potential conflicts in parenting styles and care preferences before finalizing arrangements."
      isPersonal={true}
    />
  );
};

export default NannyShare;
