
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const RemoteTeamBuilding = () => {
  return (
    <UseCaseTemplate 
      title="Remote Team Building"
      prompt="Create 5 fun prompt questions for a remote team to answer as part of a virtual team-building activity."
      questions={[
        "What's a hidden talent or unexpected hobby you have that most colleagues might not know about?",
        "If you could be famous for one thing, what would it be and why?",
        "What's the best piece of advice you've ever received, and how has it impacted your life?",
        "If you could have dinner with any historical figure, who would it be and what would you ask them?",
        "What's a personal goal you're working toward outside of your professional life?"
      ]}
      icon="👥"
      description="Voice diaries or answers to fun prompts. Voice responses create more authentic connections between remote team members through tone, laughter, and personality."
      isPersonal={false}
    />
  );
};

export default RemoteTeamBuilding;
