import {useEffect, useState} from 'react';
import { Routes, Route, Link, Outlet, useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { getSellerRole, deleteUserAccount, refreshToken, getUserCompany } from '../../utils/api';
import { isBuyer, isSeller, refresh, logout, isAuthed } from '../../services/auth.service';
import close from '../../assets/design/dashboard/close.png';
import { FaBriefcase, FaCode, FaCog, FaCreditCard, FaCrown, FaDollarSign, FaFileAlt, FaHashtag, FaRegBuilding, FaRegCalendarAlt, FaRegClock, FaRegQuestionCircle, FaRegUserCircle, FaSms, FaTicketAlt, FaUser, FaUsers } from 'react-icons/fa';

const AccountMenu = () => {
  const [options, setOptions] = useState([]);
  const [sellerRole, setSellerRole] = useState();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [userCompany, setUserCompany] = useState(null);
  const navigate = useNavigate();

  useEffect(() =>  {
    if (isAuthed() === false){
      navigate('/login');
    }

    async function loadUserCompany(){
      let res = await getUserCompany();

      if (res.success){
        setUserCompany(res.data);
      }

      else if (res.status === 403){
        await refresh();
        let res = await getUserCompany();

        if (res.success){
          setUserCompany(res.data);
        }
      }
    }

    async function loadSellerRole(){
      let res = await getSellerRole();

      if (res.success){
        setSellerRole(res.data);
      }

      else {
        setSellerRole(1); // default to admin
      }
    }

      loadSellerRole();
      loadUserCompany();
  }, []);

  useEffect(() => {
    async function loadBuyerOptions(){
      let tmp = [];
      tmp.push({
        title: "Profile Info",
        description: "Update user profile.",
        orderId: 1,
        icon: <FaUser />,
        url: "/account/profile"
      });

      tmp.push({
        title: "Password",
        description: "Reset or change your passwords.",
        orderId: 1,
        icon: <FaHashtag />,
        url: "/account/update-password"
      });

   
    if (isSeller() && userCompany && userCompany.payment_type === 'pay_now') {
      tmp.push({
        title: "Order History",
        description: "Check your recent purchase history",
        orderId: 1,
        icon: <FaFileAlt />,
        url: "/account/order-history"
      })
    }

      tmp.push({
        title: "Payment Method",
        description: "Add a valid payment method in order to book demos",
        orderId: 1,
        icon: <FaCreditCard />,
        url: "/account/payment-method"
      })

      tmp.push({
        title: "Account Settings",
        description: "Change your email, update your password.",
        orderId: 1,
        icon: <FaCog />,
        url: "/account/settings"
      });

      tmp.push({
        title: "Subscriptions",
        description: "Change your subscription status.",
        orderId: 1,
        icon: <FaCrown />,
        url: "/account/subscriptions"
      });

      tmp.push({
        title: "Purchased License",
        description: "Manage your purchased licenses.",
        orderId: 1,
        icon: <FaTicketAlt />,
        url: "/account/license"
      })

      setOptions(tmp);
    }

    loadBuyerOptions();
  }, [sellerRole, userCompany]);

  async function handleConfirm(){
    let res = await deleteUserAccount();

    if (res.success){
      setShowDeleteConfirm(false);
      setIsDeleted(true);
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let res = await deleteUserAccount();

        if (res.success){
          setShowDeleteConfirm(false);
          setIsDeleted(true);
        }
      }
    }
  }

  async function handleClose(){
    await logout();
    window.location = '/';
  }
  return (
    <>
    <h1 className='account__header'>Settings</h1>
    <div className="account account__links">
      <Helmet>
          <meta name="description" content="" />
          <title>Account Settings</title>
        </Helmet>

    {options.map((item, index) => (
      <Link to={item.url}>
      <div key={index} className="account__option">
        <div className="account__option-title">{item.icon} {item.title} <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L4.85858 4.85858C4.93668 4.93668 4.93668 5.06332 4.85858 5.14142L1 9" strokeWidth="2" strokeLinecap="round"/>
</svg>
</div>
        <div className="account__option-description">{item.description}</div>
      </div>
      </Link>
    ))}

      <div className='account__danger-zone'>
        <span style={{width: '50%'}}>
          Delete my account. You will lose all data up to this date and will not be able to book any future demos.
        </span>

        <span>
          <button className='btn btn--danger' onClick={() => setShowDeleteConfirm(true)}>Delete my account</button>
        </span>
      </div>
  </div>

  {showDeleteConfirm && (
    <>
      <div className='overlay'></div>
      <div className='modal'>
        <div className='modal__close'>
          <img src={close} onClick={() => setShowDeleteConfirm(false)} />
        </div>

        <div className='modal__h2'>
          Are you sure?
        </div>

        <div>
          Your account will be deleted along with all of your current data. You will no longer be able to book paid demos.
        </div>

        <div className='center' style={{marginTop: '30px'}}>
          <button className='btn btn--danger' onClick={() => handleConfirm()}>Confirm</button>
        </div>
      </div>
    </>
  )}

  {isDeleted && (
    <>
      <div className='overlay'></div>
      <div className='modal'>
        <div>
          Your account has been deleted. You will no longer be able to book demos and will not be able to receive notifications on possible new demo opportunities.
        </div>

        <div style={{fontSize: '12px', marginTop: '20px'}}>
          If you'd ever like to come back to the platform, please feel free to email <i>support@leadrpro.com</i>.
        </div>

        <div className='center' style={{marginTop: '30px'}}>
          <button className='btn btn--primary' onClick={() => handleClose()}>Close</button>
        </div>
      </div>
    </>
  )}
  </>
  )
}

export default AccountMenu;