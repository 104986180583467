import { useState, useEffect, useCallback } from "react";
import { getUserUsage } from "../utils/api";

const useUsage = () => {
  const [usageStatus, setUsageStatus] = useState(null);
  const [usageLoading, setUsageLoading] = useState(false);
  const [usageError, setUsageError] = useState(null);
  const [usageData, setUsageData] = useState(null);

  const usageFetch = useCallback(async () => {
    setUsageLoading(true);
    setUsageError(null);

    try {
      const response = await getUserUsage();

      if (response.success){
        setUsageStatus(response.data.status); // Adjust based on your API response
        setUsageData(response.data);
        setUsageLoading(false);
      }
    } catch (err) {
      console.log(err.message);
      setUsageError(err.message || "Failed to fetch subscriber status");
    } finally {
      setUsageLoading(false);
    }
  }, []);

  useEffect(() => {
    usageFetch();
  }, [usageFetch]);

  return { usageStatus, usageLoading, usageData, usageError, usageFetch };
};

export default useUsage;
