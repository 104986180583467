
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../components/ui/dialog.tsx";
import UseCaseList from "../components/UseCaseList.tsx";

interface UseCaseDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  isPersonal: boolean;
}

const UseCaseDialog = ({ isOpen, onOpenChange, isPersonal }: UseCaseDialogProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-4xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold mb-2">
            {isPersonal ? "Personal Use Cases" : "Professional Use Cases"}
          </DialogTitle>
          <DialogDescription>
            Select a use case to learn more about how yourinterviewer can help you.
          </DialogDescription>
        </DialogHeader>
        
        <UseCaseList isPersonal={isPersonal} />
      </DialogContent>
    </Dialog>
  );
};

export default UseCaseDialog;
