
export const personalUseCases = [
  {
    icon: "👶",
    title: "Babysitter Vetting",
    description: "Quick Q&A to understand personality, tone of voice, and comfort with kids.",
    path: "/use-cases/babysitter-vetting"
  },
  {
    icon: "🐕",
    title: "Dog Walker Introduction",
    description: "Hear confidence and affection for animals without needing a full call.",
    path: "/use-cases/dog-walker-intro"
  },
  {
    icon: "🏠",
    title: "New Roommate Intro",
    description: "Capture vibe and lifestyle habits in a low-pressure way.",
    path: "/use-cases/roommate-intro"
  },
  {
    icon: "🧹",
    title: "House Cleaner Onboarding",
    description: "Quick questions about schedule preferences and prior experience.",
    path: "/use-cases/house-cleaner"
  },
  {
    icon: "📚",
    title: "Tutor Screening",
    description: "Hear how they explain things and interact.",
    path: "/use-cases/tutor-screening"
  },
  {
    icon: "👵",
    title: "Family Member Memory Project",
    description: "Collect stories or perspectives from relatives without long interviews.",
    path: "/use-cases/family-memories"
  },
  {
    icon: "🎉",
    title: "RSVP Confirmation",
    description: "Collect tone, allergies, plus energy for event planning.",
    path: "/use-cases/rsvp-confirmation"
  },
  {
    icon: "👪",
    title: "Nanny Share Setup",
    description: "Use mutual interview prompts to align two families' expectations.",
    path: "/use-cases/nanny-share"
  },
  {
    icon: "👩‍👧",
    title: "Parenting Circle Intro",
    description: "Icebreaker intros with a few thoughtful questions.",
    path: "/use-cases/parenting-circle"
  },
  {
    icon: "🏘️",
    title: "Neighborhood Babysitter Registry",
    description: "Create a consistent intro format across sitters.",
    path: "/use-cases/babysitter-registry"
  },
  {
    icon: "🎸",
    title: "Band Interview",
    description: "Play sample for me and understand musical influences.",
    path: "/use-cases/band-interview"
  }
];

export const professionalUseCases = [
  {
    icon: "👩‍💼",
    title: "Job Interview",
    description: "Screening a candidate for cultural fit or communication style.",
    path: "/use-cases/job-interview"
  },
  {
    icon: "👨‍💻",
    title: "Freelancer Selection",
    description: "Getting a feel for communication and approach.",
    path: "/use-cases/freelancer-selection"
  },
  {
    icon: "🎙️",
    title: "Voiceover Auditions",
    description: "Hearing voice quality directly.",
    path: "/use-cases/voiceover-audition"
  },
  {
    icon: "🧪",
    title: "UX Research",
    description: "Gathering user feedback in a more human, narrative form.",
    path: "/use-cases/ux-research"
  },
  {
    icon: "💼",
    title: "Sales Role Interview",
    description: "Testing verbal charisma and spontaneity.",
    path: "/use-cases/sales-role"
  },
  {
    icon: "⭐",
    title: "Customer Testimonials",
    description: "Collecting audio testimonials for use in marketing.",
    path: "/use-cases/customer-testimonials"
  },
  {
    icon: "🚀",
    title: "Founder Story for PR",
    description: "Capturing authenticity and passion in their own words.",
    path: "/use-cases/founder-story"
  },
  {
    icon: "🚪",
    title: "Exit Interview",
    description: "Allowing employees to speak freely without typing.",
    path: "/use-cases/exit-interview"
  },
  {
    icon: "🤝",
    title: "Client Discovery Alternative",
    description: "Asynchronous insights in the client's voice.",
    path: "/use-cases/client-discovery"
  },
  {
    icon: "📈",
    title: "Performance Review Input",
    description: "Managers or employees sharing reflections.",
    path: "/use-cases/performance-review"
  },
  {
    icon: "👥",
    title: "Remote Team Building",
    description: "Voice diaries or answers to fun prompts.",
    path: "/use-cases/remote-team-building"
  },
  {
    icon: "🎤",
    title: "Conference Speaker Selection",
    description: "Listening to energy and delivery.",
    path: "/use-cases/speaker-selection"
  },
  {
    icon: "🎧",
    title: "Podcast Guest Pre-Interview",
    description: "Gauging storytelling ability.",
    path: "/use-cases/podcast-guest"
  },
  {
    icon: "💡",
    title: "Startup Pitch Feedback",
    description: "Investors offering thoughts naturally.",
    path: "/use-cases/pitch-feedback"
  },
  {
    icon: "🎓",
    title: "Training Feedback",
    description: "New hires recording impressions or confusion points.",
    path: "/use-cases/training-feedback"
  }
];
