
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const PerformanceReview = () => {
  return (
    <UseCaseTemplate 
      title="Performance Review Input"
      prompt="Create 5 questions for team members to provide self-assessment input before their annual performance review."
      questions={[
        "What achievements from the past year are you most proud of, and why do you consider them significant?",
        "Which areas of your role do you feel you've grown most in, and where would you like to develop further?",
        "How have you contributed to your team's success and alignment with company goals?",
        "What challenges have you faced, and how did you address or overcome them?",
        "What specific goals would you like to set for yourself in the coming year?"
      ]}
      icon="📈"
      description="Managers or employees sharing reflections. Voice responses often reveal confidence levels, enthusiasm about achievements, and sincerity about growth areas that text responses might hide."
      isPersonal={false}
    />
  );
};

export default PerformanceReview;
