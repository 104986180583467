import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { adminSearchUsers } from '../../utils/api';
import { adminPayouts, updatePayoutStatus, getPurchasedLicenses } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaEnvelope, FaGlobe, FaRegEnvelope } from 'react-icons/fa';

function UserLicensePurchase() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [payouts, setPayouts] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [countPending, setCountPending] = useState(0);
  const [data, setData] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      let res = await getPurchasedLicenses();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }

      else{
        alert('failed to reload and retry');
      }
    }

    loadData();
  }, []);

  async function loadData(){
    let res = await adminPayouts(200);

    if (res.success){
      setPayouts(res.data);
      setLoaded(true);
    }

    else{
      alert('failed to reload');
    }
  }

  async function handleSearch(){
    if (query.length === 0){
      loadData();
      return;
    }
    let res = await adminSearchUsers(query);

    if (res.success){
      setUsers(res.data);
      setLoaded(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await adminSearchUsers(query);

      if (res.success){
        setUsers(res.data);
        setLoaded(true);
      }
    }
  }

  async function handlePaid(id, reference_id){
    setSubmitting(true);
    setSelectedId(id);

    let res = await updatePayoutStatus(reference_id, id);

    if (res.success){
      await loadData();
      setSelectedId(null);
      setSubmitting(false);
    }

    else{
      alert('failed to reload and retry');
    }
  }

  return (
    <>
      <Helmet>
        <title>Admin - Payouts</title>
      </Helmet>

      <h1>Purchased Licenses</h1>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && data.length === 0 && (
        <div className='center mt-20'><strong>Nothing to see here...</strong></div>
      )}

      {loaded && data.length > 0 && (
         <div>
          <table className='table admindashboard__table'>
            <tr>
              <th></th>
              <th>Requested on</th>
              <th>Type</th>
              <th>User</th>
              <th>License Code</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>

            {data.map((item, index) => (
              <tr>
                <td>
                  <strong>#{index + 1}</strong>
                </td>

                <td>
                  {new Date(item.date_created).toLocaleDateString()}
                </td>

                <td>
                  <div>{item.first_name} {item.last_name}</div>
                  <div>{item.email}</div>
                </td>

                <td>
                  {item.type}
                </td>

                <td>
                  {item.license_code}
                </td>

                <td>
                  {item.status}
                </td>

                <td>
                  {item.price_amount}
                </td>

                <td>
                  {item.source}
                </td>
              </tr>
            ))}
          </table>
        </div>
      )}
    </>
  );
}

export default UserLicensePurchase;