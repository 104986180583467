
import React from "react";
import { Button } from "../components/ui/button.tsx";
import { ArrowRight, User, Users } from "lucide-react";

interface PathSelectionCardProps {
  isPersonal: boolean;
  onShowUseCases: () => void;
  useCases: Array<{ icon: string; title: string }>;
}

const PathSelectionCard = ({ isPersonal, onShowUseCases, useCases }: PathSelectionCardProps) => {
  return (
    <div 
      className={`bg-white rounded-xl p-8 shadow-sm border ${
        isPersonal ? 'border-pink-100' : 'border-brand-purple/30'
      } hover:shadow-md transition-all group relative overflow-hidden`}
    >
      <div 
        className={`absolute inset-0 bg-gradient-to-r ${
          isPersonal 
            ? 'from-pink-500 to-purple-500' 
            : 'from-brand-purple to-brand-blue'
        } opacity-5 group-hover:opacity-10 transition-opacity duration-300`}
      ></div>
      <div className="flex justify-between items-start mb-6">
        <div 
          className={`${
            isPersonal 
              ? 'bg-pink-100 text-pink-600' 
              : 'bg-brand-purple/10 text-brand-purple'
          } p-3 rounded-lg`}
        >
          {isPersonal ? <User className="h-6 w-6" /> : <Users className="h-6 w-6" />}
        </div>
      </div>
      <h3 
        className={`text-2xl font-bold mb-3 group-hover:${
          isPersonal ? 'text-pink-600' : 'text-brand-purple'
        } transition-colors`}
      >
        {isPersonal ? 'Personal Use' : 'Professional Use'}
      </h3>
      <p className="text-gray-600 mb-6">
        {isPersonal 
          ? 'Perfect for individual needs like finding reliable service providers, roommates, tutors, or anyone you need to interview informally.'
          : 'Designed for businesses and organizations to streamline recruitment, gather feedback, conduct market research, and manage team performance.'
        }
      </p>
      <div className="space-y-3 mb-6">
        {useCases.slice(0, 3).map((useCase, index) => (
          <div key={index} className="flex items-center text-sm text-gray-600">
            <span className="mr-2 text-lg">{useCase.icon}</span>
            <span>{useCase.title}</span>
          </div>
        ))}
        <div className="flex items-center text-sm text-gray-600">
          <span className="mr-2">+</span>
          <span>And more {isPersonal ? 'personal' : 'professional'} use cases</span>
        </div>
      </div>
      <Button 
        className={`w-full bg-gradient-to-r ${
          isPersonal 
            ? 'from-pink-500 to-purple-500' 
            : 'from-brand-purple to-brand-blue'
        } text-white`}
        onClick={() => onShowUseCases()}
      >
        See {isPersonal ? 'Personal' : 'Professional'} Use Cases <ArrowRight className="ml-2 h-4 w-4" />
      </Button>
    </div>
  );
};

export default PathSelectionCard;
