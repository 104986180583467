import React, { useState, useEffect } from 'react';
import './JourneyInterviewContent.css';
import { useRef } from "react";
import { getAllContent, getCompletedInterviews, getUserOutputTypes, getInterviewsForOutputType, getContentByOutputType, getUserUsage, getUserSubscriptions, deleteContentItem } from '../../utils/api';
import Spinner from '../../components/layout/Spinner';
import * as FaIcons from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import FadeIn from '../../utils/fadein';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import useUsage from '../../hooks/useUsage';
import useSubscriberStatus from '../../hooks/useSubscriberStatus';
import ScrollToTop from '../../components/layout/ScrollToTop';
import useLicenseStatus from '../../hooks/useLicenseStatus';

const JourneyInterviewContentAll = () => {
  //state variables
  const [data, setData] = useState([]);
  const [outputTypes, setOutputTypes] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedOutputTypeId, setSelectedOutputTypeId] = useState(null);
  const [selectedOutputType, setSelectedOutputType] = useState('');
  const [content, setContent] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [usage, setUsage] = useState([]);
  const [loadedUsage, setLoadedUsage] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [allContent, setAllContent] = useState([]);
  const [uniqueTypes, setUniqueTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const navigate = useNavigate();
  const targetSectionRef = useRef(null);
  const { usageData, usageLoading, usageFetch } = useUsage();
  const { subscriberData, subscriberLoading } = useSubscriberStatus();
  const { licenseData, licenseLoading } = useLicenseStatus();
  const [hasAccess, setHasAccess] = useState(false);
  const [accessLoaded, setAccessLoaded] = useState(false);
  const [planName, setPlanName] = useState('');

  useEffect(() => {

    if (subscriberLoading == false && licenseLoading == false && licenseData != null && subscriberData != null) {
      if (subscriberData.length > 0) {
        setHasAccess(true);
        setPlanName(subscriberData[0].name);
      }

      if (licenseData.length > 0) {
        setHasAccess(true);
        setPlanName(licenseData[0].type);
      }

      setAccessLoaded(true);
    }

  }, [licenseData, subscriberData, licenseLoading, subscriberLoading]);


  const IconDisplay = ({ iconName }) => {
    if (iconName == null || iconName.length == 0) {
      return <p>No icon.</p>
    }

    const IconComponent = FaIcons[iconName];

    if (IconComponent) {
      return <IconComponent />;
    }

    return <p>Icon not found</p>;
  };

  useEffect(() => {
    async function checkSubscriptionStatus() {
      let res = await getUserSubscriptions();

      if (res.success) {
        setSubscriptions(res.data);
      }
    }

    async function loadUsage() {
      let res = await getUserUsage();

      if (res.success) {
        setUsage(res.data);
        setLoadedUsage(true);
      }
    }

    async function loadData() {
      let res = await getCompletedInterviews();

      if (res.success) {
        setData(res.data);
        setCompleted(res.data);
        setLoaded(true);
      }
    }

    async function loadOutputTypes() {
      let res = await getUserOutputTypes();

      if (res.success) {
        setOutputTypes(res.data);
      }
    }

    async function loadAllContent() {
      let res = await getAllContent();

      if (res.success) {
        setAllContent(res.data);
        const uniqueTypes = [...new Set(res.data.map(item => item.output_type))];
        setUniqueTypes(uniqueTypes);
      }
    }

    checkSubscriptionStatus();
    loadData();
    loadUsage();
    loadOutputTypes();
    loadAllContent();
  }, []);

  useEffect(() => {
    if (selectedOutputTypeId != null && targetSectionRef.current) {
      targetSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedOutputTypeId]);

  async function loadContent(id) {
    let res = await getContentByOutputType(id);

    if (res.success) {
      setContent(res.data);
    }
  }


  async function handleButtonClick(type) {
    setSelectedOutputType(type);
  }

  async function handleCopy(text, id) {
    navigator.clipboard.writeText(text)
      .then(() => {
        document.getElementById('btncopy_' + id).innerHTML = 'Copied!';
        setTimeout(function (e) {
          document.getElementById('btncopy_' + id).innerHTML = `Copy <i class='fa fa-copy'></i>`;
        }, [1000])
        // alert("Text copied to clipboard!");
      })
      .catch(err => {
        //   alert("Failed to copy text.");
      });
  }

  async function handleDelete(id) {
    setShowDelete(true);
    setSelectedId(id);
  }

  async function handleConfirmDelete() {
    let res = await deleteContentItem(selectedId);

    if (res.success) {
      let newContent = allContent.filter(x => x.journey_interview_content_id != selectedId);
      setAllContent(newContent);
      setShowDelete(false);
      usageFetch();
    }
  }

  return (
    <>
      <ScrollToTop />
      <div className='contentstudio'>
        <button onClick={() => navigate('/content')} type="button" className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-bold rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path></svg> Back</button>

        <h2 className="flex justify-between align-center items-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
          <div>Your Content</div>
          <div>
            <Link to='/dashboard' className='py-3 px-4 inline-flex items-center gap-x-2 text-sm font-bold rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'><FaIcons.FaMicrophoneAlt /> Interviews </Link>
          </div>
        </h2>

        <div className="mt-10 bg-white border border-gray-200 rounded-lg p-4" role="alert" tabIndex="-1" aria-labelledby="hs-discovery-label">
          <div className="flex items-center justify-between">
            <div className='flex items-center gap-x-2'>
              <div className="shrink-0">
                <svg className="shrink-0 size-4 text-blue-600" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="12" cy="12" r="10"></circle>
                  <path d="M12 16v-4"></path>
                  <path d="M12 8h.01"></path>
                </svg>
              </div>

              {usageLoading == false && usageData != null && (
                <div className="ms-3">
                  <div id="hs-discovery-label" className="text-gray-800 text-[14px] font-semibold">
                    <div className={` truncate inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium ${usageData.total_content > 10 && accessLoaded == true && hasAccess == false
                        ? "bg-red-100 text-red-800 dark:bg-red-800/30 dark:text-red-500"
                        : ""
                      }`}>
                      <strong>Total usage:</strong> {usageData.total_content}

                      {accessLoaded == true && hasAccess == true && (
                        <>
                          {planName == 'Pro' && (
                            <span className='text-xs font-bold'>
                              / 150 items
                            </span>
                          )}

                          {planName == 'Unlimited' && (
                            <span className='text-xs/5 text-gray-500 font-normal'>
                              unlimited
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {(accessLoaded == true && hasAccess == false || (planName == 'Pro')) && (
              <div>
                <Link to='/upgrade' className='rounded-md bg-indigo-600 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>UPGRADE</Link>
              </div>
            )}
          </div>
        </div>

        {loaded == false && (
          <Spinner />
        )}

        {loaded == true && allContent.length == 0 && (
          <FadeIn delay={300} duration={300}>
            <div className='mt-20'>
              <p className='py-2 text-sm'>You have not generated any content yet.</p>
              <p className='py-2 text-sm'>Get started by taking your <Link className='link' to='/dashboard'>your first interview</Link>.</p>

            </div>
          </FadeIn>
        )}

        {loaded == true && allContent.length > 0 && (
          <FadeIn delay={300} duration={300}>

            <div className='mt-2'>
              <button
                key={'all'}
                onClick={() => handleButtonClick('')}
                className={`inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-md text-xs font-medium border border-gray-200  text-gray-800 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white ${selectedOutputType == '' ? 'bg-blue-100' : 'bg-white'}`}
              >
                All
              </button>
              {uniqueTypes.map((type) => (
                <button
                  key={type}
                  onClick={() => handleButtonClick(type)}
                  className={`inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-md text-xs font-medium border border-gray-200  text-gray-800 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white ${selectedOutputType == type ? 'bg-blue-100' : 'bg-white'}`}
                >
                  {type}
                </button>
              ))}

            </div>
            <div className='masonry'>
              {allContent.filter(x => (selectedOutputType == '' || x.output_type == selectedOutputType)).map((item, index) => (
                <div
                  key={index}
                  style={{
                    margin: '5px',
                    borderBottom: 'solid 1px #ddd',
                    fontSize: '14px'
                  }}
                  className=" masonry-item"
                >

                  <div className="flex min-w-0 gap-x-4" onClick={() => navigate(`/content/editor/${item.journey_interview_content_id}`)}>
                    <div className="min-w-0 flex-auto">
                      <p className='inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-800 text-white dark:bg-white dark:text-neutral-800'>
                        {item.output_type}
                      </p>

                      <p className='mt-2 text-xs/5 text-gray-500 font-bold mb-4'>
                        Date Generated: {new Date(item.date_created).toLocaleDateString()}
                      </p>

                      <p className="mt-1 text-xs/5 text-gray-500 text-sm">
                        <Markdown>{item.markdown_content}</Markdown>

                      </p>
                    </div>
                  </div>

                  <div className='flex items-center gap-x-2 mt-2'>
                    <button onClick={() => handleDelete(item.journey_interview_content_id)} id="btnCopy1" type="button" className="absolute top-[0px] right-0 bg-white-200 py-2 px-3 inline-flex items-center gap-x-2 text-lg border border-transparent text-gray-500 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"><FaIcons.FaTimesCircle /> </button>
                  </div>
                </div>
              ))}
            </div>
          </FadeIn>
        )}
      </div>

      {showDelete == true && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowDelete(false)}>
              <FaIcons.FaTimes />
            </div>
            <div className='modal__content'>
              <h2 className='text-xl font-bold'>Are you sure you want to delete this content?</h2>
              <div className='mt-4'>

                <button onClick={() => {
                  handleConfirmDelete()
                }} className='cursor-pointer bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500 mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10'>Confirm</button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default JourneyInterviewContentAll;