
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const TutorScreening = () => {
  return (
    <UseCaseTemplate 
      title="Tutor Screening"
      prompt="Generate 5 questions to evaluate potential math tutors for a 10th grade student struggling with algebra."
      questions={[
        "Could you explain how you would approach teaching a student who's struggling with algebraic equations?",
        "How do you adapt your teaching methods to different learning styles?",
        "Can you give an example of how you might explain a complex math concept in simple terms?",
        "What strategies do you use to keep students engaged and motivated during tutoring sessions?",
        "How do you track progress and provide constructive feedback to both students and parents?"
      ]}
      icon="📚"
      description="Hear how they explain things and interact. Voice responses reveal teaching style, patience, clarity of explanation, and enthusiasm for the subject."
      isPersonal={true}
    />
  );
};

export default TutorScreening;
