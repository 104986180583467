// ElevenLabsModal.jsx
import { useElevenLabsWidget } from "./useElevenLabsWidget";
import { useEffect, useRef } from "react";

const ElevenLabsModal = ({setShowModal, agent_id = 'w13J131fN2y78dSjS9Sk'}) => {
  const containerRef = useRef(null);
  useElevenLabsWidget();

  useEffect(() => {
    const el = containerRef.current?.querySelector("elevenlabs-convai");
    if (el) {
      Object.assign(el.style, {
        position: "relative",
        zIndex: "1000",
      });
    }
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.6)", // optional dim background
        zIndex: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div style={{textAlign: 'center'}}>
        <div>
          lkjasd
        </div>
          <elevenlabs-convai agent-id={agent_id}></elevenlabs-convai>

        <div>
          <button className="btn" onClick={() => setShowModal(false)}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ElevenLabsModal;
