import {useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { getBuyerObjections, getSellerObjections, addBuyerFeedback, addSellerFeedback, getBuyerDemoFromRef, getDemoQualifierQuestions, answerQualifierQuestion } from '../../../utils/api';
import imgScreenshot from './assets/2-1.png';
import { FaTimes } from 'react-icons/fa';
function SectionRightImageC() {
  const [showVideo, setShowVideo] = useState(false);
return (
  
<div class="overflow-hidden bg-white py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
      <div class="lg:pr-8 lg:pt-4">
        <div class="lg:max-w-lg">
          <h2 class="text-base/7 font-semibold text-indigo-600">Just 5 Minutes</h2>
          <p class="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Generate a variety of content</p>
          <p class="mt-6 text-lg/8 text-gray-600">Watch how we turned one interview into all of this content:</p>
          <dl class="mt-10 max-w-xl space-y-8 text-base/7 text-gray-600 lg:max-w-none">

          <div class="relative pl-9">
              <dt class="inline font-semibold text-gray-900">
                Blog Posts
              </dt>
            </div>

            <div class="relative pl-9">
              <dt class="inline font-semibold text-gray-900">
                LinkedIn Posts
              </dt>
            
            </div>

            <div class="relative pl-9">
              <dt class="inline font-semibold text-gray-900">
               
                Press Release
              </dt>
            </div>

            <div class="relative pl-9">
              <dt class="inline font-semibold text-gray-900">
               
                Welcome Email Sequence
              </dt>
            </div>

            <div class="relative pl-9">
              <dt class="inline font-semibold text-gray-900">
               
                AI Video Script
              </dt>
            </div>

            <div class="relative pl-9">
              <dt class="inline font-semibold text-gray-900">
               
                And more...
              </dt>
            </div>
          </dl>
        </div>
      </div>

      <img onClick={() => setShowVideo(true)} src={imgScreenshot} alt="Product screenshot" class="cursor-pointer w-[48rem] max-w-none rounded-xl ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" />
    </div>
  </div>

  {showVideo && (
    <>
      <div className='overlay'></div>
      <div className='modal' style={{width: '700px', marginLeft: '-350px'}}>
        <div className='modal__close' onClick={() => setShowVideo(false)}>
          <FaTimes />
        </div>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/Q3lelGioQns?si=FktnGDYWk2Z9F1Ns" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    </>
    )}
</div>
)
}

export default SectionRightImageC;