
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const FamilyMemories = () => {
  return (
    <UseCaseTemplate 
      title="Family Member Memory Project"
      prompt="Generate 5 questions to capture meaningful stories and memories from older family members for a family history project."
      questions={[
        "What's one of your earliest childhood memories that you recall vividly?",
        "Could you tell us about a family tradition from your childhood that was meaningful to you?",
        "What was one of the most challenging times in your life, and how did you get through it?",
        "What's something you wish your grandchildren and future generations would know about you or your life?",
        "Can you share a story about our family that you don't think many people know?"
      ]}
      icon="👵"
      description="Collect stories or perspectives from relatives without long interviews. Voice recordings preserve tone, emotion, and authenticity of family stories for future generations."
      isPersonal={true}
    />
  );
};

export default FamilyMemories;
