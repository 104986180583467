import { refresh } from "../services/auth.service";
const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3003";
/**
 * Defines the default headers for these functions to work with `json-server`
 */
const headers = new Headers();
headers.append("Content-Type", "application/json");

//et {logger} = require('./winstonLogger');
/**
 * Fetch `json` from the specified URL and handle error status codes and ignore `AbortError`s
 *
 * This function is NOT exported because it is not needed outside of this file.
 *
 * @param url
 *  the url for the requst.
 * @param options
 *  any options for fetch
 * @param onCancel
 *  value to return if fetch call is aborted. Default value is undefined.
 * @returns {Promise<Error|any>}
 *  a promise that resolves to the `json` data or an error.
 *  If the response is not in the 200 - 399 range the promise is rejected.
 */

export async function createGET(endpoint, is_authed = true) {
  const url = new URL(`${API_BASE_URL}/${endpoint}`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createGETWithRetry(endpoint) {
  const url = new URL(`${API_BASE_URL}/${endpoint}`);
  let access_token = localStorage.getItem("access_token");
  let options = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(url, options);

  if (response.status === 403) {
    await refresh();
    let access_token = localStorage.getItem("access_token");
    options.headers.Authorization = "Bearer " + access_token;

    const response = await fetch(url, options);
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  }

  return response.json();
}

export async function createPOST(endpoint, data) {
  const url = new URL(`${API_BASE_URL}/${endpoint}`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createPOSTWithRetry(endpoint, data) {
  const url = new URL(`${API_BASE_URL}/${endpoint}`);
  let access_token = localStorage.getItem("access_token");
  let options = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(url, options);

  if (response.status === 403) {
    await refresh();
    let access_token = localStorage.getItem("access_token");
    options.headers.Authorization = "Bearer " + access_token;

    const response = await fetch(url, options);
    if (response.status === 403)
    {
      window.location = '/login';
      return { success: false, status: 403 };
    }
    return response.json();
  }

  return response.json();
}

export async function createUser(data, signal) {
  const url = new URL(`${API_BASE_URL}/user/create`);
  const options = {
    method: "POST",
    headers,
    body: JSON.stringify({ data }),
    signal,
  };

  const response = await fetch(url, options).then((response) =>
    response.json()
  );
  return response;
  // return await fetch(url, options);
}

export async function createUserSeller(data) {
  const url = new URL(`${API_BASE_URL}/user/create/seller`);
  const options = {
    method: "POST",
    headers,
    body: JSON.stringify({ data }),
  };

  const response = await fetch(url, options).then((response) =>
    response.json()
  );
  return response;
}

export async function createUserEvent(data) {
  const url = new URL(`${API_BASE_URL}/user/create/event`);
  const options = {
    method: "POST",
    headers,
    body: JSON.stringify({ data }),
  };

  const response = await fetch(url, options).then((response) =>
    response.json()
  );
  return response;
}

export async function createUserJoinNow(data) {
  const url = new URL(`${API_BASE_URL}/user/create-w-password`);
  const options = {
    method: "POST",
    headers,
    body: JSON.stringify({ data }),
  };

  const response = await fetch(url, options).then((response) =>
    response.json()
  );
  return response;
}

export async function createUserHostInvite(data) {
  const url = new URL(`${API_BASE_URL}/user/create/event-host-invite`);
  const options = {
    method: "POST",
    headers,
    body: JSON.stringify({ data }),
  };

  const response = await fetch(url, options).then((response) =>
    response.json()
  );
  return response;
}

export async function forgotPassword(email) {
  const url = new URL(`${API_BASE_URL}/user/forgot-password`);
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ email }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function createCompany(
  name,
  company_size_id,
  industry_id,
  website = "",
  description = ""
) {
  const url = new URL(`${API_BASE_URL}/company/create`);
  const token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      name,
      company_size_id,
      industry_id,
      website,
      description,
    }),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createCompanyUser(company_id) {
  const url = new URL(`${API_BASE_URL}/company/add-user`);
  const token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ company_id }),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getCategories(signal) {
  const url = new URL(`${API_BASE_URL}/categories`);
  const response = await fetch(url, { headers }).then((response) =>
    response.json()
  );
  return response;
}

export async function searchCategories(query) {
  const url = new URL(`${API_BASE_URL}/category/search/${query}`);
  const response = await fetch(url).then((response) => response.json());
  return response;
}

export async function searchSellerCategories(query) {
  const url = new URL(`${API_BASE_URL}/category/sellers/${query}`);
  const response = await fetch(url).then((response) => response.json());
  return response;
}

// needs authentication
// add categories to your company
export async function searchInterestCategories(query) {
  const url = new URL(`${API_BASE_URL}/category/search-interest/${query}`);
  const token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function searchCompanyCategories(query) {
  const url = new URL(`${API_BASE_URL}/category/search-company/${query}`);
  const token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function searchCompanyCategory(query) {
  const url = new URL(`${API_BASE_URL}/search/companycategory/${query}`);
  const response = await fetch(url).then((response) => response.json());
  return response;
}

export async function searchSellers(query) {
  const url = new URL(`${API_BASE_URL}/search/sellers/${query}`);
  const response = await fetch(url).then((response) => response.json());
  return response;
}

export async function searchCompanyCustomerProfileCategory(query) {
  const url = new URL(
    `${API_BASE_URL}/company/search-customer-profile-categories/${query}`
  );
  const token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

// needs authentication
// add categories to your company
export async function addCategories(companyId, categories) {
  const url = new URL(`${API_BASE_URL}/company/add-categories`);
  const token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ companyId, categories }),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

// needs authentication
// add categories to your company
export async function addCustomerProfileCategories(companyId, categories) {
  const url = new URL(`${API_BASE_URL}/company/add-customer-profile`);
  const token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ companyId, categories }),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function updateCompanyCategory(category_id) {
  const url = new URL(`${API_BASE_URL}/company/category`);
  const token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ category_id }),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function updateCompanyCustomerProfileCategory(category_id) {
  const url = new URL(`${API_BASE_URL}/company/add-customer-profile`);
  const token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ category_id }),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getPersonaTypes(user_type) {
  const url = new URL(`${API_BASE_URL}/user/personatypes/${user_type}`);
  const response = await fetch(url, { headers }).then((response) =>
    response.json()
  );
  return response;
}

export async function searchCompanies(
  query,
  categories,
  companySizes,
  companyStages,
  perPage,
  companyId,
  currentPage,
  sortValue
) {
  try {
    const url = new URL(`${API_BASE_URL}/company/search`);
    let access_token = localStorage.getItem("access_token");

    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      body: JSON.stringify({
        query,
        categories,
        companySizes,
        companyStages,
        perPage,
        companyId,
        currentPage,
        sortValue,
      }),
      headers: {
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status === 403) return { success: false, status: 403 };
      return response.json();
    });

    return response;
  } catch (error) {
    return { success: false, error: error.message };
  }
}

export async function searchCompaniesDropdown(query) {
  try {
    const url = new URL(`${API_BASE_URL}/company/search/dropdown`);
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      body: JSON.stringify({ query }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
    return response;
  } catch (error) {
    return { success: false, error: error.message };
  }
}

export async function searchIndustries(query) {
  if (!query) query = "";
  try {
    const url = new URL(`${API_BASE_URL}/company/industries/search/${query}`);
    const response = await fetch(url).then((response) => response.json());
    return response;
  } catch (error) {
    return "Error has occurred";
  }
}

export async function searchIndustriesWithBuyers(query) {
  if (!query) query = "";
  try {
    const url = new URL(
      `${API_BASE_URL}/company/buyer-industries/search/${query}`
    );
    const response = await fetch(url).then((response) => response.json());
    return response;
  } catch (error) {
    return "Error has occurred";
  }
}

export async function searchDepartments(query) {
  if (!query) query = "";
  try {
    const url = new URL(`${API_BASE_URL}/company/departments/search/${query}`);
    const response = await fetch(url).then((response) => response.json());
    return response;
  } catch (error) {
    return "Error has occurred";
  }
}

export async function searchJobTitles(query) {
  if (!query) query = "";
  try {
    const url = new URL(`${API_BASE_URL}/company/jobtitles/search/${query}`);
    const response = await fetch(url).then((response) => response.json());
    return response;
  } catch (error) {
    return "Error has occurred";
  }
}

export async function getCompanySizes() {
  const url = new URL(`${API_BASE_URL}/company/companysizes`);

  const response = await fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getAllIndustries() {
  const url = new URL(`${API_BASE_URL}/company/all-industries`);
  const response = await fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getCompanyIndustries() {
  const url = new URL(`${API_BASE_URL}/company/industries`);
  const response = await fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getIndustriesWithBuyers() {
  const url = new URL(`${API_BASE_URL}/company/buyer-industries`);
  const response = await fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getIndustriesWithSellers() {
  const url = new URL(`${API_BASE_URL}/company/seller-industries`);
  const response = await fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getDepartmentsWithBuyers() {
  const url = new URL(`${API_BASE_URL}/company/buyer-departments`);
  const response = await fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getSeniorityLevels() {
  const url = new URL(`${API_BASE_URL}/company/seniority`);

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getCompanyStages() {
  const url = new URL(`${API_BASE_URL}/company/stages`);

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getCompanyReviewAverages(company_id) {
  const url = new URL(`${API_BASE_URL}/company/review-averages/${company_id}`);

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getCompanyReviews(company_id) {
  const url = new URL(`${API_BASE_URL}/company/reviews/${company_id}`);

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getCompanyReviewCriteria(review_id) {
  const url = new URL(`${API_BASE_URL}/company/review-criteria/${review_id}`);

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function resendVerification(email) {
  const url = new URL(`${API_BASE_URL}/user/resend-validation`);
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ email }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function resendEmailVerification(email) {
  const url = new URL(`${API_BASE_URL}/user/resend-verification`);
  const token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function validateToken(token) {
  const url = new URL(`${API_BASE_URL}/user/validate-token/${token}`);
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function verifyEmail(token) {
  const url = new URL(`${API_BASE_URL}/user/verify-email/${token}`);
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function isVerified() {
  const response = await createGETWithRetry("user/verified");
  return response;
  
  // const url = new URL(`${API_BASE_URL}/user/verified`);
  // const token = localStorage.getItem("access_token");
  // const response = await fetch(url, {
  //   method: "GET",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   headers: {
  //     Authorization: "Bearer " + token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function validateForgotPassword(token) {
  const url = new URL(`${API_BASE_URL}/user/validate-forgot-password`);
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ token }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function setPassword(password, token, role_type) {
  const url = new URL(`${API_BASE_URL}/user/set-password`);
  let data = { password, token, role_type };
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function resetPassword(password, token, role_type) {
  const url = new URL(`${API_BASE_URL}/user/reset-password`);
  let data = { password, token, role_type };
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

// search page save button
export async function saveCompany(company_id) {
  const url = new URL(`${API_BASE_URL}/company/save`);
  let data = { company_id };
  const token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

// search page save button
export async function unsaveCompany(company_id, user_id) {
  const url = new URL(`${API_BASE_URL}/company/unsave`);
  let data = { company_id, user_id };
  const token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getSavedCompanies() {
  const url = new URL(`${API_BASE_URL}/company/saved`);
  const token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function saveBuyer(buyer_id) {
  const url = new URL(`${API_BASE_URL}/buyer/save`);
  let data = { buyer_id };
  const token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getSavedBuyers() {
  const url = new URL(`${API_BASE_URL}/buyer/saved`);
  const token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function searchBuyers(
  query,
  personaTypeId,
  industries,
  departments,
  jobTitles,
  seniority,
  sizes,
  currentPage,
  selectedCompanyId
) {
  if (!query) query = "";
  try {
    const url = new URL(`${API_BASE_URL}/buyer/search`);
    const token = localStorage.getItem("access_token");

    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      body: JSON.stringify({
        query,
        personaTypeId,
        industries,
        departments,
        jobTitles,
        seniority,
        sizes,
        currentPage,
        selectedCompanyId,
      }),
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status === 403) return { success: false, status: 403 };
      return response.json();
    });

    return response;
  } catch (error) {
    return "Error has occurred";
  }
}

export async function getBuyers(
  currentPage,
  industry_id,
  company_size_id,
  seniority_level_id,
  department_id
) {
  try {
    const url = new URL(`${API_BASE_URL}/buyer/get`);
    const token = localStorage.getItem("access_token");

    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      body: JSON.stringify({
        currentPage,
        industry_id,
        company_size_id,
        seniority_level_id,
        department_id,
      }),
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status === 403) return { success: false, status: 403 };
      return response.json();
    });

    return response;
  } catch (error) {
    return "Error has occurred";
  }
}

export async function savedSearchBuyers() {
  try {
    const url = new URL(`${API_BASE_URL}/buyer/saved-search`);
    const token = localStorage.getItem("access_token");

    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());

    return response;
  } catch (error) {
    return "Error has occurred";
  }
}

// export async function updateSellerProfile(user){
//   const url = new URL(`${API_BASE_URL}/user/seller-profile`);
//  // let user = {data};
//   console.log('update seller profile', user);
//   const response = await fetch(url, {
//     method: 'POST',
//     mode: 'cors',
//     cache: 'no-cache',
//     credentials: 'same-origin',
//     body: JSON.stringify(user),
//     headers: {
//       'Content-Type': 'application/json'
//     }}).then(response => response.json());

//   return response;
// }

export async function loginUser(email, password) {
  let channel_cookie = getCookie("channel_token");

  const url = new URL(`${API_BASE_URL}/user/login`);
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ email, password, channel_cookie }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function sendPhoneVerification(phone_number) {
  const url = new URL(`${API_BASE_URL}/user/send-phone-verification`);
  // let user = {data};
  //   console.log('update buyer profile', user);
  const token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ phone_number }),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function verifyPhoneToken(phone_number, code) {
  const url = new URL(`${API_BASE_URL}/user/verify-phone`);
  const token = localStorage.getItem("access_token");
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ phone_number, code }),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addPhoneNumber(phone_number) {
  const url = new URL(`${API_BASE_URL}/user/add-phone`);
  const token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ phone_number }),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function saveCompanyProfile(company) {
  // let {company_id, company_size_id, industry_id} = company;
  const url = new URL(`${API_BASE_URL}/company/save-company-profile`);

  //   console.log('update buyer profile', user);
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(company),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function saveBuyerProfile(
  job_title,
  seniority_level_id,
  department,
  user_id
) {
  // let {company_id, company_size_id, industry_id} = company;
  const url = new URL(`${API_BASE_URL}/buyer/update`);

  //   console.log('update buyer profile', user);
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      job_title,
      seniority_level_id,
      department,
      user_id,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function saveUserProfile(
  job_title,
  seniority_level_id,
  department_id,
  linkedin_url
) {
  const url = new URL(`${API_BASE_URL}/user/update-profile`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      job_title,
      seniority_level_id,
      department_id,
      linkedin_url,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getOAuthUrl() {
  // let {company_id, company_size_id, industry_id} = company;
  const url = new URL(`${API_BASE_URL}/user/oauth-url`);
  let access_token = localStorage.getItem("access_token");

  //   console.log('update buyer profile', user);
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getOAuthTokens(code) {
  // let {company_id, company_size_id, industry_id} = company;
  const url = new URL(`${API_BASE_URL}/user/oauth-tokens`);
  let access_token = localStorage.getItem("access_token");

  //   console.log('update buyer profile', user);
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ code }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

// retrieve company information for editing
// use JWT token for authentication
// return the company associated with current user
export async function getCompanyInfo() {
  // let {company_id, company_size_id, industry_id} = company;
  const url = new URL(`${API_BASE_URL}/company`);

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

// Company.js
export async function getCompany(company_id) {
  const url = new URL(`${API_BASE_URL}/company/company/${company_id}`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getCompanyFromSlug(slug) {
  const url = new URL(`${API_BASE_URL}/company/slug`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ slug }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function refreshToken() {
  let access_token = localStorage.getItem("access_token");
  let refresh_token = localStorage.getItem("refresh_token");

  const url = new URL(`${API_BASE_URL}/refresh`);

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ refreshToken: refresh_token }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function hRefreshToken() {
  let access_token = localStorage.getItem("access_token");
  let refresh_token = localStorage.getItem("refresh_token");

  // let {company_id, company_size_id, industry_id} = company;
  const url = new URL(`${API_BASE_URL}/hardrefresh`);

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ refreshToken: refresh_token }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  if (response.success) {
    let tokens = response.data;

    localStorage.setItem("access_token", tokens.accessToken);
    localStorage.setItem("refresh_token", tokens.refreshToken);

    setCookie("access_token", tokens.accessToken, 1);
  }

  return response;
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  let _domain = `domain=${process.env.REACT_APP_SITE_DOMAIN}`;
  document.cookie =
    cname + "=" + cvalue + ";" + _domain + ";" + expires + ";path=/";
}

export async function getUserProfile() {
  let access_token = localStorage.getItem("access_token");
  const url = new URL(`${API_BASE_URL}/user/profile`);

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getPublicUserProfile(slug) {
  let access_token = localStorage.getItem("access_token");
  const url = new URL(`${API_BASE_URL}/user/public-profile/${slug}`);

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addUrlSlug(slug) {
  let access_token = localStorage.getItem("access_token");
  const url = new URL(`${API_BASE_URL}/user/url-slug`);

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ url_slug: slug }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getUserProfileFromId(id) {
  if (id === null) id = 0;
  let access_token = localStorage.getItem("access_token");
  const url = new URL(`${API_BASE_URL}/user/profile/${id}`);

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

// update data in database record
export async function updateRecord(ht, htw, t) {}

export async function saveUserName(firstName, lastName) {
  // let {company_id, company_size_id, industry_id} = company;
  const url = new URL(`${API_BASE_URL}/user/update/name`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ firstName, lastName }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function updateUser(user) {
  const url = new URL(`${API_BASE_URL}/user/update`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(user),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function updateUserProfile(user) {
  const url = new URL(`${API_BASE_URL}/user/update-profile`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(user),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function updateCompany(company) {
  const url = new URL(`${API_BASE_URL}/company/update`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(company),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function updateSellerProfile(seller_profile) {
  const url = new URL(`${API_BASE_URL}/user/seller-profile`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(seller_profile),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function updatePersonaType(persona_type_id, role_type) {
  const url = new URL(`${API_BASE_URL}/user/update/persona-type`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ persona_type_id, role_type }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getUserCompany() {
  const response = await createGETWithRetry("company/usercompany");
  return response;

  // const url = new URL(`${API_BASE_URL}/company/usercompany`);
  // let access_token = localStorage.getItem("access_token");

  // const response = await fetch(url, {
  //   method: "GET",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function getUserCompanyFromId(id) {
  if (id === null) id = 0;
  const url = new URL(`${API_BASE_URL}/company/usercompany/${id}`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

// 1/19/2022
export async function uploadCompanyLogo(file) {
  const url = new URL(`${API_BASE_URL}/company/uploadlogo`);
  let access_token = localStorage.getItem("access_token");

  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
    },
    body: formData,
  }).then((response) => response.json());

  return response;
}

export async function getUserCompanyCategories() {
  const url = new URL(`${API_BASE_URL}/company/usercategories`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getCompanyCustomerProfileCategories() {
  const url = new URL(`${API_BASE_URL}/company/customer-profile-categories`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getCompanyCategories(company_id) {
  const url = new URL(`${API_BASE_URL}/company/categories/${company_id}`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function updatePassword(currentPassword, newPassword) {
  const url = new URL(`${API_BASE_URL}/user/updatepassword`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ currentPassword, newPassword }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function savePricingLead(data) {
  const url = new URL(`${API_BASE_URL}/user/save-pricing-lead`);
  // let access_token = localStorage.getItem('access_token');

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

// passes refresh_token to api endpoint
// updates access token and refresh token
export async function authenticatedAPI(callback) {
  await refreshToken();
  callback();
}

export async function getPendingBuyerDemos(data) {
  const url = new URL(`${API_BASE_URL}/demo/pending/seller`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getBuyerDemoFromCompanyId(company_id) {
  const url = new URL(`${API_BASE_URL}/demo/company/${company_id}`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createBuyerDemos(data) {
  const url = new URL(`${API_BASE_URL}/demo/create/buyer`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createBuyerDemo(data) {
  const url = new URL(`${API_BASE_URL}/demo/create/single-buyer`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function createSellerDemo(data) {
  const response = await createPOSTWithRetry("demo/create/seller", data);
  return response;

  // const url = new URL(`${API_BASE_URL}/demo/create/seller`);
  // let access_token = localStorage.getItem("access_token");

  // const response = await fetch(url, {
  //   method: "POST",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   body: JSON.stringify(data),
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response.json());

  // return response;
}

export async function buyerCreatesSellerDemo(data) {
  const url = new URL(`${API_BASE_URL}/demo/create/buyertoseller`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addReview(data) {
  const url = new URL(`${API_BASE_URL}/review/add`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addReviewCriteria(data) {
  const url = new URL(`${API_BASE_URL}/review/add/criteria`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getUserInterest() {
  const url = new URL(`${API_BASE_URL}/user/interest`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getUserInterestFromId(id) {
  if (id === null) id = 0;

  const url = new URL(`${API_BASE_URL}/user/interest/${id}`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addUserInterest(category_id, interest_level) {
  const url = new URL(`${API_BASE_URL}/user/interest/add`);
  let access_token = localStorage.getItem("access_token");
  let data = { category_id, interest_level };

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function removeUserInterest(category_id, interest_level) {
  const url = new URL(`${API_BASE_URL}/user/interest/remove`);
  let access_token = localStorage.getItem("access_token");
  let data = { category_id, interest_level };

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function companyInvite(name, email, role_type) {
  const url = new URL(`${API_BASE_URL}/company/invite`);
  let access_token = localStorage.getItem("access_token");
  let data = { name, email, role_type };

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getCompanyInvites() {
  const url = new URL(`${API_BASE_URL}/company/invites`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getSellerDashboardCosts() {
  const url = new URL(`${API_BASE_URL}/dashboard/seller/costs`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getSellerDashboardStats() {
  const url = new URL(`${API_BASE_URL}/dashboard/seller/stats`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getBuyerDashboardEarnings() {
  const url = new URL(`${API_BASE_URL}/dashboard/buyer/earnings`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getBuyerDashboardStats() {
  const url = new URL(`${API_BASE_URL}/dashboard/buyer/stats`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getPayoutTransactions() {
  const url = new URL(`${API_BASE_URL}/payout/transactions`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getPayoutMethods(type = "Online") {
  const url = new URL(`${API_BASE_URL}/payout/methods/${type}`);

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function savePayoutMethod(payout_method_id, source) {
  const url = new URL(`${API_BASE_URL}/payout/method/save`);
  let access_token = localStorage.getItem("access_token");
  let data = { payout_method_id, source };

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(data),
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getUserPayoutMethods() {
  const url = new URL(`${API_BASE_URL}/payout/methods/user`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function removePayoutMethod(payout_method_id) {
  const url = new URL(`${API_BASE_URL}/payout/method/remove`);
  let access_token = localStorage.getItem("access_token");
  let data = { payout_method_id };

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    body: JSON.stringify(data),
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function setPrimaryPayoutMethod(payout_method_id) {
  const url = new URL(`${API_BASE_URL}/payout/method/setprimary`);
  let access_token = localStorage.getItem("access_token");
  let data = { payout_method_id };

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    body: JSON.stringify(data),
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getUserWorkHours() {
  const url = new URL(`${API_BASE_URL}/user/workhours`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getBuyerWorkHours(registered_user_id) {
  const url = new URL(
    `${API_BASE_URL}/user/buyer-workhours/${registered_user_id}`
  );
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getSellerWorkHours(registered_user_id) {
  const url = new URL(
    `${API_BASE_URL}/user/seller-workhours/${registered_user_id}`
  );
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getBuyerDemos() {
  const url = new URL(`${API_BASE_URL}/demo/buyer`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getBuyerDemosRescheduled() {
  const url = new URL(`${API_BASE_URL}/demo/buyer-rescheduled`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getSellerDemos() {
  const url = new URL(`${API_BASE_URL}/demo/seller`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getSellerDemosAvailable() {
  const url = new URL(`${API_BASE_URL}/demo/seller-available`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getSellerDemosReschedule() {
  const url = new URL(`${API_BASE_URL}/demo/seller-reschedule`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getSellerDemosRescheduleCount() {
  const url = new URL(`${API_BASE_URL}/demo/seller-reschedule-count`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getTeamDemosPending() {
  const response = await createGETWithRetry("demo/team-pending");
  return response;

  // const url = new URL(`${API_BASE_URL}/demo/team-pending`);
  // let access_token = localStorage.getItem("access_token");

  // const response = await fetch(url, {
  //   method: "GET",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function getTeamDemosUpcoming() {
  const url = new URL(`${API_BASE_URL}/demo/team-upcoming`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getTeamDemosCompleted() {
  const url = new URL(`${API_BASE_URL}/demo/team-completed`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getTeamDemosCompletedSearch(query) {
  const url = new URL(`${API_BASE_URL}/demo/team-completed-search`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ query }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getTeamDemosIncomplete() {
  const url = new URL(`${API_BASE_URL}/demo/team-incomplete`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getBuyerObjections() {
  const url = new URL(`${API_BASE_URL}/demo/objections/buyer`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getSellerObjections() {
  const url = new URL(`${API_BASE_URL}/demo/objections/seller`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getSellerObjectionBreakdown() {
  const url = new URL(`${API_BASE_URL}/dashboard/seller/objections`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

// export async function getCompaniesReview(page, category_id) {
//   if (!page)
//     page = 1;
export async function getCompaniesReview(page, query, category_id) {
  if (!page) page = 1;
  if (!category_id) category_id = 0;
  const url = new URL(`${API_BASE_URL}/company/reviewcompanies/${page}`);
  let access_token = localStorage.getItem("access_token");
  let data = { query, category_id };

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    body: JSON.stringify(data),
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

// buyer is accepting the demo
export async function acceptDemoBuyer(
  demo_id,
  selectedDate,
  selectedTime,
  selectedDateTime
) {
  const url = new URL(`${API_BASE_URL}/demo/accept/buyer`);
  let access_token = localStorage.getItem("access_token");
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      demo_id,
      selectedDate,
      selectedTime,
      selectedDateTime,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
  return response;
}

export async function acceptDemoSeller(demo_id) {
  const url = new URL(`${API_BASE_URL}/demo/accept/seller`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

// buyer is accepting the demo
export async function buyerRescheduleSeller(data) {
  const url = new URL(`${API_BASE_URL}/demo/reschedule/buyertoseller`);
  let access_token = localStorage.getItem("access_token");
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
  return response;
}

// buyer is accepting the demo
export async function sellerRescheduleBuyer(data) {
  const url = new URL(`${API_BASE_URL}/demo/reschedule/sellertobuyer`);
  let access_token = localStorage.getItem("access_token");
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
  return response;
}

export async function declineDemoSeller(demo_id, source) {
  const url = new URL(`${API_BASE_URL}/demo/decline/seller`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id, source }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function declineDemoBuyer(demo_id, source, declineReason) {
  const url = new URL(`${API_BASE_URL}/demo/decline/buyer`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id, source, declineReason }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getBuyerDemoFromToken(token) {
  const url = new URL(`${API_BASE_URL}/demo/pending/${token}`);

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addBuyerFeedback(
  demo_id,
  moving_to_proposal,
  objection_reason_id,
  feedback_text
) {
  const url = new URL(`${API_BASE_URL}/demo/feedback/buyer`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      demo_id,
      moving_to_proposal,
      objection_reason_id,
      feedback_text,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addBuyerFeedbackNew(
  demo_id,
  moving_to_proposal,
  buyer_feedback_option_id,
  feedback1,
  feedback2,
  feedback3,
  problem_looking_to_solve,
  does_product_fix,
  other_providers,
  current_budget,
  implementation_time,
  plan_to_address,
  other_software,
  decline_reason
) {
  const url = new URL(`${API_BASE_URL}/demo/add-buyer-feedback`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      demo_id,
      moving_to_proposal,
      buyer_feedback_option_id,
      feedback1,
      feedback2,
      feedback3,
      problem_looking_to_solve,
      does_product_fix,
      other_providers,
      current_budget,
      implementation_time,
      plan_to_address,
      other_software,
      feedback_text: decline_reason,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addSellerFeedback(
  demo_id,
  moving_to_proposal,
  estimated_value,
  seller_objection_reason_id,
  feedback_text
) {
  const url = new URL(`${API_BASE_URL}/demo/feedback/seller`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      demo_id,
      moving_to_proposal,
      estimated_value,
      seller_objection_reason_id,
      feedback_text,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getUserEmail() {
  const url = new URL(`${API_BASE_URL}/user/email`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function withdraw(charity_amount) {
  const url = new URL(`${API_BASE_URL}/payout/withdraw`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      charity_amount,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function withdrawToCharity(charity_campaign_id) {
  const url = new URL(`${API_BASE_URL}/payout/withdraw-charity`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      charity_campaign_id,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addSellerDeclineReason(
  demo_id,
  decline_reason_id,
  decline_text
) {
  const url = new URL(`${API_BASE_URL}/demo/decline-reason/seller`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      demo_id,
      decline_reason_id,
      decline_text,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addBuyerDeclineReason(
  demo_id,
  decline_reason_id,
  decline_text
) {
  const url = new URL(`${API_BASE_URL}/demo/decline-reason/buyer`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      demo_id,
      decline_reason_id,
      decline_text,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addBuyerDeclineReasonFromToken(
  demo_id,
  decline_reason_id,
  decline_text,
  token
) {
  const url = new URL(`${API_BASE_URL}/demo/decline-reason/pending-buyer`);

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      demo_id,
      decline_reason_id,
      decline_text,
      token,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function saveWorkHour(data) {
  const url = new URL(`${API_BASE_URL}/user/workhour`);
  let access_token = localStorage.getItem("access_token");
  //let data = {day_of_week, start_time};

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getSellerDemoHours(user_id, selected_date) {
  const url = new URL(`${API_BASE_URL}/demo/hours/seller`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ user_id, selected_date }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getSellerDemoTimes(user_id) {
  const url = new URL(`${API_BASE_URL}/demo/seller-demo-times/${user_id}`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function calendarIsSynced() {
  const url = new URL(`${API_BASE_URL}/user/google-calendar-sync`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function saveOauthTokens(access_token, refresh_token) {
  const url = new URL(`${API_BASE_URL}/user/save-oauth-tokens`);
  let auth_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ access_token, refresh_token }),
    headers: {
      Authorization: "Bearer " + auth_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function connectZoom(code) {
  const url = new URL(`${API_BASE_URL}/zoom/connectZoom`);
  let auth_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ code: code }),
    headers: {
      Authorization: "Bearer " + auth_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function zoomDeauthenticate() {
  const url = new URL(`${API_BASE_URL}/zoom/deauthenticate`);
  let auth_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + auth_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function zoomIsSynced() {
  const url = new URL(`${API_BASE_URL}/zoom/isSynced`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getBuyerDemoFromRef(referenceId) {
  const url = new URL(`${API_BASE_URL}/demo/get/${referenceId}`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getDemoFromRef(referenceId) {
  const url = new URL(`${API_BASE_URL}/demo/get/${referenceId}`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getDemoFromRefForTeam(referenceId) {
  const url = new URL(`${API_BASE_URL}/demo/get-team/${referenceId}`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getDemoFromRefWithTimes(referenceId) {
  const url = new URL(`${API_BASE_URL}/demo/get-with-times/${referenceId}`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createZoomMeeting(demo_id) {
  const url = new URL(`${API_BASE_URL}/zoom/createMeeting`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id: demo_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getSellerDeclinedReasons() {
  const url = new URL(`${API_BASE_URL}/demo/declined-reasons/seller`);

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getBuyerDeclinedReasons() {
  const response = await createGET("demo/declined-reasons/buyer");
  return response;

  // const url = new URL(`${API_BASE_URL}/demo/declined-reasons/buyer`);

  // const response = await fetch(url, {
  //   method: "GET",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response.json());

  // return response;
}

export async function sendInvites(emails) {
  const url = new URL(`${API_BASE_URL}/user/send-invites`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ emails }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getSignUpCategories() {
  const url = new URL(`${API_BASE_URL}/category/signup`);

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getRandomCategories() {
  const url = new URL(`${API_BASE_URL}/category/random`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function sellerRequestCredit(
  number_of_credits,
  cost_per_credit,
  cost_of_credits
) {
  const url = new URL(`${API_BASE_URL}/credit/request`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      number_of_credits,
      cost_per_credit,
      cost_of_credits,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getSellerCredits() {
  const url = new URL(`${API_BASE_URL}/credit/seller`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getSellerCalendarEvents(id) {
  const url = new URL(`${API_BASE_URL}/user/calendar-events/${id}`);
  //let access_token = localStorage.getItem('access_token');

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function abconvert(campaign_id, version, event, source) {
  const url = new URL(`${API_BASE_URL}/page/abconvert`);

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ campaign_id, version, event, source }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addQualifierQuestion(question) {
  const url = new URL(`${API_BASE_URL}/company/qualifier-question`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ question }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function removeQualifierQuestion(qualifier_question_id) {
  const url = new URL(`${API_BASE_URL}/company/qualifier-question/delete`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ qualifier_question_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function updateQualifierQuestion(qualifier_question_id, question) {
  const url = new URL(`${API_BASE_URL}/company/qualifier-question/update`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ qualifier_question_id, question }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getDemoQualifierQuestions(demo_id) {
  const url = new URL(`${API_BASE_URL}/demo/qualifier-questions/${demo_id}`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function answerQualifierQuestion(
  qualifier_question_id,
  answer,
  demo_id
) {
  const url = new URL(`${API_BASE_URL}/demo/qualifier-answer`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ qualifier_question_id, answer, demo_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getDepartments() {
  const url = new URL(`${API_BASE_URL}/company/departments`);
  const response = await fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function saveDemoAvailableTimes(demo_id, times, timezone) {
  const url = new URL(`${API_BASE_URL}/demo/available-times`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id, times, timezone }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function saveDemoAvailableTimesForInvite(
  token,
  times,
  timezone,
  answer
) {
  const url = new URL(`${API_BASE_URL}/demo/available-times-invite`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ token, times, timezone, answer }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function saveRescheduleAvailableTimes(demo_id, times, timezone) {
  const url = new URL(`${API_BASE_URL}/demo/reschedule-available-times`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id, times, timezone }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function sellerConfirmsDemo(
  demo_id,
  available_time,
  demo_available_time_id,
  meeting_link = ""
) {
  const url = new URL(`${API_BASE_URL}/demo/seller-confirm`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      demo_id,
      available_time,
      demo_available_time_id,
      meeting_link,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function sellerConfirmsRescheduleDemo(
  demo_id,
  available_time,
  demo_available_time_id
) {
  const url = new URL(`${API_BASE_URL}/demo/seller-confirm-reschedule`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id, available_time, demo_available_time_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function sellerReschedule(demo_id) {
    let data = { demo_id };
    const response = await createPOSTWithRetry("demo/seller-reschedule", data);
    return response;

  // const url = new URL(`${API_BASE_URL}/demo/seller-reschedule`);
  // let access_token = localStorage.getItem("access_token");

  // const response = await fetch(url, {
  //   method: "POST",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   body: JSON.stringify({ demo_id }),
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function addCompanyPreferenceDepartment(department_id) {
  const url = new URL(`${API_BASE_URL}/company/save-icp-department`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ department_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function removeCompanyPreferenceDepartment(department_id) {
  const url = new URL(`${API_BASE_URL}/company/remove-icp-department`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ department_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addCompanyPreferenceIndustry(industry_id) {
  const url = new URL(`${API_BASE_URL}/company/save-icp-industry`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ industry_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function removeCompanyPreferenceIndustry(industry_id) {
  const url = new URL(`${API_BASE_URL}/company/remove-icp-industry`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ industry_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addCompanyPreferenceSeniority(seniority_level_id) {
  const url = new URL(`${API_BASE_URL}/company/save-icp-seniority`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ seniority_level_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function removeCompanyPreferenceSeniority(seniority_level_id) {
  const url = new URL(`${API_BASE_URL}/company/remove-icp-seniority`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ seniority_level_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addCompanyPreferenceSize(company_size_id) {
  const url = new URL(`${API_BASE_URL}/company/save-icp-size`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ company_size_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function removeCompanyPreferenceSize(company_size_id) {
  const url = new URL(`${API_BASE_URL}/company/remove-icp-size`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ company_size_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getCompanyPreferenceSeniority() {
  const url = new URL(`${API_BASE_URL}/company/get-icp-seniority`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getCompanyPreferenceDepartment() {
  const url = new URL(`${API_BASE_URL}/company/get-icp-department`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getCompanyPreferenceIndustry() {
  const url = new URL(`${API_BASE_URL}/company/get-icp-industry`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getCompanyPreferenceSize() {
  const url = new URL(`${API_BASE_URL}/company/get-icp-size`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getAvailableSellers() {
  const url = new URL(`${API_BASE_URL}/company/available-sellers`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getAvailableSellersAll() {
  const url = new URL(`${API_BASE_URL}/company/available-sellers-all`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getCompaniesForHome() {
  const url = new URL(`${API_BASE_URL}/company/for-home`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function searchAvailableSellers(query, selectedIndustry = 0) {
  const url = new URL(`${API_BASE_URL}/company/search-companies`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ query, selectedIndustry }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function searchComingSoonCompanies(query, selectedIndustry = 0) {
  const url = new URL(`${API_BASE_URL}/company/search-coming-soon-companies`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ query, selectedIndustry }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function buyerConfirmsDemo(token) {
  const url = new URL(`${API_BASE_URL}/demo/buyer-confirm`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ token }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getDemoMessages(reference_id) {
  const url = new URL(`${API_BASE_URL}/demo/demo-messages/${reference_id}`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getInbox() {
  const url = new URL(`${API_BASE_URL}/dashboard/inbox`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addDemoMessages(reference_id, message) {
  const url = new URL(`${API_BASE_URL}/demo/add-demo-message`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ reference_id, message }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addCompanyMedia(media_type, media_source) {
  const url = new URL(`${API_BASE_URL}/company/add-media`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ media_type, media_source }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getCompanyMedia() {
  const url = new URL(`${API_BASE_URL}/company/get-media`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getPublicCompanyMedia(company_id) {
  const url = new URL(
    `${API_BASE_URL}/company/get-company-media/${company_id}`
  );
  //let access_token = localStorage.getItem('access_token');

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function removeCompanyMedia(company_media_id) {
  const url = new URL(`${API_BASE_URL}/company/remove-media`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ company_media_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getBuyerUnreadMessages() {
  const url = new URL(`${API_BASE_URL}/dashboard/buyer/unread-messages`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getSellerUnreadMessages() {
  const url = new URL(`${API_BASE_URL}/dashboard/seller/unread-messages`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createNotification(identifier, enable_sms, enable_email) {
  const url = new URL(`${API_BASE_URL}/user/create-notification`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ identifier, enable_sms, enable_email }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getNotifications() {
  const url = new URL(`${API_BASE_URL}/user/account-notifications`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getInvitations() {
  const url = new URL(`${API_BASE_URL}/user/user-invite`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getCompanyInvite(token) {
  const url = new URL(`${API_BASE_URL}/company/get-invite`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ token }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function pauseCompany(isPaused) {
  const url = new URL(`${API_BASE_URL}/company/pause`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ isPaused }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getSellerFeedbackCriteria() {
  const url = new URL(`${API_BASE_URL}/demo/seller-feedback-criteria`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getBuyerFeedbackCriteria() {
  const url = new URL(`${API_BASE_URL}/demo/buyer-feedback-criteria`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addSellerFeedbackCriteria(
  seller_feedback_id,
  seller_feedback_criteria_id,
  value
) {
  const url = new URL(
    `${API_BASE_URL}/demo/add-seller-feedback-criteria-value`
  );
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      seller_feedback_id,
      seller_feedback_criteria_id,
      value,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addBuyerFeedbackCriteria(
  buyer_feedback_id,
  buyer_feedback_criteria_id,
  value
) {
  const url = new URL(`${API_BASE_URL}/demo/add-buyer-feedback-criteria-value`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      buyer_feedback_id,
      buyer_feedback_criteria_id,
      value,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getCountries() {
  const url = new URL(`${API_BASE_URL}/user/countries`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function createNewCustomer() {
  const url = new URL(`${API_BASE_URL}/stripe/create-customer`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function createSetupIntent() {
  const url = new URL(`${API_BASE_URL}/stripe/create-setup-intent`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getCustomerId() {
  const url = new URL(`${API_BASE_URL}/stripe/customerid`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function checkHasPaymentMethod() {
  const response = await createGETWithRetry("stripe/has-payment-method");
  return response;

  // const url = new URL(`${API_BASE_URL}/stripe/has-payment-method`);
  // let access_token = localStorage.getItem("access_token");

  // const response = await fetch(url, {
  //   method: "GET",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function getPaymentMethods() {
  const url = new URL(`${API_BASE_URL}/stripe/payment-methods`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function removePaymentMethod(payment_method_id) {
  const url = new URL(`${API_BASE_URL}/stripe/remove-payment-method`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ payment_method_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getCompanyTeam() {
  const response = await createGETWithRetry("company/get-team");
  return response;

  // const url = new URL(`${API_BASE_URL}/company/get-team`);
  // let access_token = localStorage.getItem("access_token");

  // const response = await fetch(url, {
  //   method: "GET",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function checkTeamInvite(token) {
  const url = new URL(`${API_BASE_URL}/user/check-team-invite`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ token }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addDemoInterest(demo_id, interest_level) {
  const url = new URL(`${API_BASE_URL}/demo/add-demo-interest`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id, interest_level }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function saveSiteFeedback(feedback_text, subject) {
  const url = new URL(`${API_BASE_URL}/user/save-feedback`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ feedback_text, subject }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addBuyerRequest(request_text) {
  const url = new URL(`${API_BASE_URL}/buyer/add-request`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ request_text }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addDemoNoShow(demo_id) {
  const url = new URL(`${API_BASE_URL}/demo/demo-noshow`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addCompanyCategory(category) {
  const url = new URL(`${API_BASE_URL}/company/add-category`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ category }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

function success(pos) {
  const crd = pos.coords;

  console.log("Your current position is:");
  console.log(`Latitude : ${crd.latitude}`);
  console.log(`Longitude: ${crd.longitude}`);
  console.log(`More or less ${crd.accuracy} meters.`);
}

function error(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}

const getIPAddress = async () => {
  try {
    const response = await fetch("https://api.ipify.org/?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error(error);
    return "";
  }
};

function isHuman() {
  let _ishuman = getCookie("is_human");
  return _ishuman;
}

// force build
export async function createChannelTracking(channel, campaign, ad_id) {
  const url = new URL(`${API_BASE_URL}/user/create-channel-tracking`);
  let access_token = localStorage.getItem("access_token");
  let agent = navigator.userAgent;
  let referrer = document.referrer;
  let res_ip = await getIP();
  let ishuman = isHuman();

  if (res_ip.ip) {
    if (res_ip.ip.indexOf("40.94") > -1) {
      return;
    }
  }

  // if (ishuman != 'true'){
  //   return;
  // }

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      campaign,
      channel,
      ad_id,
      agent,
      referrer,
      client_ip: res_ip.ip,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export async function createUserSession() {
  const url = new URL(`${API_BASE_URL}/user/create-session`);
  let access_token = localStorage.getItem("access_token");
  let agent = navigator.userAgent;
  let referrer = document.referrer;
  let res_ip = await getIP();
  let ishuman = isHuman();

  if (res_ip.ip) {
    if (res_ip.ip.indexOf("40.94") > -1) {
      return { success: false };
    }
  }

  // if (ishuman != 'true'){
  //   console.log('nh');
  //   return {success: false};
  // }

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ agent, referrer, client_ip: res_ip.ip }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function createUserSessionPage(token, page_name = "") {
  const url = new URL(`${API_BASE_URL}/user/create-session-page`);
  let access_token = localStorage.getItem("access_token");
  let agent = navigator.userAgent;
  let res_ip = await getIP();
  let page_url = window.location.pathname;
  let ishuman = isHuman();

  if (res_ip.ip) {
    if (res_ip.ip.indexOf("40.94") > -1) {
      return;
    }
  }

  // if (ishuman != 'true'){
  //   return;
  // }

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      agent,
      client_ip: res_ip.ip,
      token,
      page_url,
      page_name,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getIP() {
  const url = new URL(`${API_BASE_URL}/user/api/ipaddress`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export function getIPSync() {
  const url = new URL(`${API_BASE_URL}/user/api/ipaddress`);
  let access_token = localStorage.getItem("access_token");

  const response = fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function channelTrackingExists(token) {
  const url = new URL(`${API_BASE_URL}/user/channel-tracking-exists`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ token }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addSellerRole() {
  const url = new URL(`${API_BASE_URL}/user/add-seller-role`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function addBuyerRole() {
  const url = new URL(`${API_BASE_URL}/user/add-buyer-role`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addEventAttendeeRole() {
  const url = new URL(`${API_BASE_URL}/user/add-event-attendee-role`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addEventHostRole() {
  const url = new URL(`${API_BASE_URL}/user/add-event-host-role`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getCharges() {
  const url = new URL(`${API_BASE_URL}/payment/charges`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function getActiveCharity() {
  const url = new URL(`${API_BASE_URL}/charity/get-active`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addCompanyEmail(company_id, email) {
  const url = new URL(`${API_BASE_URL}/company/company-email`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ company_id, email }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function validateWorkEmail(website, email) {
  const url = new URL(`${API_BASE_URL}/company/validate-work-email`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ website, email }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createWorkEmailValidation(website, email, company_id) {
  const url = new URL(`${API_BASE_URL}/company/create-email-validation`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ website, email, company_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function checkWorkEmailValidation(token) {
  const url = new URL(`${API_BASE_URL}/company/check-email-validation`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ token }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function deleteCompanyInvite(company_invite_id) {
  let data = { company_invite_id };
  const response = await createPOSTWithRetry("company/delete-invite", data);
  return response;


  // const url = new URL(`${API_BASE_URL}/company/delete-invite`);
  // let access_token = localStorage.getItem("access_token");

  // const response = await fetch(url, {
  //   method: "POST",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   body: JSON.stringify({ company_invite_id }),
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function reassignDemo(demo_id, registered_user_id) {
  const url = new URL(`${API_BASE_URL}/demo/reassign`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id, registered_user_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getSellerRoleTypes() {
  const url = new URL(`${API_BASE_URL}/user/seller-role-types`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function updateSellerRole(registered_user_id, role_type) {
  const url = new URL(`${API_BASE_URL}/user/update-seller-role`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ registered_user_id, role_type }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function removeSellerRole(seller_id) {
  let data = { seller_id };
  const response = await createPOSTWithRetry("user/remove-seller-role", data);
  return response;

  // const url = new URL(`${API_BASE_URL}/user/remove-seller-role`);
  // let access_token = localStorage.getItem("access_token");

  // const response = await fetch(url, {
  //   method: "POST",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   body: JSON.stringify({ seller_id }),
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function getSellerRole() {
  const url = new URL(`${API_BASE_URL}/user/seller-role`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function qualifierQuestionMoveUp(
  qualifier_question_id,
  previous_qualifier_question_id,
  index
) {
  const url = new URL(`${API_BASE_URL}/company/qualifier-question-up`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      qualifier_question_id,
      previous_qualifier_question_id,
      index,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function qualifierQuestionMoveDown(
  qualifier_question_id,
  next_qualifier_question_id,
  index
) {
  const url = new URL(`${API_BASE_URL}/company/qualifier-question-down`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      qualifier_question_id,
      next_qualifier_question_id,
      index,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function disconnectCalendar() {
  const url = new URL(`${API_BASE_URL}/user/deactivate-calendar`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function checkPromoCode(demo_id, promo_code) {
  const url = new URL(`${API_BASE_URL}/demo/check-promo`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id, promo_code }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addToWaitlist(company_id) {
  let data = { company_id };
  const response = await createPOSTWithRetry("company/add-to-waitlist", data);
  return response;

  // const url = new URL(`${API_BASE_URL}/company/add-to-waitlist`);
  // let access_token = localStorage.getItem("access_token");

  // const response = await fetch(url, {
  //   method: "POST",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   body: JSON.stringify({ company_id }),
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function getWaitlist() {
  const url = new URL(`${API_BASE_URL}/company/waitlist`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function offTheWaitlist() {
  const url = new URL(`${API_BASE_URL}/company/off-waitlist`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addRescheduleReason(demo_id, reason) {
  const url = new URL(`${API_BASE_URL}/demo/reschedule-reason`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id, reason, user_role: "Buyer" }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function openedLeadCard(demo_id) {
  const url = new URL(`${API_BASE_URL}/demo/opened`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addReferToken() {
  const url = new URL(`${API_BASE_URL}/user/refer-token`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getReferToken() {
  const url = new URL(`${API_BASE_URL}/user/refer-token`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addEmailCampaign(email, campaign_name) {
  const url = new URL(`${API_BASE_URL}/user/email-campaign`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ email, campaign_name }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addDemoMeetingLink(demo_id, meeting_link) {
  const url = new URL(`${API_BASE_URL}/demo/demo-meeting-link`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id, meeting_link }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addCompanyView(company_id, source = "") {
  const url = new URL(`${API_BASE_URL}/company/add-view`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ company_id, token: tok, source }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createDemoReminder(demo_id, reminder_date) {
  const url = new URL(`${API_BASE_URL}/demo/snooze`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id, reminder_date }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getUserWaitlist() {
  const url = new URL(`${API_BASE_URL}/user/waitlist`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function checkLinkedinAccessToken(code) {
  const url = new URL(`${API_BASE_URL}/linkedin/check-code`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ code }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getFeedItems(slug) {
  const url = new URL(`${API_BASE_URL}/feed/get-items`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ slug }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getFeedItemsBySource(source_type, source_id) {
  const url = new URL(`${API_BASE_URL}/feed/get-items-by-source`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ source_type, source_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getFeedItemComments(feed_item_id) {
  const url = new URL(`${API_BASE_URL}/feed/get-comments`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ feed_item_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getFeedItemCommentReplies(feed_item_comment_id) {
  const url = new URL(`${API_BASE_URL}/feed/get-comment-replies`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ feed_item_comment_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addFeedItem(
  slug,
  content_body,
  is_anon,
  source_type,
  source_id
) {
  const url = new URL(`${API_BASE_URL}/feed/add-item`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      slug,
      content_body,
      is_anon,
      source_type,
      source_id,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addFeedItemLike(feed_item_id) {
  const url = new URL(`${API_BASE_URL}/feed/toggle-like`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ feed_item_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addFeedItemCommentLike(feed_item_comment_id) {
  const url = new URL(`${API_BASE_URL}/feed/toggle-comment-like`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ feed_item_comment_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addFeedItemComment(
  feed_item_id,
  parent_id,
  comment_body,
  is_anon
) {
  const url = new URL(`${API_BASE_URL}/feed/add-comment`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ feed_item_id, parent_id, comment_body, is_anon }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getAddons() {
  const url = new URL(`${API_BASE_URL}/addon/get-addons`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function enableAddon(addon_id) {
  const url = new URL(`${API_BASE_URL}/addon/enable`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ addon_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function disableAddon(addon_id) {
  const url = new URL(`${API_BASE_URL}/addon/disable`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ addon_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getMeetingLinkStatus() {
  const response = await createGETWithRetry("addon/meeting-link-status");
  return response;

  // const url = new URL(`${API_BASE_URL}/addon/meeting-link-status`);
  // let access_token = localStorage.getItem("access_token");

  // const response = await fetch(url, {
  //   method: "POST",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function getAdditionalTeamStatus() {
  const url = new URL(`${API_BASE_URL}/addon/additional-team-status`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getDemoFromInvite(token) {
  const url = new URL(`${API_BASE_URL}/demo/get-demo-invite`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ token }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getDepartmentsForFeed(token) {
  const url = new URL(`${API_BASE_URL}/feed/departments`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function removeMeetingLink(demo_id) {
  const url = new URL(`${API_BASE_URL}/demo/remove-meeting-link`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function checkCaptcha(token) {
  const url = new URL(`${API_BASE_URL}/captcha/check-token`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ token }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function SaveDemoInviteForCompany(company_id, message, data) {
  const url = new URL(`${API_BASE_URL}/demo/demo-invite-company`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ company_id, message, data }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getDemoBuyerFeedback(demo_id) {
  const url = new URL(`${API_BASE_URL}/demo/buyer-feedback`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getBuyerFeedbackByReference(reference_id) {
  const url = new URL(`${API_BASE_URL}/demo/buyer-feedback-by-reference`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ reference_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getDemoQualifierAnswers(demo_id) {
  const url = new URL(`${API_BASE_URL}/demo/qualifier-answers`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createTestCharge() {
  const url = new URL(`${API_BASE_URL}/stripe/test-charge`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getFeedUnreplyCount() {
  const url = new URL(`${API_BASE_URL}/feed/unreply-count`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function deleteUserAccount() {
  const url = new URL(`${API_BASE_URL}/user/delete-user`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addUserEventTracking(event_name, source_id) {
  const url = new URL(`${API_BASE_URL}/user/add-event-track`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_name, source_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addUserTestimonial(rating, feedback) {
  const url = new URL(`${API_BASE_URL}/user/add-user-testimonial`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ rating, feedback }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createPackagePurchase(company_package_id) {
  const url = new URL(`${API_BASE_URL}/package/package-purchase`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ company_package_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getPackagePurchase(reference_id) {
  const url = new URL(`${API_BASE_URL}/package/get-package-purchase`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ reference_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createPackagePurchaseConfirmation(
  reference_id,
  number_of_demos
) {
  const url = new URL(`${API_BASE_URL}/package/package-purchase-confirmation`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ reference_id, number_of_demos }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getCompanyPackages() {
  const url = new URL(`${API_BASE_URL}/package/company-packages`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getCompanyPackagePurchases() {
  const url = new URL(`${API_BASE_URL}/package/get-package-purchases`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getPackageItemsRemainingCount() {
  const response = await createGETWithRetry("package/remaining-count");
  return response;
}

export async function getSiteNotification(name) {
  const url = new URL(`${API_BASE_URL}/user/site-notification`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ name }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function hasUnreadMessages() {
  const url = new URL(`${API_BASE_URL}/dashboard/has-unread-messages`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function updateEmail(email) {
  const url = new URL(`${API_BASE_URL}/user/update-email`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ email }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function hasAvailableApplications() {
  const response = await createGETWithRetry("user/has-available-applications");
  return response;

  // const url = new URL(`${API_BASE_URL}/user/has-available-applications`);
  // let access_token = localStorage.getItem("access_token");

  // const response = await fetch(url, {
  //   method: "GET",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function workEmailIsVerified() {
  const url = new URL(`${API_BASE_URL}/user/work-email-verified`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getAffiliates() {
  const url = new URL(`${API_BASE_URL}/affiliate/affiliates`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getAffiliatesByCategory(slug) {
  const url = new URL(`${API_BASE_URL}/affiliate/affiliates-by-category`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ slug }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addClick(affiliate_id) {
  const url = new URL(`${API_BASE_URL}/affiliate/add-click`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ affiliate_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getCompanyFromCategory(slug) {
  const url = new URL(`${API_BASE_URL}/company/category-slug`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ slug }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getCategoryFromSlug(slug) {
  const url = new URL(`${API_BASE_URL}/category/slug/${slug}`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getBuyerRecommendations() {
  const url = new URL(`${API_BASE_URL}/company/get-buyer-recommendations`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addBuyerRecommendationStatus(company_id, status) {
  const url = new URL(
    `${API_BASE_URL}/company/add-buyer-recommendation-status`
  );
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ company_id, status }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getSellerRecommendations() {
  const url = new URL(`${API_BASE_URL}/buyer/recommended-buyers`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getSellerRecommendationsForDashboard() {
    const response = await createGETWithRetry("buyer/recommended-buyers-for-dashboard");
    return response;
  
  // const url = new URL(`${API_BASE_URL}/buyer/recommended-buyers`);
  // let access_token = localStorage.getItem("access_token");
  // let tok = getCookie("session_token");

  // const response = await fetch(url, {
  //   method: "GET",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function getSellerRecommendationsBookmarked() {
  const url = new URL(`${API_BASE_URL}/buyer/recommended-buyers-bookmarked`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addSellerRecommendationStatus(buyer_id, status, source = '') {
  let data = { buyer_id, status, source };
  const response = await createPOSTWithRetry("buyer/add-seller-recommendation-status", data);
  return response;
}

export async function getBuyerWorkspace() {
  const url = new URL(`${API_BASE_URL}/dashboard/buyer-workspace`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getSellerWorkspace() {
  const url = new URL(`${API_BASE_URL}/dashboard/seller-workspace`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getBuyerDemosCompleted() {
  const url = new URL(`${API_BASE_URL}/demo/buyer-completed`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getBuyerDemosIncomplete() {
  const url = new URL(`${API_BASE_URL}/demo/buyer-incomplete`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getBuyerDemosUpcoming() {
  const url = new URL(`${API_BASE_URL}/demo/buyer-upcoming`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getBuyerDemosPending() {
    const response = await createGETWithRetry("demo/buyer-pending");
    return response;
  
  // const url = new URL(`${API_BASE_URL}/demo/buyer-pending`);
  // let access_token = localStorage.getItem("access_token");
  // let tok = getCookie("session_token");

  // const response = await fetch(url, {
  //   method: "GET",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function getRoles() {
  const url = new URL(`${API_BASE_URL}/user/roles`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function demoPackagePurchaseExists(demo_id) {
  const url = new URL(`${API_BASE_URL}/demo/package-purchase-exists`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getDemoCheckIn() {
  const url = new URL(`${API_BASE_URL}/demo/check-in`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addDemoCheckIn(demo_id, status, decline_reason_id) {
  const url = new URL(`${API_BASE_URL}/demo/add-check-in`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id, status, decline_reason_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addDemoNote(demo_id, note_text) {
  const url = new URL(`${API_BASE_URL}/demo/add-note`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ demo_id, note_text }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getUserActivity() {
  const response = await createGETWithRetry("user/user-activity");
  return response;

  // const url = new URL(`${API_BASE_URL}/user/user-activity`);
  // let access_token = localStorage.getItem("access_token");
  // let tok = getCookie("session_token");

  // const response = await fetch(url, {
  //   method: "GET",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function getEvents(page = 1) {
  const url = new URL(`${API_BASE_URL}/event/get-events-by-page`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ page }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getEventsRoundtable(page = 1) {
  const response = await createGET("event/get-events-roundtable");
  return response;

  // const url = new URL(`${API_BASE_URL}/event/get-events-roundtable`);
  // let access_token = localStorage.getItem("access_token");
  // let tok = getCookie("session_token");

  // const response = await fetch(url, {
  //   method: "GET",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function getEventsWebinars(page = 1) {
  const url = new URL(`${API_BASE_URL}/event/get-events-webinars`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getEvent(event_id) {
  const url = new URL(`${API_BASE_URL}/event/get-event`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getRegisteredEvents() {
  const url = new URL(`${API_BASE_URL}/event/get-registered-events`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getRegisteredWebinars() {
  const url = new URL(`${API_BASE_URL}/event/get-registered-webinars`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getEventsByQuery(query) {
  const url = new URL(`${API_BASE_URL}/event/get-events-by-query`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ query }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function eventRegistration(event_id) {
  const url = new URL(`${API_BASE_URL}/event/user-registration`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function saveContactForm(name, email, subject, contact_message) {
  const url = new URL(`${API_BASE_URL}/user/contact-form`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ name, email, subject, contact_message }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createEvent(
  name,
  description,
  start_date,
  end_date,
  website,
  status,
  fileName,
  capacity,
  timezone,
  enable_questions,
  is_webinar,
  webinar_url,
  short_description,
  requires_approval
) {
  const url = new URL(`${API_BASE_URL}/event/create`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      name,
      description,
      start_date,
      end_date,
      website,
      status,
      image_url: fileName,
      capacity,
      timezone,
      enable_questions,
      is_webinar,
      webinar_url,
      short_description,
      requires_approval
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function updateEvent(
  event_id,
  name,
  description,
  start_date,
  end_date,
  website,
  venue_instructions,
  address1,
  address2,
  city,
  state,
  zipcode,
  fileName,
  isFree,
  requiresApproval,
  status,
  enable_questions,
  capacity,
  timezone,
  is_webinar,
  webinar_url,
  meta_description,
  highlights,
  short_description
) {
  const url = new URL(`${API_BASE_URL}/event/update`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      event_id,
      name,
      description,
      start_date,
      end_date,
      website,
      venue_instructions,
      address1,
      address2,
      city,
      state,
      zipcode,
      image_url: fileName,
      is_free: isFree,
      requires_approval: requiresApproval,
      status,
      enable_questions,
      capacity,
      timezone,
      is_webinar,
      webinar_url,
      meta_description,
      highlights,
      short_description
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getHostedWebinars() {
  const url = new URL(`${API_BASE_URL}/event/hosted-webinars`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getHostedEvent(event_id) {
  const url = new URL(`${API_BASE_URL}/event/get-hosted-event`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createEventHostInvite(event_id, email, role) {
  const url = new URL(`${API_BASE_URL}/event/invite-host`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_id, email, role }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function cancelEventHostInvite(event_host_invite_id) {
  const url = new URL(`${API_BASE_URL}/event/cancel-invite`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_host_invite_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function deleteEvent(event_id) {
  const url = new URL(`${API_BASE_URL}/event/delete`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function updateEventHost(event_host_id, role) {
  const url = new URL(`${API_BASE_URL}/event/update-host`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_host_id, role }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function deleteEventHost(event_host_id) {
  const url = new URL(`${API_BASE_URL}/event/delete-host`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_host_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function approveEventGuest(event_id, saved_user_id) {
  const url = new URL(`${API_BASE_URL}/event/approve-guest`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_id, saved_user_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function updateEventUrl(event_id, url_slug) {
  const url = new URL(`${API_BASE_URL}/event/update-url`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_id, url_slug }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function checkHostInvite(token) {
  const url = new URL(`${API_BASE_URL}/event/check-invite`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ token }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addEventQuestion(event_id, question) {
  const url = new URL(`${API_BASE_URL}/event/add-question`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_id, question }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function removeEventQuestion(event_question_id) {
  const url = new URL(`${API_BASE_URL}/event/remove-question`);
  let access_token = localStorage.getItem("access_token");
  let tok = getCookie("session_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_question_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function updateEventQuestion(event_question_id, question) {
  const url = new URL(`${API_BASE_URL}/event/update-question`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_question_id, question }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());

  return response;
}

export async function eventQuestionMoveUp(
  event_question_id,
  previous_event_question_id,
  index
) {
  const url = new URL(`${API_BASE_URL}/event/question-move-up`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      event_question_id,
      previous_event_question_id,
      index,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function eventQuestionMoveDown(
  event_question_id,
  next_event_question_id,
  index
) {
  const url = new URL(`${API_BASE_URL}/event/question-move-down`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_question_id, next_event_question_id, index }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addEventAnswer(event_question_id, event_id, answer) {
  const url = new URL(`${API_BASE_URL}/event/add-answer`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_question_id, event_id, answer }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addEventAnswerInstant(event_question_id, event_id, answer, user_id) {
  let data = { event_id, event_question_id, answer, user_id };
  const response = await createPOSTWithRetry("event/add-answer-for-instant", data);
  return response;

  // const url = new URL(`${API_BASE_URL}/event/add-answer-for-instant`);
  // let access_token = localStorage.getItem("access_token");

  // const response = await fetch(url, {
  //   method: "POST",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   body: JSON.stringify({ event_question_id, event_id, answer }),
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function getEventAnswers(event_id, user_id) {
  const url = new URL(`${API_BASE_URL}/event/get-user-answers`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_id, user_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getEventIdFromSlug(slug) {
  const url = new URL(`${API_BASE_URL}/event/get-event-id-from-slug`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ slug }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getMessageUnreadCount() {
  const response = await createGETWithRetry("user/get-message-unread-count");
  return response;

  // const url = new URL(`${API_BASE_URL}/user/get-message-unread-count`);
  // let access_token = localStorage.getItem("access_token");

  // const response = await fetch(url, {
  //   method: "GET",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function createEventPost(
  event_id,
  subject,
  post_body,
  is_pinned,
  send_to,
  send_email
) {
  const url = new URL(`${API_BASE_URL}/event/add-post`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      event_id,
      subject,
      post_body,
      is_pinned,
      send_to,
      send_email,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function removeEventPost(event_post_id) {
  const url = new URL(`${API_BASE_URL}/event/remove-post`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_post_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function updateEventPost(event_post_id, post_body) {
  const url = new URL(`${API_BASE_URL}/event/update-post`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_post_id, post_body }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getEventPostsForHost(event_id) {
  const url = new URL(`${API_BASE_URL}/event/get-posts-for-host`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getEventPostsForPublic(event_id) {
  const url = new URL(`${API_BASE_URL}/event/get-posts-for-public`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function publishCompany() {
  const url = new URL(`${API_BASE_URL}/company/publish`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function adminCheck() {
  const url = new URL(`${API_BASE_URL}/user/admin-check`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getPageBreakdown(month, year) {
  const url = new URL(`${API_BASE_URL}/admin/page-breakdown`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ month, year }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function userIsOwner(company_id) {
  const url = new URL(`${API_BASE_URL}/company/is-owner`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ company_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getCompanyPricing() {
  const url = new URL(`${API_BASE_URL}/company/get-pricing`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function deleteCompanyPricing(company_pricing_id) {
  const url = new URL(`${API_BASE_URL}/company/delete-pricing`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ company_pricing_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function updateCompanyPricing(
  company_pricing_id,
  name,
  description,
  price,
  unit
) {
  const url = new URL(`${API_BASE_URL}/company/update-pricing`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      company_pricing_id,
      name,
      description,
      price,
      unit,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createCompanyPricing(name, description, price, unit) {
  const url = new URL(`${API_BASE_URL}/company/create-pricing`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ name, description, price, unit }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getUserCompanyFeatures() {
  const url = new URL(`${API_BASE_URL}/company/user-features`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function addCompanyFeature(feature_text) {
  const url = new URL(`${API_BASE_URL}/company/add-feature`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ feature_text }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function removeCompanyFeature(company_feature_id) {
  const url = new URL(`${API_BASE_URL}/company/remove-feature`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ company_feature_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function companyFeatureMoveUp(
  company_feature_id,
  previous_company_feature_id,
  index
) {
  const url = new URL(`${API_BASE_URL}/company/move-up-feature`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      company_feature_id,
      previous_company_feature_id,
      index,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function companyFeatureMoveDown(
  company_feature_id,
  next_company_feature_id,
  index
) {
  const url = new URL(`${API_BASE_URL}/company/move-down-feature`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({
      company_feature_id,
      next_company_feature_id,
      index,
    }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function indexCompany(company_id) {
  const url = new URL(`${API_BASE_URL}/admin/is-indexed`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ company_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function indexEvent(event_id) {
  const url = new URL(`${API_BASE_URL}/admin/event-is-indexed`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getCompaniesForSitemap() {
  const url = new URL(`${API_BASE_URL}/admin/companies-for-sitemap`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function eventIndexLog(event_id) {
  const url = new URL(`${API_BASE_URL}/admin/event-index-log`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function updateSIDToViewed(sid) {
  const url = new URL(`${API_BASE_URL}/company/update-sid-viewed`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ sid }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function testDemoRequestEmail() {
  const url = new URL(`${API_BASE_URL}/admin/test-demo-request-email`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function updateMediaUrl(event_id, media_url, embed_media = false) {
  const url = new URL(`${API_BASE_URL}/event/update-media-url`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ event_id, media_url, embed_media }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createUserEventInstant(data) {
  const url = new URL(`${API_BASE_URL}/user/create/event-instant`);
  const options = {
    method: "POST",
    headers,
    body: JSON.stringify({ data }),
  };

  const response = await fetch(url, options).then((response) =>
    response.json()
  );
  return response;
}

export async function adminUpdateCompany(id, data, message) {
  const url = new URL(`${API_BASE_URL}/admin/update-company`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ id, data, message }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function adminSearchUsers(query, id){
  const url = new URL(`${API_BASE_URL}/admin/search-users`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    body: JSON.stringify({query, id}),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getComingSoonCompanies(page = 1) {
  const url = new URL(`${API_BASE_URL}/company/coming-soon-companies/${page}`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function removeFromWaitlist(company_id) {
  const url = new URL(`${API_BASE_URL}/user/remove-from-waitlist`);
  let access_token = localStorage.getItem("access_token");

  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    body: JSON.stringify({ company_id }),
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function getHostedEvents() {
  const response = await createGETWithRetry("event/hosted-events");
  return response;
}

export async function GetBuyerAvailableRecommendations() {
  const response = await createGETWithRetry("company/available-recommendations");
  return response;
}

export async function GetCompanyQualifierQuestions(company_id) {
  const response = await createGET("company/get-company-questions");
  return response;
}

export async function getEventSpeakersForHost(event_id) {
  let data = { event_id };
  const response = await createPOSTWithRetry(
    "event/get-speakers-for-host",
    data
  );
  return response;
}

export async function uet(event_name, source_id) {
  let data = { event_name, source_id };
  const response = await createPOST("user/add-event-track", data);
  return response;
}

export async function addCompanyClick(company_id, source = "") {
  let data = { company_id, source };
  const response = await createPOST("company/add-click", data);
  return response;
}

export async function addEventSpeaker(event_id, speaker_name, speaker_bio, speaker_profile_picture) {
  let data = { event_id, speaker_name, speaker_bio, speaker_profile_picture };
  const response = await createPOSTWithRetry("event/add-speaker", data);
  return response;
}

export async function updateEventSpeaker(event_speaker_id, speaker_name, speaker_bio,
  speaker_profile_picture) {
  let data = {event_speaker_id, speaker_name, speaker_bio, speaker_profile_picture};
  const response = await createPOSTWithRetry("event/update-speaker", data);
  return response;
}

export async function deleteEventSpeaker(event_speaker_id) {
  let data = { event_speaker_id };
  const response = await createPOSTWithRetry("event/delete-speaker", data);
  return response;
}

export async function getPublicCategories() {
  const response = await createGET("category/all");
  return response;
}

export async function getCompaniesForCategoryBySlug(slug) {
  let data = { slug };
  const response = await createPOST("company/get-companies-for-category", data);
  return response;
}

export async function getCompaniesForCategoryById(category_id) {
  let data = { category_id };
  const response = await createPOST("company/get-companies-for-category-by-id", data);
  return response;
}

export async function updateDefaultSellerId(seller_id) {
  let data = { seller_id };
  const response = await createPOSTWithRetry("company/update-default-seller-id", data);
  return response;
}

export async function getDemoAvailableTimes(demo_id) {
  let data = { demo_id };
  const response = await createPOSTWithRetry("demo/get-available-times", data);
  return response;
}

export async function getDemoForLeadCard(demo_id) {
  let data = { demo_id };
  const response = await createPOSTWithRetry("demo/get-demo-for-lead", data);
  return response;
}

export async function getPaymentType() {
  const response = await createGETWithRetry("company/get-payment-type");
  return response;
}

export async function getSponsorList() {
  const response = await createGETWithRetry("event/sponsor-list");
  return response;
}

export async function updateEventSponsor(event_id, sponsor_id) {
  let data = { event_id, sponsor_id };
  const response = await createPOSTWithRetry("event/update-sponsor", data);
  return response;
}

export async function getFaqQuestions(type) {
  let data = { type };
  const response = await createPOST("content/get-faq", data);
  return response;
}

export async function getBlogHome() {
  const response = await createGET("content/blog-home");
  return response;
}

export async function getBlogHomeByCategory() {
  const response = await createGET("content/blog-home-by-category");
  return response;
}

export async function getFoundersSpotlight() {
  const response = await createGET("content/founders-spotlight");
  return response;
}

export async function getLatestReviews() {
  const response = await createGET("company/latest-reviews");
  return response;
}

export async function getBlogCategories() {
  const response = await createGET("content/blog-categories");
  return response;
}

export async function getPostFromSlug(slug) {
  let data = { slug };
  const response = await createPOST("content/get-blog-post-from-slug", data);
  return response;
}

export async function getBlogAll() {
  const response = await createGET("content/blog-all");
  return response;
}

export async function getRelatedEvents(event_id) {
  let data = { event_id };
  const response = await createPOST("event/get-related", data);
  return response;
}

export async function updateMailOutbox(token) {
  let data = { token };
  const response = await createPOST("user/update-mail-outbox", data);
  return response;
}

export async function updateEventThumbnail(event_id, image_url) {
  let data = { image_url, event_id };
  const response = await createPOSTWithRetry("event/update-thumbnail", data);
  return response;
}

export async function createPointTransaction(event_id, image_url) {
  let data = { image_url, event_id };
  const response = await createPOSTWithRetry("event/update-thumbnail", data);
  return response;
}

export async function createPointActivity(point_activity_id) {
  let data = { point_activity_id };
  const response = await createPOSTWithRetry("point/create-user-activity", data);
  return response;
}

export async function createUserPointReward(point_reward_id, payout_destination, point_redeem_amount_id) {
  let data = { point_reward_id, payout_destination, point_redeem_amount_id };
  const response = await createPOSTWithRetry("point/create-user-reward", data);
  return response;
}

export async function createUserSellerInvite(_data) {
  let data = _data;
  const response = await createPOST("user/create/seller-invite", data);
  return response;
}

export async function verifyStl(token) {
  let data = {token};
  const response = await createPOST("user/verify-single-token-login", data);
  return response;
}

export async function getAvailableRewards() {
  const response = await createGET("point/available-rewards");
  return response;
}

export async function getPointRedeemAmounts() {
  const response = await createGETWithRetry("point/redeem-amounts");
  return response;
}

export async function getUserAvailablePoints() {
  const response = await createGETWithRetry("point/available-balance");
  return response;
}

export async function getUserPendingPoints() {
  const response = await createGETWithRetry("point/pending-balance");
  return response;
}

export async function getUserPointHistory() {
  const response = await createGETWithRetry("point/activity-history");
  return response;
}

export async function getUpcomingEvents() {
  const response = await createGET("event/upcoming-events");
  return response;
}

export async function createArticleView(page_url) {
  let data = {page_url};
  const response = await createPOST("content/create-article-view", data);
  return response;
}

export async function getFeaturesMap() {
  const response = await createGETWithRetry("company/features-map");
  return response;
}

export async function updateFeatureMap(feature_id, selected) {
  let data = { feature_id, selected };
  const response = await createPOSTWithRetry("company/update-feature-map", data);
  return response;
}

export async function getFeatureList() {
  const response = await createGET("company/feature-list");
  return response;
}

export async function searchAvailableProducts(query = '') {
  let data = {query};
  const response = await createPOST("company/search-products", data);
  return response;
}

export async function searchAvailableProductsWithFilters(query, industries, features) {
  let data = {query, industries, features};
  const response = await createPOST("company/search-products-with-filters", data);
  return response;
}

export async function saveLeadFormSubmit(name, job_title, contact_email, contact_phone, type, request_body, company_id, time_to_call, email_preference, phone_preference, company_name) {
  let data = { name, job_title, contact_email, contact_phone, type, request_body, company_id, time_to_call, email_preference, phone_preference, company_name };
  const response = await createPOSTWithRetry("user/lead-form-submission", data);
  return response;
}

export async function getLeadFormSubmissions() {
  const response = await createGETWithRetry("user/lead-form-submissions");
  return response;
}

export async function getLeadCardsApproved() {
  const response = await createGETWithRetry("user/lead-cards-approved");
  return response;
}

export async function getLeadCardsPending() {
  const response = await createGETWithRetry("user/lead-cards-pending");
  return response;
}

export async function getLeadCardApproved(reference_id) {
  let data = { reference_id};
  const response = await createPOSTWithRetry("user/lead-card-approved", data);
  return response;
}

export async function hasPendingLeads() {
  const response = await createGETWithRetry("user/has-pending-leads");
  return response;
}

export async function getLeadCardNotes(reference_id) {
  let data = { reference_id};
  const response = await createPOSTWithRetry("user/lead-card-notes", data);
  return response;
}

export async function saveLeadCardNote(reference_id, note_text) {
  let data = { reference_id, note_text};
  const response = await createPOSTWithRetry("user/save-lead-card-note", data);
  return response;
}

export async function leadCardPurchase(reference_id) {
  let data = { reference_id};
  const response = await createPOSTWithRetry("user/lead-card-purchase", data);
  return response;
}

export async function purchaseLeadCard(reference_id) {
  let data = { reference_id};
  const response = await createPOSTWithRetry("user/purchase-lead-card", data);
  return response;
}

export async function getJourneySteps(journey_id) {
  let data = { journey_id };
  const response = await createPOSTWithRetry("journey/get-journey-steps", data);
  return response;
}

export async function getOnboardingStatus() {
  const response = await createGETWithRetry("user/onboarding-status");
  return response;
}

export async function getPreferredName() {
  const response = await createGETWithRetry("user/preferred-name");
  return response;
}

export async function createInterview(journey_step_id, call_id) {
  let data = { journey_step_id, call_id };
  const response = await createPOSTWithRetry("journey/create-interview", data);
  return response;
}

export async function getCallReport(session_id) {
  let data = { session_id };
  const response = await createPOSTWithRetry("vapi/get-call-report", data);
  return response;
}

export async function interviewCompleted(session_id) {
  let data = { session_id };
  const response = await createPOSTWithRetry("journey/interview-completed", data);
  return response;
}

export async function getCompletedInterviews(journey_id) {
  let data = { journey_id };
  const response = await createPOSTWithRetry("journey/completed-interviews", data);
  return response;
}

export async function getContentStudioItems(call_id, output_type_id) {
  let data = { call_id, output_type_id };
  const response = await createPOSTWithRetry("journey/get-studio-items", data);
  return response;
}

export async function getCompleteInterview(call_id) {
  let data = { call_id };
  const response = await createPOSTWithRetry("journey/get-complete-interview", data);
  return response;
}

export async function generateContent(call_id) {
  let data = { call_id };
  const response = await createPOSTWithRetry("journey/generate-content", data);
  return response;
}

export async function generateAnthropicContent(call_id, modify_prompt, output_type_id) {
  let data = { call_id, modify_prompt, output_type_id };
  const response = await createPOSTWithRetry("journey/generate-anthropic-content", data);
  return response;
}

export async function generateAnthropicRevision(journey_interview_content_id, query, content) {
  let data = { journey_interview_content_id, query, content };
  const response = await createPOSTWithRetry("journey/generate-anthropic-revision", data);
  return response;
}

export async function testGenMedia() {
  const response = await createGET("vapi/generate-media");
  return response;
}

export async function addUserPreference(name) {
  let data = { name };
  const response = await createPOSTWithRetry("journey/add-user-preference", data);
  return response;
}

export async function removeUserPreference(name) {
  let data = { name };
  const response = await createPOSTWithRetry("journey/remove-user-preference", data);
  return response;
}

export async function checkInviteToken(token) {
  let data = { token };
  const response = await createPOST("user/check-invite-token", data);
  return response;
}

export async function getInviteCode() {
  const response = await createGETWithRetry("user/invite-code");
  return response;
}

export async function earlyAccessRequest(email, name) {
  let data = { email, name };
  const response = await createPOSTWithRetry("user/early-access-request", data);
  return response;
}

export async function getJourneys() {
  const response = await createGETWithRetry("journey/journeys");
  return response;
}

export async function getJourney(journey_id) {
  let data = { journey_id };
  const response = await createPOSTWithRetry("journey/get-journey", data);
  return response;
}

export async function getAllContent() {
  const response = await createGETWithRetry("journey/get-all-content");
  return response;
}

export async function createSubscription(plan_name) {
  let data = { plan_name };
  const response = await createPOSTWithRetry("stripe/create-subscription", data);
  return response;
}

export async function cancelSubscription() {
  let data = { };
  const response = await createPOSTWithRetry("stripe/cancel-subscription", data);
  return response;
}

export async function checkSubscriptionStatus() {
  const response = await createGETWithRetry("stripe/subscription-status");
  return response;
}

export async function setDefaultMethod(id) {
  let data = { id };
  const response = await createPOSTWithRetry("stripe/set-default-method", data);
  return response;
}

export async function updateLikeStatus(journey_interview_content_id, is_liked) {
  let data = { journey_interview_content_id, is_liked };
  const response = await createPOSTWithRetry("journey/update-like-status", data);
  return response;
}

export async function deleteContent(journey_interview_content_id) {
  let data = { journey_interview_content_id };
  const response = await createPOSTWithRetry("journey/delete-content", data);
  return response;
}

export async function testPrompt() {
  const response = await createGET("anthropic/test-prompt");
  return response;
}

export async function addCallRating(journey_step_interview_id, rating) {
  let data = { journey_step_interview_id, rating };
  const response = await createPOSTWithRetry("journey/interview-call-rating", data);
  return response;
}

export async function getInterviewModifiers(call_id) {
  let data = { call_id };
  const response = await createPOSTWithRetry("journey/get-interview-modifiers", data);
  return response;
}

export async function addInterviewModifier(call_id, prompt) {
  let data = { call_id, prompt };
  const response = await createPOSTWithRetry("journey/add-interview-modifier", data);
  return response;
}

export async function removeInterviewModifier(journey_step_interview_modifier_id) {
  let data = { journey_step_interview_modifier_id };
  const response = await createPOSTWithRetry("journey/remove-interview-modifier", data);
  return response;
}

export async function getJourneysWithCompleted() {
  const response = await createGETWithRetry("journey/get-journeys-with-completed");
  return response;
}

export async function getOutputTypesForInterview(call_id) {
  let data = { call_id };
  const response = await createPOSTWithRetry("journey/get-output-types-for-interview", data);
  return response;
}

export async function getOutputTypeById(output_type_id) {
  let data = { output_type_id };
  const response = await createPOSTWithRetry("journey/get-output-type", data);
  return response;
}
export async function getInterviewOutputTypes(call_id) {
  let data = { call_id };
  const response = await createPOSTWithRetry("journey/interview-output-types", data);
  return response;
}

export async function getUserOutputTypes() {
  const response = await createGETWithRetry("journey/user-output-types");
  return response;
}

export async function getAvailableOutputTypes() {
  const response = await createGETWithRetry("journey/available-output-types");
  return response;
}

export async function getProOutputTypes() {
  const response = await createGETWithRetry("journey/pro-output-types");
  return response;
}

export async function getAllOutputTypes() {
  const response = await createGETWithRetry("journey/all-output-types");
  return response;
}

export async function getInterviewsForOutputType(output_type_id) {
  let data = { output_type_id };
  const response = await createPOSTWithRetry("journey/interviews-for-output-type", data);
  return response;
}

export async function getContentByOutputType(output_type_id) {
  let data = { output_type_id };
  const response = await createPOSTWithRetry("journey/content-by-output-type", data);
  return response;
}

export async function getAllJourneySteps() {
  const response = await createGETWithRetry("journey/all-journey-steps");
  return response;
}

export async function getAllJourneyStepsByOutputType(output_type_id) {
  let data = { output_type_id };
  const response = await createPOSTWithRetry("journey/all-journey-steps-by-output-type", data);
  return response;
}

export async function getAllJourneyStepsNotInOutputType(output_type_id) {
  let data = { output_type_id };
  const response = await createPOSTWithRetry("journey/all-journey-steps-not-in-output-type", data);
  return response;
}

export async function getContentItem(journey_interview_content_id) {
  let data = { journey_interview_content_id };
  const response = await createPOSTWithRetry("journey/content-item", data);
  return response;
}

export async function addContentQuery(journey_interview_content_id, query) {
  let data = { journey_interview_content_id, query };
  const response = await createPOSTWithRetry("journey/add-content-query", data);
  return response;
}

export async function getUserSubscriptions() {
  const response = await createGETWithRetry("stripe/user-subscriptions");
  return response;
}

export async function getUserUsage() {
  const response = await createGETWithRetry("journey/user-usage");
  return response;
}

export async function deleteContentVersion(journey_interview_content_version_id) {
  let data = { journey_interview_content_version_id };
  const response = await createPOSTWithRetry("journey/delete-content-version", data);
  return response;
}

export async function deleteContentItem(journey_interview_content_id) {
  let data = { journey_interview_content_id };
  const response = await createPOSTWithRetry("journey/delete-content-item", data);
  return response;
}

export async function getUserTranscript(call_id) {
  let data = { call_id };
  const response = await createPOSTWithRetry("journey/get-user-transcript", data);
  return response;
}

export async function deleteInterview(call_id) {
  let data = { call_id };
  const response = await createPOSTWithRetry("journey/delete-interview", data);
  return response;
}

export async function createUserTranscript(name, transcript) {
  let data = { name, transcript };
  const response = await createPOSTWithRetry("transcript/create-user-transcript", data);
  return response;
}

export async function getUserTranscripts() {
  const response = await createGETWithRetry("transcript/user-transcripts");
  return response;
}

export async function getCustomUserTranscript(reference_id) {
  let data = { reference_id };
  const response = await createPOSTWithRetry("transcript/user-transcript", data);
  return response;
}

export async function getTranscriptContentItems(reference_id) {
  let data = { reference_id };
  const response = await createPOSTWithRetry("transcript/get-content-items", data);
  return response;
}

export async function deleteUserTranscript(reference_id) {
  let data = { reference_id };
  const response = await createPOSTWithRetry("transcript/delete-user-transcript", data);
  return response;
}

export async function generateAnthropicContentForTranscript(reference_id, output_type_id) {
  let data = { reference_id, output_type_id };
  const response = await createPOSTWithRetry("transcript/generate-anthropic-content", data);
  return response;
}

export async function updateUserTranscript(reference_id, name, transcript) {
  let data = { reference_id, name, transcript };
  const response = await createPOSTWithRetry("transcript/update-user-transcript", data);
  return response;
}

export async function verifyLtd(code) {
  let data = { code };
  const response = await createPOSTWithRetry("user/verify-ltd", data);
  return response;
}

export async function getPurchasedLicense() {
  const response = await createGETWithRetry("user/purchased-license");
  return response;
}

export async function purchaseLicence(plan_name) {
  let data = { plan_name };
  const response = await createPOSTWithRetry("stripe/purchase-license", data);
  return response;
}

export async function purchaseInterviewLink(reference_id) {
  let data = { reference_id };
  const response = await createPOSTWithRetry("stripe/purchase-interview-link", data);
  return response;
}

export async function createCheckoutSession(plan_name) {
  let data = { plan_name };
  const response = await createPOSTWithRetry("stripe/create-checkout-session", data);
  return response;
}

export async function createLicensePurchase(session_id) {
  let data = { session_id };
  const response = await createPOSTWithRetry("stripe/create-license-purchase", data);
  return response;
}

export async function createInterviewLinkAgent(name, prompt) {
  let data = { name, prompt };
  const response = await createPOSTWithRetry("elevenlabs/create-agent", data);
  return response;
}

export async function getAgentFromReferenceId(reference_id) {
  let data = { reference_id };
  const response = await createPOSTWithRetry("elevenlabs/get-agent-from-reference", data);
  return response;
}

export async function addInterviewLinkQuestion(reference_id, question_text) {
  let data = { reference_id, question_text };
  const response = await createPOSTWithRetry("elevenlabs/add-interview-link-question", data);
  return response;
}

export async function updateInterviewLinkQuestion(interview_link_question_id, reference_id, payload) {
  let data = { interview_link_question_id, reference_id, data: payload };
  const response = await createPOSTWithRetry("elevenlabs/update-interview-link-question", data);
  return response;
}

export async function getInterviewLinkInvites(reference_id) {
  let data = { reference_id };
  const response = await createPOSTWithRetry("elevenlabs/get-interview-link-invites", data);
  return response;
}

export async function addInterviewLinkInvite(reference_id, name, email, phone_number) {
  let data = { reference_id, name, email, phone_number };
  const response = await createPOSTWithRetry("elevenlabs/add-interview-link-invite", data);
  return response;
}

export async function updateInterviewLinkInvite(interview_link_invite_id, payload) {
  let data = { interview_link_invite_id, data: payload };
  const response = await createPOSTWithRetry("elevenlabs/update-interview-link-invite", data);
  return response;
}

export async function getInterviewLinks() {
  const response = await createGETWithRetry("elevenlabs/interview-links");
  return response;
}

export async function getInterviewLinkInvite(token) {
  let data = { token };
  const response = await createPOSTWithRetry("elevenlabs/get-interview-link-invite", data);
  return response;
}

export async function sendInterviewLinkInvites(reference_id, coupon_code) {
  let data = { reference_id, coupon_code };
  const response = await createPOSTWithRetry("elevenlabs/send-interview-link-invites", data);
  return response;
}

export async function saveConversationId(session_token, conversation_id) {
  let data = { session_token, conversation_id };
  const response = await createPOSTWithRetry("elevenlabs/save-conversation-id", data);
  return response;
}

export async function getConversationDetails(session_token) {
  let data = { session_token };
  const response = await createPOSTWithRetry("elevenlabs/get-conversation", data);
  return response;
}

export async function updateInterviewLink(interview_link_id, payload) {
  let data = { interview_link_id, data: payload };
  const response = await createPOSTWithRetry("elevenlabs/update-interview-link", data);
  return response;
}

export async function getInterviewLinkPurchases() {
  const response = await createGETWithRetry("elevenlabs/interview-link-purchases");
  return response;
}

export async function getAvailableFreeCredits() {
  const response = await createGETWithRetry("elevenlabs/available-free-credits");
  return response;
}