
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const ParentingCircle = () => {
  return (
    <UseCaseTemplate 
      title="Parenting Circle Member Intro"
      prompt="Create 5 icebreaker questions for new members joining a parenting support circle."
      questions={[
        "Tell us a little about yourself and your family.",
        "What's one parenting challenge you're currently navigating that you might like support with?",
        "What's a parenting win you've experienced recently that you're proud of?",
        "What do you hope to gain from being part of this parenting circle?",
        "Share a parenting tip or resource that has been helpful for you that others might benefit from."
      ]}
      icon="👩‍👧"
      description="Icebreaker intros with a few thoughtful questions. Voice responses help create personal connections and identify common interests or challenges among group members."
      isPersonal={true}
    />
  );
};

export default ParentingCircle;
