
import React, { useState } from "react";
import Navbar from "../components/Navbar.tsx";
import Footer from "../components/Footer.tsx";
import { Button } from "../components/ui/button.tsx";
import { useNavigate } from "react-router-dom";
import {
  Mic,
  MessageSquare,
  FileText,
  Share2,
  ChevronDown,
  ArrowRight,
  RefreshCw
} from "lucide-react";
import ScrollToTop from "../components/layout/ScrollToTop.js";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../components/ui/tabs.tsx";

const VoiceInterviews = () => {
  const [showAllTopics, setShowAllTopics] = useState(false);
  const navigate = useNavigate();

  const interviewTopics = [
    {
      category: "Business & Professional",
      topics: [
        "Leadership Journey",
        "Company Culture",
        "Industry Trends",
        "Customer Experience",
        "Innovation Process",
        "Team Management",
        "Work-Life Balance",
        "Professional Development",
        "Business Strategy",
        "Remote Working"
      ]
    },
    {
      category: "Personal & Lifestyle",
      topics: [
        "Travel Experiences",
        "Fitness Journey",
        "Parenting Tips",
        "Personal Growth",
        "Hobby Passion",
        "Life Lessons",
        "Health & Wellness",
        "Financial Wisdom",
        "Relationship Advice",
        "Culinary Adventures"
      ]
    },
    {
      category: "Education & Knowledge",
      topics: [
        "Learning Techniques",
        "Educational Philosophy",
        "Subject Matter Expertise",
        "Teaching Experience",
        "Research Insights",
        "Academic Journey",
        "Student Experiences",
        "Educational Technology",
        "Continuing Education",
        "Knowledge Sharing"
      ]
    },
  ];

  const contentTypes = [
    {
      title: "Blog Posts",
      icon: <FileText className="h-6 w-6 text-brand-purple" />,
      description: "Long-form articles from 500-2000 words with detailed insights",
      examples: ["How-to guides", "Thought leadership", "Personal experiences"]
    },
    {
      title: "Social Media Content",
      icon: <Share2 className="h-6 w-6 text-emerald-500" />,
      description: "Engaging posts optimized for different platforms",
      examples: ["LinkedIn updates", "Twitter threads", "Instagram captions"]
    },
    {
      title: "Newsletters",
      icon: <MessageSquare className="h-6 w-6 text-amber-500" />,
      description: "Email-ready content that nurtures your audience",
      examples: ["Weekly roundups", "Featured interviews", "Industry insights"]
    },
  ];

  return (
    <div className="min-h-screen bg-white overflow-x-hidden">
      <ScrollToTop />
      {/* <Navbar /> */}

      <section className="pt-28 pb-16 md:pt-32 md:pb-24 px-4 bg-gradient-to-b from-emerald-50 to-white">
        <div className="container mx-auto max-w-7xl">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-flex items-center bg-emerald-100/90 backdrop-blur-sm px-4 py-2 rounded-full shadow-sm mb-6">
                <Mic className="h-4 w-4 text-emerald-600 mr-2" />
                <span className="font-medium text-sm text-emerald-600">Voice-to-Content</span>
              </div>

              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold leading-tight mb-6">
                Create Amazing Content From <span className="text-transparent bg-clip-text bg-gradient-to-r from-emerald-500 to-brand-blue">Voice Interviews</span>
              </h1>

              <p className="text-lg text-gray-600 mb-8 max-w-xl">
                Choose from over 100+ interview topics, answer simple questions with your voice, and get professionally written content in seconds.
              </p>

              <div className="flex flex-col sm:flex-row gap-4 mb-6">
                <Button onClick={() => navigate('/join-now')} className="bg-gradient-to-r from-emerald-500 to-emerald-700 text-white px-8 py-6 text-lg rounded-xl">
                  Start Recording Now
                  <Mic className="ml-2 h-5 w-5" />
                </Button>
                {/* <Button variant="outline" className="px-8 py-6 text-lg rounded-xl">
                  Upload Existing Recording
                </Button> */}
              </div>

              <div className="py-3 px-4 bg-gray-50 rounded-lg border border-gray-200 mb-4">
                <p className="text-sm text-gray-600 flex items-start">
                  <span className="text-emerald-500 font-bold mr-2 mt-0.5">💡</span>
                  <span>
                    <strong>Pro Tip:</strong> The average 5-minute voice interview can generate 
                    up to 10 different pieces of content across multiple formats.
                  </span>
                </p>
              </div>
            </div>

            <div className="relative">
              <div className="absolute inset-0 bg-gradient-radial from-emerald-200/30 to-transparent rounded-full blur-3xl -z-10"></div>
              
              <div className="bg-white rounded-xl shadow-xl p-6 border border-gray-100">
                <div className="flex items-center justify-between mb-5">
                  <h3 className="font-semibold text-lg flex items-center">
                    <Mic className="h-5 w-5 text-emerald-500 mr-2" />
                    Voice Interview Session
                  </h3>
                  <Button size="sm" className="bg-emerald-500 hover:bg-emerald-600">
                    New Topic
                  </Button>
                </div>

                <div className="space-y-5 mb-6">
                  <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                    <p className="font-medium mb-1">Current Topic:</p>
                    <p className="text-gray-800">Sustainable Business Practices</p>
                  </div>

                  <div className="space-y-4">
                    <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
                      <div className="flex items-start">
                        <div className="bg-emerald-100 p-1.5 rounded-full">
                          <span className="text-sm font-bold text-emerald-600">1</span>
                        </div>
                        <p className="ml-3 text-gray-800">
                          How has your company implemented sustainability initiatives?
                        </p>
                      </div>
                      <div className="mt-3 flex items-center">
                        <Button size="sm" className="bg-red-500 hover:bg-red-600 flex items-center">
                          <Mic className="h-4 w-4 mr-2" />
                          Record Answer
                        </Button>
                        <span className="text-xs text-gray-500 ml-3">Suggested: 30-60 seconds</span>
                      </div>
                    </div>

                    <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
                      <div className="flex items-start">
                        <div className="bg-emerald-100 p-1.5 rounded-full">
                          <span className="text-sm font-bold text-emerald-600">2</span>
                        </div>
                        <p className="ml-3 text-gray-800">
                          What measurable impacts have you seen from these initiatives?
                        </p>
                      </div>
                      <div className="mt-3 flex items-center">
                        <Button size="sm" className="bg-red-500 hover:bg-red-600 flex items-center">
                          <Mic className="h-4 w-4 mr-2" />
                          Record Answer
                        </Button>
                        <span className="text-xs text-gray-500 ml-3">Suggested: 30-60 seconds</span>
                      </div>
                    </div>

                    <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
                      <div className="flex items-start">
                        <div className="bg-emerald-100 p-1.5 rounded-full">
                          <span className="text-sm font-bold text-emerald-600">3</span>
                        </div>
                        <p className="ml-3 text-gray-800">
                          What challenges did you face during implementation?
                        </p>
                      </div>
                      <div className="mt-3 flex items-center">
                        <Button size="sm" className="bg-red-500 hover:bg-red-600 flex items-center">
                          <Mic className="h-4 w-4 mr-2" />
                          Record Answer
                        </Button>
                        <span className="text-xs text-gray-500 ml-3">Suggested: 30-60 seconds</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-6 text-center">
                  <Button className="w-full bg-emerald-500 hover:bg-emerald-600">
                    Generate Content
                    <RefreshCw className="ml-2 h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 px-4">
        <div className="container mx-auto max-w-7xl">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              Choose From 100+ Interview Topics
            </h2>
            <p className="text-gray-600 max-w-3xl mx-auto">
              Pick a pre-built interview script or customize your own. Our AI will generate 
              thoughtful questions tailored to your specific topic.
            </p>
          </div>

          <Tabs defaultValue="business">
            <TabsList className="grid w-full max-w-lg mx-auto md:grid-cols-3 mb-12">
              <TabsTrigger value="business">Business</TabsTrigger>
              <TabsTrigger value="personal">Personal</TabsTrigger>
              <TabsTrigger value="education">Education</TabsTrigger>
            </TabsList>

            {interviewTopics.map((category, idx) => (
              <TabsContent key={idx} value={category.category.split(" ")[0].toLowerCase()}>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                  {category.topics.slice(0, showAllTopics ? category.topics.length : 5).map((topic, index) => (
                    <div 
                      key={index}
                      className="bg-white rounded-lg p-4 border border-gray-200 hover:border-emerald-400 hover:shadow-md transition-all cursor-pointer flex items-center justify-center text-center h-24"
                    >
                      <p className="font-medium">{topic}</p>
                    </div>
                  ))}

                  {!showAllTopics && (
                    <div 
                      onClick={() => setShowAllTopics(true)}
                      className="bg-white rounded-lg p-4 border border-dashed border-emerald-400 hover:shadow-md transition-all cursor-pointer flex flex-col items-center justify-center text-center h-24"
                    >
                      <ChevronDown className="h-5 w-5 text-emerald-500 mb-2" />
                      <p className="font-medium text-emerald-500">Show More</p>
                    </div>
                  )}
                </div>
              </TabsContent>
            ))}
          </Tabs>
        </div>
      </section>

      <section className="py-16 px-4 bg-gray-50">
        <div className="container mx-auto max-w-7xl">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              Multiple Content Formats
            </h2>
            <p className="text-gray-600 max-w-3xl mx-auto">
              From one voice interview, generate multiple types of content to use across your marketing channels.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {contentTypes.map((content, index) => (
              <div key={index} className="bg-white rounded-xl p-8 shadow-sm border border-gray-100">
                <div className="flex items-center mb-4">
                  <div className={`p-3 rounded-lg ${
                    index === 0 ? "bg-brand-purple/10" : 
                    index === 1 ? "bg-emerald-100" : "bg-amber-100"
                  }`}>
                    {content.icon}
                  </div>
                  <h3 className="text-xl font-semibold ml-3">{content.title}</h3>
                </div>

                <p className="text-gray-600 mb-5">{content.description}</p>

                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="font-medium mb-2">Examples:</p>
                  <ul className="space-y-2">
                    {content.examples.map((example, idx) => (
                      <li key={idx} className="flex items-center">
                        <div className="w-1.5 h-1.5 bg-gray-400 rounded-full mr-2"></div>
                        <span>{example}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="mt-6">
                  <Button variant="outline" className="w-full">
                    See Samples
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-16 px-4">
        <div className="container mx-auto max-w-7xl">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              Start Creating Content Today
            </h2>
            <p className="text-gray-600 max-w-3xl mx-auto">
              No more writer's block. Just speak naturally and let our AI transform your words into professional content.
            </p>
          </div>

          <div className="flex flex-col md:flex-row items-center justify-center gap-4">
            <Button className="bg-gradient-to-r from-emerald-500 to-emerald-700 text-white px-8 py-6 text-lg rounded-xl">
              Start Recording Now
              <Mic className="ml-2 h-5 w-5" />
            </Button>
            <Button variant="outline" className="px-8 py-6 text-lg rounded-xl">
              Explore Topic Library
            </Button>
          </div>
        </div>
      </section>

      {/* <Footer /> */}
    </div>
  );
};

export default VoiceInterviews;
