// useElevenLabsWidget.js
import { useEffect } from "react";

export const useElevenLabsWidget = () => {
  useEffect(() => {
    const scriptId = "elevenlabs-convai-widget";

    // Prevent duplicate script load
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://elevenlabs.io/convai-widget/index.js";
      script.async = true;
      script.type = "text/javascript";
      document.body.appendChild(script);
    }

    // Optional cleanup — leave it out if you want it globally available
    return () => {
      // Don’t remove the script if you want to persist the widget across routes
    };
  }, []);
};
