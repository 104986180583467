
import React from "react";
import { Button } from "../components/ui/button.tsx";
import { ArrowRight } from "lucide-react";

interface UseCaseCardProps {
  title: string;
  description: string;
  icon: React.ReactNode | string;
  link?: string;
  isPersonal?: boolean;
}

const UseCaseCard = ({ title, description, icon, link = "#", isPersonal = false }: UseCaseCardProps) => {
  return (
    <div className={`bg-white rounded-xl p-6 shadow-sm border ${isPersonal ? 'border-pink-100' : 'border-gray-100'} hover:shadow-md transition-all group relative overflow-hidden`}>
      <div className={`absolute inset-0 ${isPersonal ? 'bg-gradient-to-r from-pink-500 to-purple-500' : 'bg-gradient-to-r from-brand-purple to-brand-blue'} opacity-0 group-hover:opacity-5 transition-opacity duration-300`}></div>
      <div className="mb-4 text-3xl">{icon}</div>
      <div className={`text-xs font-medium mb-2 inline-block px-2 py-1 rounded-full ${isPersonal ? 'bg-pink-100 text-pink-600' : 'bg-blue-100 text-blue-600'}`}>
        {isPersonal ? 'Personal' : 'Professional'}
      </div>
      <h3 className="text-xl font-semibold mb-2 group-hover:text-brand-purple transition-colors">{title}</h3>
      <p className="text-gray-600 mb-4">{description}</p>
      <a href={link}>
        <Button variant="ghost" className="p-0 h-auto text-brand-purple hover:text-brand-purple/80 hover:bg-transparent group-hover:translate-x-1 transition-transform">
          Learn more <ArrowRight className="ml-2 h-4 w-4" />
        </Button>
      </a>
    </div>
  );
};

export default UseCaseCard;
