import { useRef, useState } from 'react';
import { Conversation } from '@11labs/client';
import { saveConversationId } from '../../../utils/api';

function VoiceAgentSession({ agentId, session_token }) {
  const conversationRef = useRef(null);
  const [status, setStatus] = useState('idle');
  const [conversationId, setConversationId] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);

  const startConversation = async () => {
    try {
      setStatus('requesting mic');

      // Request mic permission
      await navigator.mediaDevices.getUserMedia({ audio: true });

      setStatus('connecting');

      const conversation = await Conversation.startSession({
        agentId,
        onConnect: () => {
          console.log('✅ Connected');
          setStatus('connected');
        },
        onDisconnect: async () => {
          console.log('❌ Disconnected');

          const id = conversationRef.current.getId();

          let res = await saveConversationId(session_token, id);
          console.log('Ending conversation with ID:', id);
          setStatus('disconnected');
          setIsCompleted(true);
        },
        onMessage: (message) => {
          console.log('🗨️ Message:', message);

          if (message?.conversation_id && !conversationId) {
            console.log('🧠 Captured Conversation ID:', message.conversation_id);
            setConversationId(message.conversation_id);
          }
        },
        onError: (error) => {
          console.error('⚠️ Error:', error);
          setStatus('error');
        },
        onStatusChange: (s) => {
          console.log('🔄 Status changed:', s);
        },
        onModeChange: (mode) => {
          console.log('🎙️ Mode changed:', mode);
        },
      });

      conversationRef.current = conversation;

      console.log('conversation', conversation);
    } catch (err) {
      console.error('🎤 Mic or session error:', err);
      setStatus('error');
    }
  };

  const endConversation = async () => {
    if (conversationRef.current) {
      const id = conversationRef.current.getId();

      console.log('Ending conversation with ID:', id);
      await conversationRef.current.endSession();
      conversationRef.current = null;
      setStatus('manually ended');
    }
  };

  return (
    <div className="p-4 rounded border border-gray-300">
      {isCompleted == false && (
        <>
      <p>Status: <strong>{status}</strong></p>
      <div className="flex gap-4 mt-2">
        <button
          onClick={startConversation}
          disabled={status === 'connected' || status === 'connecting'}
          className="get-started-btn"
        >
          Start Conversation
        </button>
        <button
          onClick={endConversation}
          disabled={status !== 'connected'}
          className="px-4 py-2 bg-red-600 text-white rounded disabled:opacity-50"
        >
          End Conversation
        </button>
      </div>
      </>
      )}

      {isCompleted && (
        <div className="text-center mt-4">
          <p className="text-lg font-semibold">
            Your interview has been completed. Thank you for your time!
          </p>
        </div>
      )}
    </div>
  );
}

export default VoiceAgentSession;
