
import React from "react";
import { Button } from "../components/ui/button.tsx";
import { Sparkles, PenTool, Send, FileText } from "lucide-react";
import { useNavigate } from "react-router-dom";

const HowItWorks = () => {
  const navigate = useNavigate();
  const steps = [
    {
      icon: <Sparkles className="h-6 w-6 text-white" />,
      title: "Generate Questions",
      description: "Enter a simple prompt and our AI generates relevant interview questions in seconds.",
      screenshot: "/step1-generate-questions.png"
    },
    {
      icon: <PenTool className="h-6 w-6 text-white" />,
      title: "Review & Edit",
      description: "Customize the questions to perfectly fit your specific needs.",
      screenshot: "/step2-review-edit.png"
    },
    {
      icon: <Send className="h-6 w-6 text-white" />,
      title: "Share Link",
      description: "Send the interview link to recipients via email, SMS, or any messaging platform.",
      screenshot: "/step3-share-link.png"
    },
    {
      icon: <FileText className="h-6 w-6 text-white" />,
      title: "Collect & Analyze",
      description: "Get notified when responses arrive and analyze voice data for insights.",
      screenshot: "/step4-analyze-results.png"
    }
  ];

  return (
    <section id="how-it-works" className="py-16 px-4 bg-gray-50">
      <div className="container mx-auto max-w-6xl">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">How It Works</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Create voice-based interviews in minutes and start collecting authentic responses
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {steps.map((step, index) => (
            <div key={index} className="relative">
              <div className="flex flex-col items-center">
                <div className="bg-gradient-to-r from-brand-purple to-brand-blue rounded-full p-4 mb-4">
                  {step.icon}
                </div>
                
                {index < steps.length - 1 && (
                  <div className="hidden lg:block absolute top-5 left-[60%] w-[80%] h-0.5 bg-gradient-to-r from-brand-purple/30 to-brand-blue/30"></div>
                )}
                
                <h3 className="text-xl font-semibold mb-2 text-center">{step.title}</h3>
                <p className="text-gray-600 text-center">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
        
        <div className="mt-12 text-center">
          <Button onClick={() => navigate('/join-now')} className="bg-gradient-to-r from-brand-purple to-brand-blue text-white text-lg py-6 px-8 rounded-xl">
            Start Creating Now
          </Button>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
