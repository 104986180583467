
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const PodcastGuest = () => {
  return (
    <UseCaseTemplate 
      title="Podcast Guest Pre-Interview"
      prompt="Create 5 questions for potential podcast guests to assess their storytelling ability and audio quality."
      questions={[
        "Please introduce yourself and share what makes you an expert in your field.",
        "Tell us a brief story that illustrates an important principle or lesson from your experience.",
        "What are the 3 most important points you'd want our audience to take away from a conversation with you?",
        "How do you typically explain complex ideas in your field to people who are new to the subject?",
        "What's a common misconception about your topic that you're passionate about correcting?"
      ]}
      icon="🎧"
      description="Gauging storytelling ability. Voice responses help evaluate potential guests' speaking style, ability to stay concise, and audio setup quality before scheduling full interviews."
      isPersonal={false}
    />
  );
};

export default PodcastGuest;
