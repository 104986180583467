import { useElevenLabsWidget } from "./useElevenLabsWidget";
import { useState, useEffect } from "react";
import { useRef } from "react";
import { Conversation } from '@11labs/client';
import { FaMicrophone } from "react-icons/fa";
import { Button } from "../../../components/ui/button.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "../../../components/ui/popover.tsx";
import { Mic, MicOff, Pause, Play, CircleStop, Volume2, VolumeX } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../../components/ui/dialog.tsx";

const VoiceAgentPreview = ({ agentId, isOpen, onClose }) => {
  // const [agentId, setAgentId] = useState(null);
  // const [scriptLoaded, setScriptLoaded] = useState(false);
  // const [showWidget, setShowWidget] = useState(false);

  // useElevenLabsWidget(setScriptLoaded);

  // const handlePreview = async () => {
  //   const res = await fetch("/api/create-agent", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({ questions: personaInputs }),
  //   });

  //   const data = await res.json();
  //   setAgentId(data.agentId);
  //   setShowWidget(true);
  // };

  // return (
  //   <div>
  //     <button onClick={handlePreview}>Preview Voice Agent</button>

  //     {showWidget && agentId && scriptLoaded && (
  //       <div
  //         style={{
  //           position: "fixed",
  //           top: 0,
  //           left: 0,
  //           width: "100vw",
  //           height: "100vh",
  //           background: "rgba(0,0,0,0.5)",
  //           zIndex: 999,
  //         }}
  //       >
  //         <elevenlabs-convai
  //           key={agentId}
  //           agent-id={agentId}
  //           style={{
  //             position: "absolute",
  //             top: "50%",
  //             left: "50%",
  //             transform: "translate(-50%, -50%)",
  //             zIndex: 1000,
  //           }}
  //         ></elevenlabs-convai>
  //       </div>
  //     )}
  //   </div>
  // );

  const conversationRef = useRef(null);
    const [status, setStatus] = useState('idle');
    const [conversationId, setConversationId] = useState(null);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [recordingTime, setRecordingTime] = useState(0);


      useEffect(() => {
        let interval;
        
        if (isRecording && !isPaused) {
          interval = setInterval(() => {
            setRecordingTime(prev => prev + 1);
          }, 1000);
        }
        
        return () => {
          if (interval) clearInterval(interval);
        };
      }, [isRecording, isPaused]);

    const startConversation = async () => {
      try {
        setStatus('requesting mic');
  
        // Request mic permission
        await navigator.mediaDevices.getUserMedia({ audio: true });
  
        setStatus('connecting');
  
        const conversation = await Conversation.startSession({
          agentId,
          onConnect: () => {
            console.log('✅ Connected');
            setStatus('connected');
            setIsRecording(true);
            setIsRecording(true);
            setIsPaused(false);
          },
          onDisconnect: async () => {
            console.log('❌ Disconnected');
            setStatus('idle');
            setIsRecording(false);
          },
          onMessage: (message) => {
            console.log('🗨️ Message:', message);
  
            if (message?.conversation_id && !conversationId) {
              console.log('🧠 Captured Conversation ID:', message.conversation_id);
              setConversationId(message.conversation_id);
            }
          },
          onError: (error) => {
            console.error('⚠️ Error:', error);
            setStatus('error');
          },
          onStatusChange: (s) => {
            console.log('🔄 Status changed:', s);
          },
          onModeChange: (mode) => {
            console.log('🎙️ Mode changed:', mode);
          },
        });
  
        conversationRef.current = conversation;
  
        console.log('conversation', conversation);
      } catch (err) {
        console.error('🎤 Mic or session error:', err);
        setStatus('error');
      }
    };
  
    const handleStartRecording = () => {
      setIsRecording(true);
      setIsPaused(false);
    };
    
    const handlePauseRecording = () => {
      setIsPaused(true);
    };
    
    const handleResumeRecording = () => {
      setIsPaused(false);
    };

    const handleStopRecording = () => {
      setIsRecording(false);
      setIsPaused(false);
      setRecordingTime(0);
    };
  
    
    const endConversation = async () => {
      if (conversationRef.current) {
        await conversationRef.current.endSession();
        conversationRef.current = null;
        setIsRecording(false);
        setIsPaused(false);
        setRecordingTime(0);
        setStatus('idle');
      }
    };
  
    return (
      <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="max-w-md rounded-xl bg-white">
        <DialogHeader>
          <DialogTitle className="text-center text-xl font-semibold flex items-center justify-center">
            <Mic className="h-5 w-5 text-emerald-500 mr-2" /> 
            Preview
          </DialogTitle>
        </DialogHeader>
      <div className="p-4 rounded border border-gray-300">
        {isCompleted == false && (
          <>
          
          <div className="text-center mb-4">
        <p className="text-sm font-medium text-gray-500">Status: <strong>{status}</strong></p>

          {isRecording == false && (
            <div className="text-sm font-medium text-gray-500">Ready to record</div>
          )}

          {isRecording == true && (
            <div className="text-sm font-medium text-gray-500">Recording... {Math.floor(recordingTime / 60)}:{String(recordingTime % 60).padStart(2, '0')}</div>
          )}
          </div>

        <div className="flex items-center justify-center space-x-4">
                    {!isRecording ? (
                      <Button 
                        onClick={startConversation}
                        className="bg-red-500 hover:bg-red-600 text-white rounded-full h-12 w-12 flex items-center justify-center p-0"
                      >
                        <Mic className="h-5 w-5" />
                      </Button>
                    ) : (
                      <>
                        {isPaused ? (
                          <Button 
                            onClick={handleResumeRecording}
                            className="bg-emerald-500 hover:bg-emerald-600 text-white rounded-full h-12 w-12 flex items-center justify-center p-0"
                          >
                            <Play className="h-5 w-5" />
                          </Button>
                        ) : (
                          <Button 
                            onClick={handlePauseRecording}
                            className="bg-amber-500 hover:bg-amber-600 text-white rounded-full h-12 w-12 flex items-center justify-center p-0"
                          >
                            <Pause className="h-5 w-5" />
                          </Button>
                        )}
                        
                        <Button 
                          onClick={endConversation}
                          className="bg-gray-500 hover:bg-gray-600 text-white rounded-full h-12 w-12 flex items-center justify-center p-0"
                        >
                          <CircleStop className="h-5 w-5" />
                        </Button>
                      </>
                    )}
                    </div>

        {/* <div className=" mt-2">
          <button
            onClick={startConversation}
            disabled={status === 'connected' || status === 'connecting'}
            className="get-started-btn w-full display-block"
          >
            <FaMicrophone /> Start Conversation
          </button>
        </div> */}

        {/* <div className="mt-2">
          <button
            onClick={endConversation}
            disabled={status !== 'connected'}
            className="display-block w-full px-4 py-2 bg-red-600 text-white rounded disabled:opacity-50"
          >
            End Conversation
          </button>
        </div> */}
        </>
        )}
  
        {isCompleted && (
          <div className="text-center mt-4">
            <p className="text-lg font-semibold">
              Your interview has been completed. Thank you for your time!
            </p>
          </div>
        )}
      </div>
      </DialogContent>
      </Dialog>
    );
};

export default VoiceAgentPreview;