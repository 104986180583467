import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './InterviewLinkDetails.css';
import { getConversationDetails } from '../../utils/api';

const InterviewLinkDetails = () => {
    const [conversationDetails, setConversationDetails] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [transcript, setTranscript] = useState([]);
    const {id} = useParams();

    useEffect(() => {
      async function fetchConversationDetails() {
        const res = await getConversationDetails(id);
        if (res.success) {
          setConversationDetails(res.data);
          setTranscript(res.data.transcript);
          console.log('res', res.data);
        } else {
          alert('Error fetching conversation details');
        }
        setLoading(false);
      }
      fetchConversationDetails();
    }, []);

    return (
        <div className="interview-details-container">
          <div>
            <Link to="/interviewlink/list" className="back-button">Back</Link>
          </div>
            <h1 className="conversation-title">Conversation with Caring Dog Sitter Search</h1>
           
            <div className="tabs">
                <button className="tab active">Overview</button>
                <button className="tab">Transcription</button>
                <button className="tab">Client data</button>
            </div>
            <div className="transcription-section">
                {transcript.map((entry, index) => (
                    <p key={index}>
                        <strong>{entry.role === 'agent' ? 'Agent' : 'Client'}:</strong> {entry.message}
                    </p>
                ))}
            </div>
        </div>
    );
};

export default InterviewLinkDetails;
