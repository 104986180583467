
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const SpeakerSelection = () => {
  return (
    <UseCaseTemplate 
      title="Conference Speaker Selection"
      prompt="Create 5 questions for potential conference speakers to assess their presentation style and topic expertise."
      questions={[
        "Please briefly introduce yourself and describe the core message of your proposed presentation.",
        "What unique perspective or insights will attendees gain from your session that they won't find elsewhere?",
        "How do you engage audiences and make complex topics accessible when presenting?",
        "Can you share a story or example that illustrates the impact of your topic in real-world settings?",
        "How do you typically handle audience questions, especially challenging ones, during your presentations?"
      ]}
      icon="🎤"
      description="Listening to energy and delivery. Voice responses reveal speaking pace, clarity of explanation, enthusiasm, and ability to engage listeners beyond what a written proposal shows."
      isPersonal={false}
    />
  );
};

export default SpeakerSelection;
