
import React, { useState } from "react";
import { Button } from "../components/ui/button.tsx";
import { MessageSquare, Upload, PenTool, FileText, Share2, Mic } from "lucide-react";
import { Link } from "react-router-dom";

const ContentFromConversation = () => {
  const [activeContentType, setActiveContentType] = useState(null);
  
  const handleContentButtonClick = (type) => {
    setActiveContentType(type);
  };
  
  const getGeneratedContent = () => {
    switch (activeContentType) {
      case "blog":
        return {
          title: "Getting Started with Sustainable Gardening: A Beginner's Guide",
          content: "Introduction: Sustainable gardening isn't just good for the planet—it's easier on your wallet and produces healthier plants. In this comprehensive guide, we'll explore how to start your sustainable garden with minimal effort and maximum impact..."
        };
      case "social":
        return {
          title: "LinkedIn Post",
          content: "🌱 Just recorded a quick voice interview about #SustainableGardening and transformed it into actionable tips! The biggest surprise? You don't need fancy equipment to get started. Three key takeaways from my conversation:\n\n1. Start with native plants\n2. Create your own compost\n3. Implement water-saving techniques\n\nWhat sustainable practices have you implemented in your garden? Comment below! #GreenLiving #Sustainability"
        };
      case "newsletter":
        return {
          title: "Monthly Green Thumb Newsletter",
          content: "Dear Green Enthusiasts,\n\nThis month, we're excited to share insights from our recent voice interview with sustainable gardening expert Jane Smith. Jane shared practical tips for beginners that require minimal time investment but yield maximum results...\n\nHighlights include:\n- Season-by-season planting guide\n- Budget-friendly garden tools\n- Community garden initiatives in your area"
        };
      default:
        return null;
    }
  };
  
  const generatedContent = getGeneratedContent();
  
  return (
    <section className="py-16 px-4 bg-white">
      <div className="container mx-auto max-w-7xl">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="order-2 lg:order-1">
            <div className="bg-gradient-to-br from-emerald-50 to-emerald-100 p-8 rounded-2xl shadow-lg border border-emerald-200 relative">
              <div className="absolute -right-3 -top-3 bg-emerald-500 text-white p-2 rounded-lg text-sm font-medium">
                New Feature
              </div>
              
              <div className="flex items-start space-x-4">
                <div className="bg-white p-3 rounded-xl shadow-sm">
                  <Mic className="h-6 w-6 text-emerald-500" />
                </div>
                <div className="flex-1">
                  <div className="bg-white p-4 rounded-xl shadow-sm mb-3">
                    <p className="text-gray-700">
                      <span className="font-medium">Voice Interview Question:</span> What are your favorite sustainable gardening practices for beginners?
                    </p>
                  </div>
                  <div className="bg-emerald-500/10 p-4 rounded-xl shadow-sm mb-6">
                    <p className="text-gray-700">
                      <span className="font-medium">Your Voice Response:</span> I think composting is really accessible for beginners. You can start small with kitchen scraps and gradually build up. Native plants are also great because they require less water and maintenance. And collecting rainwater with a simple barrel system can make a big difference...
                    </p>
                  </div>
                  
                  <div className="flex flex-wrap gap-3 mb-6">
                    <Button 
                      className={`${activeContentType === "blog" ? "bg-emerald-600" : "bg-emerald-500"} hover:bg-emerald-700`}
                      onClick={() => handleContentButtonClick("blog")}
                    >
                      <FileText className="h-4 w-4 mr-2" />
                      Blog Post
                    </Button>
                    <Button 
                      variant={activeContentType === "social" ? "default" : "outline"} 
                      className={activeContentType === "social" ? "bg-emerald-600 hover:bg-emerald-700" : "border-emerald-200 text-emerald-700"}
                      onClick={() => handleContentButtonClick("social")}
                    >
                      <Share2 className="h-4 w-4 mr-2" />
                      Social Posts
                    </Button>
                    <Button 
                      variant={activeContentType === "newsletter" ? "default" : "outline"} 
                      className={activeContentType === "newsletter" ? "bg-emerald-600 hover:bg-emerald-700" : "border-emerald-200 text-emerald-700"}
                      onClick={() => handleContentButtonClick("newsletter")}
                    >
                      <MessageSquare className="h-4 w-4 mr-2" />
                      Newsletter
                    </Button>
                  </div>
                  
                  {generatedContent && (
                    <div className="bg-white p-4 rounded-xl shadow-sm border border-emerald-200 animate-fade-in">
                      <h4 className="font-medium mb-2 flex items-center">
                        {activeContentType === "blog" && <FileText className="h-4 w-4 mr-2 text-emerald-600" />}
                        {activeContentType === "social" && <Share2 className="h-4 w-4 mr-2 text-emerald-600" />}
                        {activeContentType === "newsletter" && <MessageSquare className="h-4 w-4 mr-2 text-emerald-600" />}
                        Generated {activeContentType === "blog" ? "Blog Post" : activeContentType === "social" ? "Social Post" : "Newsletter"}
                      </h4>
                      <p className="text-sm text-gray-600 mb-2">
                        <strong>Title:</strong> "{generatedContent.title}"
                      </p>
                      <div className="text-xs text-gray-500 bg-gray-50 p-2 rounded-lg max-h-32 overflow-y-auto">
                        <p>{generatedContent.content}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          
          <div className="order-1 lg:order-2">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              Create Content From Voice Interviews
            </h2>
            
            <p className="text-lg text-gray-600 mb-6">
              Answer a few voice questions or upload an interview recording - get instant blogs, newsletters, social posts, and more! Say goodbye to writer's block forever.
            </p>
            
            <ul className="space-y-4 mb-8">
              {[
                {
                  icon: <Mic className="h-5 w-5 text-emerald-500" />,
                  title: "Voice Interviews",
                  description: "Answer simple questions in your own voice - no writing required"
                },
                {
                  icon: <Upload className="h-5 w-5 text-emerald-500" />,
                  title: "Upload Recordings",
                  description: "Convert existing voice conversations, interviews or meetings into polished content"
                },
                {
                  icon: <PenTool className="h-5 w-5 text-emerald-500" />,
                  title: "Multiple Formats",
                  description: "Transform the same interview into blogs, social posts, newsletters, and more"
                }
              ].map((item, index) => (
                <li key={index} className="flex items-start">
                  <div className="bg-emerald-100 p-2 rounded-full mr-3 mt-0.5">
                    {item.icon}
                  </div>
                  <div>
                    <h3 className="font-medium">{item.title}</h3>
                    <p className="text-gray-600">{item.description}</p>
                  </div>
                </li>
              ))}
            </ul>
            
            <Link to="/voice-interviews">
              <Button className="bg-gradient-to-r from-emerald-500 to-teal-600 text-white px-6 py-2.5 rounded-lg hover:from-emerald-600 hover:to-teal-700 transition-all">
                Explore 100+ Interview Topics
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentFromConversation;
