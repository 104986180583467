import React, { useState, useEffect, useRef } from "react";
import { Button } from "../components/ui/button.tsx";
import { ArrowRight, Mic, Wand2, Volume2 } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface HeroSectionProps {
  onShowUseCasesClick?: () => void;
}

const HeroSection = ({ onShowUseCasesClick }: HeroSectionProps) => {
  // Sample use cases that will rotate
  const navigate = useNavigate();
  const useCases = [
    {
      title: "Interview a Dog Walker",
      prompt: "I need 5 questions to interview potential dog walkers about their experience with different breeds.",
      questions: [
        "How much experience do you have with walking different breeds and sizes of dogs?",
        "What is your typical availability during weekdays and weekends?"
      ],
      isPersonal: true,
      icon: "🐕"
    },
    {
      title: "Screen Job Candidates",
      prompt: "Create 5 questions for screening marketing manager candidates to assess their experience.",
      questions: [
        "Describe your experience managing successful marketing campaigns from concept to execution.",
        "How do you measure the success of a marketing strategy?"
      ],
      isPersonal: false,
      icon: "👩‍💼"
    },
    {
      title: "Find a Babysitter",
      prompt: "Help me create 5 questions to interview potential babysitters for my 3-year-old.",
      questions: [
        "What experience do you have with toddlers and what activities do you typically do with them?",
        "How would you handle a temper tantrum or difficult behavior?"
      ],
      isPersonal: true,
      icon: "👶"
    },
    {
      title: "Customer Feedback",
      prompt: "Create 5 questions to gather feedback from customers who recently used our customer service.",
      questions: [
        "How satisfied were you with the resolution of your recent support request?",
        "What one thing could we improve about our customer support experience?"
      ],
      isPersonal: false,
      icon: "📊"
    },
    {
      title: "Vet Home Contractors",
      prompt: "Generate 5 questions to ask potential contractors for a kitchen renovation project.",
      questions: [
        "Can you describe your approach to staying on budget while managing unexpected issues?",
        "What's your typical timeline for a kitchen renovation of approximately 200 square feet?"
      ],
      isPersonal: true,
      icon: "🏡"
    },
    {
      title: "Team Performance Review",
      prompt: "I need 5 questions for a quarterly performance review for my team members.",
      questions: [
        "What achievements from this quarter are you most proud of and why?",
        "What resources or support would help you be more effective in the next quarter?"
      ],
      isPersonal: false,
      icon: "📈"
    }
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [showQuestions, setShowQuestions] = useState(false);
  const typingTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const generatingTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const rotationTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  // Function to type out the prompt
  useEffect(() => {
    if (typingTimer.current) clearTimeout(typingTimer.current);
    if (generatingTimer.current) clearTimeout(generatingTimer.current);
    if (rotationTimer.current) clearTimeout(rotationTimer.current);

    setCurrentPrompt("");
    setIsTyping(true);
    setIsGenerating(false);
    setShowQuestions(false);
    
    let i = 0;
    const currentUseCase = useCases[activeIndex];
    
    const typePrompt = () => {
      if (i <= currentUseCase.prompt.length) {
        // Set the prompt to be the substring from start to current position
        setCurrentPrompt(currentUseCase.prompt.substring(0, i));
        i++;
        typingTimer.current = setTimeout(typePrompt, 40);
      } else {
        setIsTyping(false);
        // After typing is done, simulate generating
        setIsGenerating(true);
        generatingTimer.current = setTimeout(() => {
          setIsGenerating(false);
          setShowQuestions(true);
          
          // Set up the next rotation after showing questions for a while
          rotationTimer.current = setTimeout(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % useCases.length);
          }, 5000);
        }, 1500);
      }
    };
    
    // Start with empty prompt
    setCurrentPrompt('');
    i = 1; // Start with showing the first character
    typePrompt();
    
    return () => {
      if (typingTimer.current) clearTimeout(typingTimer.current);
      if (generatingTimer.current) clearTimeout(generatingTimer.current);
      if (rotationTimer.current) clearTimeout(rotationTimer.current);
    };
  }, [activeIndex]);

  const currentUseCase = useCases[activeIndex];

  return (
    <section className="pt-28 pb-16 md:pt-32 md:pb-24 px-4">
      <div className="container mx-auto max-w-7xl">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="text-center lg:text-left">
            <div className="inline-flex items-center bg-white/90 backdrop-blur-sm px-4 py-2 rounded-full shadow-sm mb-6">
              <span className="inline-block animate-wave">👋</span>
              <span className="ml-2 font-medium text-sm text-gray-600">Welcome to yourinterviewer</span>
            </div>
            
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold leading-tight mb-6">
              Capture <span className="text-transparent bg-clip-text bg-gradient-to-r from-brand-purple to-brand-blue">authentic</span> responses
            </h1>
            
            <p className="text-lg text-gray-600 mb-4 max-w-xl mx-auto lg:mx-0">
              When voice notes are too personal, generate an interview link. Just like voice notes, but with real-time tracking, transcripts, insights, and reporting.
            </p>
            
            <div className="py-3 px-4 bg-gray-50 rounded-lg border border-gray-100 mb-4 max-w-xl mx-auto lg:mx-0">
              <p className="text-sm text-gray-600 flex items-start">
                <span className="text-brand-purple font-bold mr-2 mt-0.5">📊</span>
                <span>
                  <strong>Fact:</strong> People are approximately 3 times more likely to provide truthful responses when using voice versus text.
                  <span className="block mt-1 text-xs text-gray-500">Journal of Experimental Psychology, 2020</span>
                </span>
              </p>
            </div>
            
            <div className="flex flex-col sm:flex-row justify-center lg:justify-start gap-4 mb-6">
              <Button onClick={() => navigate('/join-now')} className="bg-gradient-to-r from-brand-purple to-brand-blue text-white text-lg py-6 px-8 rounded-xl flex items-center">
                Try for free
                <ArrowRight className="ml-2 h-5 w-5" />
              </Button>
              <Button 
                variant="outline" 
                className="text-lg py-6 px-8 rounded-xl"
                onClick={onShowUseCasesClick}
              >
                See how people use it
              </Button>
            </div>
            
            <div className="mt-4 text-sm text-gray-500 flex items-center justify-center lg:justify-start gap-x-4">
              <span className="inline-flex items-center">
                <svg className="w-4 h-4 mr-1 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
                </svg>
                No credit card required
              </span>
              <span className="inline-flex items-center">
                <svg className="w-4 h-4 mr-1 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
                </svg>
                Free personal plan available
              </span>
            </div>
          </div>
          
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-radial from-brand-purple/30 to-transparent rounded-full blur-3xl -z-10"></div>
            <div className="bg-white rounded-xl shadow-xl p-6 border border-gray-100 transition-all duration-300 hover:shadow-2xl">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <div className={`${currentUseCase.isPersonal ? 'bg-pink-100 text-pink-500' : 'bg-brand-purple/10 text-brand-purple'} p-2 rounded-lg flex items-center justify-center`}>
                    <span className="text-xl">{currentUseCase.icon}</span>
                  </div>
                  <h3 className="ml-3 font-semibold">{currentUseCase.title}</h3>
                </div>
                <Button 
                  size="sm" 
                  className={`${isGenerating ? 'bg-gray-300' : 'bg-brand-purple'} text-white transition-colors`}
                  disabled={isGenerating}
                >
                  {isGenerating ? 'Generating...' : 'Generate'}
                </Button>
              </div>
              
              <div className="bg-gray-50 p-4 rounded-lg mb-4 h-24 flex items-center">
                <p className="text-gray-600 italic">
                  {currentPrompt}
                  {isTyping && <span className="inline-block w-2 h-4 bg-brand-purple/80 animate-pulse ml-0.5"></span>}
                </p>
              </div>
              
              <div className="space-y-4">
                {currentUseCase.questions.map((question, index) => (
                  <div 
                    key={index} 
                    className={`bg-white p-4 rounded-lg border border-gray-200 transition-all duration-300 transform ${
                      showQuestions ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
                    } ${showQuestions ? 'delay-' + (index * 300) : ''}`}
                    style={{ transitionDelay: showQuestions ? `${index * 150}ms` : '0ms' }}
                  >
                    <div className="flex items-start">
                      <div className="bg-brand-blue/10 p-1.5 rounded-full">
                        <span className="text-sm font-bold text-brand-blue">{index + 1}</span>
                      </div>
                      <p className="ml-3 text-gray-800">
                        {question}
                      </p>
                    </div>
                    {index === 0 && showQuestions && (
                      <div className="ml-9 mt-2 flex items-center">
                        <Volume2 className="h-4 w-4 text-red-500 mr-2 animate-pulse" />
                        <div className="h-1.5 bg-gray-200 rounded-full w-full overflow-hidden">
                          <div className="bg-red-500 h-full w-3/4 animate-pulse-slow"></div>
                        </div>
                        <span className="ml-2 text-xs text-gray-500">0:45</span>
                      </div>
                    )}
                  </div>
                ))}
                
                <div className="text-center pt-2">
                  <span className="text-gray-400 text-sm">+ 3 more questions</span>
                </div>
              </div>
              
              <div className="mt-6 text-center">
                <Button onClick={() => navigate('/join-now')} className="bg-brand-blue text-white w-full">Send to Candidates</Button>
              </div>
              
              <div className="mt-4 flex items-center justify-center text-xs text-gray-500">
                <div className="w-6 h-6 rounded-full bg-gradient-to-r from-brand-purple to-brand-blue flex items-center justify-center text-white text-xs font-bold mr-2">J</div>
                <span>Jessica will get audio responses from all your candidates</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
