import { useState, useEffect, useCallback } from "react";
import { getUserSubscriptions, getPurchasedLicense } from "../utils/api";

const useLicenseStatus = () => {
  const [licenseStatus, setLicenseStatus] = useState(null);
  const [licenseLoading, setLicenseLoading] = useState(false);
  const [licenseError, setLicenseError] = useState(null);
  const [licenseData, setLicenseData] = useState(null);

  const fetchLicenseStatus = useCallback(async () => {
    setLicenseLoading(true);
    setLicenseError(null);

    try {
      const response = await getPurchasedLicense();

      if (response.success){
        //setLicenseStatus(response.data); // Adjust based on your API response
        setLicenseData(response.data);
      }
    } catch (err) {
      setLicenseError(err.message || "Failed to fetch subscriber status");
    } finally {
      setLicenseLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchLicenseStatus();
  }, [fetchLicenseStatus]);

  return { licenseStatus, licenseLoading, licenseData, licenseError, refetch: fetchLicenseStatus };
};

export default useLicenseStatus;
