
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const BandInterview = () => {
  return (
    <UseCaseTemplate 
      title="Band Interview"
      prompt="Create 5 questions for potential band members to discuss their musical experience and share a brief sample."
      questions={[
        "Please introduce yourself and tell us about your musical background and influences.",
        "What instruments do you play, and how long have you been playing each one?",
        "Could you share a brief sample of your playing or singing that represents your style?",
        "What are your goals as a musician, and what are you looking for in a band?",
        "How do you handle creative differences when collaborating with other musicians?"
      ]}
      icon="🎸"
      description="Play sample for me and understand musical influences. Voice responses let you hear both musical ability and alignment with your band's style and chemistry."
      isPersonal={true}
    />
  );
};

export default BandInterview;
