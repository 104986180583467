
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const BabysitterVetting = () => {
  return (
    <UseCaseTemplate 
      title="Babysitter Vetting"
      prompt="I need 5 questions to understand a babysitter's personality, tone of voice, and comfort with kids."
      questions={[
        "Can you tell me about your experience caring for children and what ages you're most comfortable with?",
        "How would you handle a situation where a child is throwing a tantrum or refusing to follow instructions?",
        "What are some of your favorite activities to do with children to keep them engaged and entertained?",
        "How do you approach safety when caring for someone else's children?",
        "Can you describe a challenging situation you've had with a child and how you resolved it?"
      ]}
      icon="👶"
      description="Quick Q&A to understand personality, tone of voice, and comfort with kids. Voice responses reveal confidence, warmth, and how they interact with children."
      isPersonal={true}
    />
  );
};

export default BabysitterVetting;
