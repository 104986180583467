
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const JobInterview = () => {
  return (
    <UseCaseTemplate 
      title="Job Interview"
      prompt="Generate 5 screening questions for marketing manager candidates to assess their communication style and cultural fit."
      questions={[
        "Can you describe a marketing campaign you led that didn't meet expectations and what you learned from it?",
        "How do you approach collaborating with different departments like sales, product, and design?",
        "What's your philosophy on balancing data-driven decisions with creative intuition in marketing?",
        "Tell me about a time when you had to pivot a marketing strategy quickly and how you managed that change.",
        "How do you stay current with marketing trends and which recent trend do you find most interesting?"
      ]}
      icon="👩‍💼"
      description="Screening a candidate for cultural fit or communication style. Voice responses reveal confidence, articulation skills, and how they present ideas."
      isPersonal={false}
    />
  );
};

export default JobInterview;
