import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { acceptDemoBuyer, getBuyerObjections, getSellerObjections, addBuyerFeedback, addSellerFeedback, getBuyerDemoFromRef, refreshToken, hRefreshToken, addUserEventTracking, verifyStl, addBuyerRole, createSubscription, checkSubscriptionStatus, checkHasPaymentMethod } from '../../utils/api';

import { FaAward, FaChartLine, FaCircle, FaCrown, FaDiceFive, FaHorseHead, FaRegCheckCircle, FaTimes } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';
import { isAuthed } from "../../services/auth.service";
import useSubscriberStatus from "../../hooks/useSubscriberStatus";

const tiers = [
  {
    name: 'FREE',
    id: 'free',
    href: '/join-now',
    priceMonthly: '$0',
    description: "Get started for free. Access some of our Journey's and start generating authentic content today.",
    features: ['Access to our free interviews',
      'Schedule interviews on your own time', 
      'Access to our content studio to generate content',
      '48-hour support response time'],
    featured: false,
    withCheckout: false
  },
  {
    name: 'PRO',
    id: 'Pro',
    href: '/join-now',
    priceMonthly: '$29',
    totalPrice: '$59',
    description: 'Get access to our complete library of Journeys and Interviews.',
    features: [
      'Unlimited access to ALL interviews',
      'Access to all output types',
      'Unlimited interview retakes',
      'x15 more content generation than FREE',
      '24-hour support response time',
    ],
    featured: false,
    withCheckout: true
  },

  {
    name: 'UNLIMITED',
    id: 'Unlimited',
    href: '/join-now',
    priceMonthly: '$59',
    totalPrice: '$89',
    description: 'Get unlimited access to our complete library of Journeys and Interviews.',
    features: [
      'Unlimited access to ALL Journeys',
      'Unlimited interview retakes',
      'Unlimited content generation',
      'Ability to import your own transcripts',
      '24-hour support response time',
      'Access to new features as soon as they are released'
    ],
    featured: true,
    withCheckout: true
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function PricingWithAction() {
      const [submitting, setSubmitting] = useState(false);
      const [showConfirm, setShowConfirm] = useState(false);
      const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
      const [loadedPaymentMethod, setLoadedPaymentMethod] = useState(false);
      const [hasSubscription, setHasSubscription] = useState(false);
      const [loadedSubscriptionCheck, setLoadedSubscriptionCheck] = useState(false);
      const [completed, setCompleted] = useState(false);
      const [selectedPlan, setSelectedPlan] = useState(null);
      const [showUpgrade, setShowUpgrade] = useState(false);
      const [showDowngrade, setShowDowngrade] = useState(false);
      const { subscriberStatus, subscriberLoading, subscriberData, subscriberError, refetch: fetchSubscriberStatus } = useSubscriberStatus();
      const navigate =useNavigate();

      console.log(subscriberData);

      useEffect(() => {
            async function loadPaymentCheck()
            {
              let res = await checkHasPaymentMethod();
      
              if (res.success){
                setHasPaymentMethod(res.data);
                setLoadedPaymentMethod(true);
              }
            }
      
            async function loadSubscriptionCheck(){
              let res = await checkSubscriptionStatus();
      
              if (res.success){
                setHasSubscription(res.data);
                setLoadedSubscriptionCheck(true);
              }
            }
      
            loadPaymentCheck();
              loadSubscriptionCheck();
          }, []);

          async function handleUpgrade(name){
            setSelectedPlan(name);
            if (loadedPaymentMethod == true){
              if (hasPaymentMethod == false){
                navigate('/account/payment-method');
              }
          
              else{
                setShowConfirm(true);
              }
            }
          }
      
          async function handleConfirm(){
            setSubmitting(true);
            let res = await createSubscription(selectedPlan);
      
            if (res.success){
             setSubmitting(false);
             setCompleted(true);
            }
      
            else{
              alert(res.message);
            }
          }

          async function handleRedirect(){
            window.location = '/journeys';
          }

          async function handleUpgradeFromPro(plan){
            setSelectedPlan(plan);
            setShowUpgrade(true);
          }

          async function handleDowngrade(plan){
            setSelectedPlan(plan);
            setShowDowngrade(true);
          }

  return (
    <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div aria-hidden="true" className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl">
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
        />
      </div>
      <div className="mx-auto max-w-4xl text-center">
        <h2 className="text-base/7 font-semibold text-indigo-600">Pricing</h2>
        <p className="mt-2 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
          Choose the right plan for you
        </p>
      </div>
      <p className="mx-auto mt-6 max-w-2xl text-pretty text-center text-lg font-medium text-gray-600 sm:text-xl/8">
        Choose an affordable plan that's packed with the best features for generating unique and personal content.
      </p>
      <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-3">
        {tiers.map((tier, tierIdx) => (
          <div
            key={tier.id}
            className={classNames(
              tier.featured ? 'relative bg-gray-900 shadow-2xl' : 'bg-white/60 sm:mx-8 lg:mx-0',
              tier.featured
                ? ''
                : tierIdx === 0
                  ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none'
                  : 'sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl',
              'rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10',
            )}
          >
            <h3
              id={tier.id}
              className={classNames(tier.featured ? 'text-indigo-400' : 'text-indigo-600', 'text-base/7 font-semibold')}
            >
              {tier.name}
            </h3>

            <div className="mt-4 flex items-center gap-x-2">
            <span
                className={classNames(
                  tier.featured ? 'text-white' : 'text-gray-900',
                  'text-1xl line-through font-semibold tracking-tight',
                )}
              >{tier.totalPrice} / month
              </span>
              </div>

            <p className="mt-4 flex items-baseline gap-x-2">
              <span
                className={classNames(
                  tier.featured ? 'text-white' : 'text-gray-900',
                  'text-5xl font-semibold tracking-tight',
                )}
              >
                {tier.priceMonthly}
              </span>
              <span className={classNames(tier.featured ? 'text-gray-400' : 'text-gray-500', 'text-base')}>/month</span>
            </p>
            <p className={classNames(tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-6 text-base/7')}>
              {tier.description}
            </p>
            <ul
              role="list"
              className={classNames(
                tier.featured ? 'text-gray-300' : 'text-gray-600',
                'mt-8 space-y-3 text-sm/6 sm:mt-10',
              )}
            >
              {tier.features.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  &#9679; {feature}
                </li>
              ))}
            </ul>

            {tier.withCheckout == false && (
              <>
                {isAuthed() == false && (
                  <a
                    href={tier.href}
                    aria-describedby={tier.id}
                    className={classNames(
                      tier.featured
                        ? 'bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500'
                        : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline-indigo-600',
                      'mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10',
                    )}
                  >
                    Get started today
                  </a>
                )}

                
              </>
            )}

          {tier.withCheckout == true && isAuthed() == false && (
            <>
            <a
                    href={tier.href}
                    aria-describedby={tier.id}
                    className={classNames(
                      tier.featured
                        ? 'bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500'
                        : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline-indigo-600',
                      'mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10',
                    )}
                  >
                    Get started today
                  </a>
            </>
          )}

            {tier.withCheckout == true && isAuthed() == true && (
              <>
                <div className='upgrade__plan-btns'>
                  {subscriberLoading == false && subscriberData != null && subscriberData.length > 0 && subscriberData[0].name == 'Pro' && tier.id == 'Pro' && (
                    <div className={classNames(
                      tier.featured
                        ? 'bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500'
                        : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline-indigo-600',
                      'mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10',
                    )}>
                      YOU'RE ON PRO
                    </div>
                  )}

                  {subscriberLoading == false && subscriberData != null && subscriberData.length > 0 && subscriberData[0].name == 'Pro' && tier.id == 'Unlimited' && (
                    <div onClick={() => handleUpgradeFromPro(tier.id)} className={classNames(
                      tier.featured
                        ? 'bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500'
                        : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline-indigo-600',
                      'mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10',
                    )}>
                      UPGRADE
                    </div>
                  )}

              {subscriberLoading == false && subscriberData != null && subscriberData.length > 0 && subscriberData[0].name == 'Unlimited' && tier.id == 'Unlimited' && (
                    <div className={classNames(
                      tier.featured
                        ? 'bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500'
                        : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline-indigo-600',
                      'mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10',
                    )}>
                      YOU'RE ON UNLIMITED
                    </div>
                  )}

          {subscriberLoading == false && subscriberData != null && subscriberData.length > 0 && subscriberData[0].name == 'Unlimited' && tier.id == 'Pro' && (
                    <div onClick={() => handleDowngrade(tier.id)} className={classNames(
                      tier.featured
                        ? 'bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500'
                        : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline-indigo-600',
                      'mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10',
                    )}>
                      DOWNGRADE
                    </div>
                  )}

                  {subscriberLoading == false && subscriberData != null && subscriberData.length == 0 && (
                    <div className={classNames(
                      tier.featured
                        ? 'cursor-pointer bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500'
                        : 'cursor-pointer text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline-indigo-600',
                      'mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10',
                    )} onClick={() => handleUpgrade(tier.id)}>UPGRADE</div>
                  )}

                </div>
              </>
            )}
          </div>
        ))}
      </div>

      {showConfirm && (
            <>
              <div className='overlay'></div>
              <div className='modal center'>
                <div className='modal__close' onClick={() => setShowConfirm(false)}>
                  <FaTimes />
                </div>

                {completed == false && (
                  <>
                <div className='center' style={{fontSize: '70px'}}>
                  <FaCrown />
                </div>

                <h2 className='modal__h2'>UPGRADE TO <span className='highlight'>
                  {tiers.filter(tier => tier.id == selectedPlan)[0].name}
                  </span></h2>

                <div className='upgrade__plan-price'>
                  <span className='upgrade__plan-price-amount'>
                    {tiers.filter(tier => tier.id == selectedPlan)[0].priceMonthly}
                    </span> / month
                </div>

                <div className='mt-20 center'>
                  {submitting == false && (
                    <button className='w-full bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500 mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10' onClick={() => handleConfirm()}>CONFIRM SUBSCRIPTION</button>
                  )}

                  {submitting == true && (
                    <div>
                      <Spinner />
                    </div>
                  )}
                </div>

                <div className='upgrade__disclosure'>
                  By clicking 'confirm', you agree to be charged the monthly subscription fee which will automatically be charged each month. You can cancel anytime.
                </div>
                </>
                )}

                {completed == true && (
                  <>
                    <div>
                    <div className='center' style={{fontSize: '70px'}}>
                      <FaCrown />
                    </div>

                    <h2 className='modal__h2 mt-20'><span className='highlight'>SUCCESS!</span></h2>

                    <p className='mt-20'>
                      You are now on the {tiers.filter(x => x.id == selectedPlan)[0].name} plan and instantly get access to all journeys and interviews.
                    </p>

                    <div className='mt-20'>
                      <button className='w-full bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500 mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10' onClick={() => handleRedirect()}>TAKE ME TO MY DASHBOARD</button>
                    </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          {showUpgrade && (
            <>
             <div className='overlay'></div>
              <div className='modal center'>
                <div className='modal__close' onClick={() => setShowUpgrade(false)}>
                  <FaTimes />
                </div>

                <div className='center' style={{fontSize: '70px'}}>
                  <FaCrown />
                </div>

                <h2 className='modal__h2'>UPGRADE TO <span className='highlight'>
                  {tiers.filter(tier => tier.id == selectedPlan)[0].name}
                  </span></h2>

                <div className='upgrade__plan-price'>
                  <span className='upgrade__plan-price-amount'>
                    {tiers.filter(tier => tier.id == selectedPlan)[0].priceMonthly}
                    </span> / month
                </div>

                <p>
                  To upgrade your account you must first cancel your current subscription.
                </p>

                <div className='mt-20 center'>
                  {submitting == false && (
                    <button className='w-full bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500 mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10' onClick={() => navigate('/account/subscriptions')}>Account Settings</button>
                  )}
                </div>

                
              </div>
            </>
          )}

        {showDowngrade && (
            <>
             <div className='overlay'></div>
              <div className='modal center'>
                <div className='modal__close' onClick={() => setShowDowngrade(false)}>
                  <FaTimes />
                </div>

                <div className='center' style={{fontSize: '70px'}}>
                  <FaCrown />
                </div>

                <h2 className='modal__h2'>DOWNGRADE TO <span className='highlight'>
                  {tiers.filter(tier => tier.id == selectedPlan)[0].name}
                  </span></h2>

                <div className='upgrade__plan-price'>
                  <span className='upgrade__plan-price-amount'>
                    {tiers.filter(tier => tier.id == selectedPlan)[0].priceMonthly}
                    </span> / month
                </div>

                <p>
                  To downgrade your account you must first cancel your current subscription.
                </p>

                <div className='mt-20 center'>
                  {submitting == false && (
                    <button className='w-full bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500 mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10' onClick={() => navigate('/account/subscriptions')}>Account Settings</button>
                  )}
                </div>

                
              </div>
            </>
          )}
    </div>
  )
}

export default PricingWithAction;