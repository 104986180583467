import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import { createLicensePurchase } from '../../utils/api';

const PurchaseLicenceSuccess = () => {
    const navigate = useNavigate();
    const [locked, setLocked] = useState(false);

    async function handleRedirect(){
        window.location = '/dashboard';
    }

    useEffect(() => {
        async function purchaseLicense(){
          // get session_id from url
          setLocked(true);
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const sessionId = urlParams.get('session_id');

            let res = await createLicensePurchase(sessionId);

            if (res.success){
                console.log('License purchased');
            }

            else{
                console.log('Error purchasing license');
            }

            setLocked(false);
        }

        if (locked == false)
        purchaseLicense();
    }, []);

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="w-full max-w-lg bg-white shadow-xl rounded-2xl overflow-hidden">
        <div className="p-6">
          <div className="flex flex-col items-center text-center">
            {/* Success Icon */}
            <div className="mb-4 relative">
              <div className="absolute -inset-1">
                <div className="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-green-400 via-emerald-500 to-teal-500"></div>
              </div>
              <div className="relative">
                <div className="h-16 w-16 bg-green-100 rounded-full flex items-center justify-center">
                  <svg className="h-8 w-8 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
            </div>

            {/* Main Content */}
            <div className="space-y-2 mb-8">
              <h2 className="text-2xl font-bold text-gray-900">Payment Confirmed!</h2>
              <p className="text-gray-600">Thank you for purchasing our premium plan.</p>
            </div>

            {/* Features List */}
            <div className="w-full space-y-4 mb-8">
              <div className="flex items-center space-x-3 text-left">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-purple-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                  </svg>
                </div>
                <div>
                  <h4 className="font-medium text-gray-900">Premium Features Unlocked</h4>
                  <p className="text-sm text-gray-500">Access to all premium features and content</p>
                </div>
              </div>
              
              <div className="flex items-center space-x-3 text-left">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <div>
                  <h4 className="font-medium text-gray-900">24/7 Priority Support</h4>
                  <p className="text-sm text-gray-500">Direct access to our support team</p>
                </div>
              </div>
            </div>

            {/* CTA Button */}
            <button onClick={() => handleRedirect()} className="w-full bg-gradient-to-r from-green-400 to-emerald-500 text-white py-3 px-6 rounded-lg font-medium hover:opacity-90 transition-opacity flex items-center justify-center group">
              Go to Dashboard
              <svg className="ml-2 h-4 w-4 transform group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseLicenceSuccess;