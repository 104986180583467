
import React from "react";
import { LucideIcon } from "lucide-react";
import { Button } from "../components/ui/button.tsx";
import { ArrowRight } from "lucide-react";

interface FeatureCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
  iconColor?: string;
  iconBgColor?: string;
  link?: string;
  highlight?: boolean;
}

const FeatureCard = ({ 
  icon: Icon, 
  title, 
  description, 
  iconColor = "text-brand-purple", 
  iconBgColor = "bg-brand-purple/10",
  link = "#",
  highlight = false
}: FeatureCardProps) => {
  return (
    <div className={`bg-white rounded-xl p-6 shadow-sm border ${highlight ? 'border-brand-purple/30' : 'border-gray-100'} hover:shadow-md transition-shadow duration-300 flex flex-col h-full ${highlight ? 'relative overflow-hidden' : ''}`}>
      {highlight && (
        <div className="absolute -top-1 -right-1">
          <div className="bg-brand-purple text-white text-xs font-bold px-3 py-1 rotate-12 shadow-sm">
            Popular
          </div>
        </div>
      )}
      <div className={`${iconBgColor} p-3 rounded-lg inline-block mb-4`}>
        <Icon className={`h-6 w-6 ${iconColor}`} />
      </div>
      <h3 className="text-xl font-semibold mb-3">{title}</h3>
      <p className="text-gray-600 mb-4 flex-grow">{description}</p>
      <a href={link}>
        <Button variant="ghost" className="p-0 h-auto text-brand-purple hover:text-brand-purple/80 hover:bg-transparent group">
          Learn more <ArrowRight className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-transform" />
        </Button>
      </a>
    </div>
  );
};

export default FeatureCard;
