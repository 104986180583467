import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes, FaPlay } from 'react-icons/fa';
import { getJourneyInterviews, getJourneys, getJourneyStep, updateJourneyStep, addJourneyStep, addOutputType, anthropicTest } from '../../utils/api/admin';
import './OutputType.css';
import OutputTypeRenderer from './Components/OutputTypeRenderer';
import Typewriter from './Components/TypeWriter';
import * as FaIcons from 'react-icons/fa';

function OutputTypeAdd({journey_step_id, setShowModal, loadData}) {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [name, setName] = useState('');
  const [journeys, setJourneys] = useState([]);
  const [selectedJourneyId, setSelectedJourneyId] = useState(null);
  const [description, setDescription] = useState('');
  const [assistantId, setAssistantId] = useState('');
  const [minDuration, setMinDuration] = useState('0');
  const [maxDuration, setMaxDuration] = useState('0');
  const [icon, setIcon] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [orderId, setOrderId] = useState('');
  const [outputPrompt, setOutputPrompt] = useState('');
  const [structuredDataSchema, setStructuredDataSchema] = useState('');
  const [outputTemplate, setOutputTemplate] = useState('');
  const [testData, setTestData] = useState([]);
  const [outputStyle, setOutputStyle] = useState('');
  const [status, setStatus] = useState('Draft');
  const [isModifying, setIsModifying] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [generatedOutput, setGeneratedOutput] = useState('');
  const [fontAwesomeIcon, setFontAwesomeIcon] = useState('');
  const [isFree, setIsFree] = useState(false);
 // const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  const IconDisplay = ({ iconName }) => {
    if (iconName == null || iconName.length == 0){
      return <p>No icon.</p>
    }
  
    // Dynamically get the icon component from the FaIcons object
    const IconComponent = FaIcons[iconName];
  
    // If the icon is found, render it
    if (IconComponent) {
      return <IconComponent />;
    }
  
    // Fallback if icon is not found
    return <p>Icon not found</p>;
  };

  useEffect(() => {
    async function loadJourneys(){
      let res = await getJourneys();

      if (res.success){
        setJourneys(res.data);
      }
    }

    loadJourneys();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleUpdate(){
    let res = await addOutputType(name, description, icon, outputPrompt, structuredDataSchema, outputTemplate, outputStyle, testData, status, isFree);

    if (res.success){
      alert('Saved');
      loadData();
      setShowModal(false);
    }
  }

  async function handleRefresh(){
    setIsModifying(false);
  }

  async function handleTemplateChange(val){
    setIsModifying(true);
    setOutputTemplate(val);
  }

  async function handleAnthropicTest(){
    setSubmitting(true);
    setGeneratedOutput('');
    let res = await anthropicTest(transcript, outputPrompt, structuredDataSchema);

    if (res.success){
      setGeneratedOutput(JSON.stringify(res.data));
      setSubmitting(false);
    }

    else{
      setSubmitting(false);
      setGeneratedOutput(res.message);
    }
  }

  async function handleIconChange(val){
    setFontAwesomeIcon(val);
  }

  return (
    <>
      <Helmet>
        <title>Journeys</title>
      </Helmet>

         <div>
          <div>

            <div className='form-group'>
              <label>Name</label>
              <input type='text' className='input' value={name} onChange={(e) => setName(e.target.value)} />
            </div>

            {/* <div className='form-group'>
              <div>
                <img style={{width: '100%'}} src={`${imageUrl}`} />
              </div>
            </div>

            <div className='form-group'>
              <label>Image URL</label>
              <input type='text' className='input' value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
            </div> */}

<div className='outputtype__editor-wrapper'>
              <div>
              <div className='form-group'>
                  <label>Output Prompt</label>
                  <textarea type='text' className='input' value={outputPrompt} onChange={(e) => setOutputPrompt(e.target.value)}></textarea>
                </div>

                <div className='form-group'>
                  <label>Sample Transcript</label>
                  <textarea type='text' className='input' value={transcript} onChange={(e) => setTranscript(e.target.value)}></textarea>
                </div>

                <div className='form-group'>
                  <label>Structured Data Schema</label>
                  <textarea type='text' className='input' value={structuredDataSchema} onChange={(e) => setStructuredDataSchema(e.target.value)}></textarea>
                </div>

                <div>
                  {submitting == false && (
                    <span className='btn' onClick={() => handleAnthropicTest()}>
                      Run <FaPlay />
                    </span>
                  )}

                  {submitting == true && (
                    <Spinner />
                  )}
                </div>
              </div>

              <div className='outputtype__editor-preview'>
                <div><strong>Response</strong></div>

                <div className='mt-10'>
                  <div className='outputtype__editor-response'>
                    <Typewriter text={generatedOutput} speed={10} />
                  </div>
                </div>
              </div>
            </div>

            <div className='outputtype__editor-wrapper mt-20'>
              <div> 
                <div className='form-group'>
                  <label>Output Template</label>
                  <textarea type='text' className='input' onChange={(e) => handleTemplateChange(e.target.value)} value={outputTemplate}></textarea>
                </div>

                <div className='form-group'>
                  <label>Test Data</label>
                  <textarea type='text' className='input' onChange={(e) => {
                    setIsModifying(true);
                    setTestData(e.target.value);
                  }} value={testData}></textarea>
                </div>

                <div className='form-group'>
                  <label>Output Style</label>
                  <textarea type='text' className='input' value={outputStyle} onChange={(e) => setOutputStyle(e.target.value)}></textarea>
                </div>

                <div className='mt-10'>
                  <button className='btn' onClick={() => handleRefresh()}>Refresh preview</button>
                </div>
              </div>

              <div className='outputtype__editor-preview'>
                <div><strong>Preview</strong></div>

                <div className='mt-10'>
                  <OutputTypeRenderer isModifying={isModifying} template={outputTemplate} aiData={testData} template_style={outputStyle} />
                </div>
              </div>
            </div>

            <div className='form-group'>
              <label>Description</label>
              <textarea type='text' className='input' value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
            </div>

            <div className='form-group'>
                <label>Is Free</label>
                <input type='checkbox' checked={isFree} onChange={(e) => setIsFree(e.target.checked)} />
            </div>
     
            <div className='form-group'>
              <label>Status</label>
              <select className='input select' value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value='Draft'>Draft</option>
                <option value='Published'>Published</option>
                <option value='Paused'>Paused</option>
              </select>
            </div>

            <div className='form-group'>
              <button className='btn' onClick={() => handleUpdate()}>Save</button>
            </div>
          </div>
       </div>

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default OutputTypeAdd;