
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const VoiceoverAudition = () => {
  return (
    <UseCaseTemplate 
      title="Voiceover Auditions"
      prompt="Create 5 prompts for voiceover artists to demonstrate range, clarity, and tone for a commercial project."
      questions={[
        "Please read this promotional script in an enthusiastic, upbeat tone suitable for a mainstream audience.",
        "Now deliver the same message in a more sophisticated, premium brand style.",
        "Please improvise a 30-second introduction for a tech product, emphasizing innovation and reliability.",
        "Demonstrate how you would voice a character who is warm and trustworthy for an educational series.",
        "Read this disclaimer text at a conversational pace while maintaining clarity and professionalism."
      ]}
      icon="🎙️"
      description="Hearing voice quality directly. Collect samples that demonstrate range, tone control, and ability to deliver specific types of content."
      isPersonal={false}
    />
  );
};

export default VoiceoverAudition;
