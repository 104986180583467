
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const SalesRole = () => {
  return (
    <UseCaseTemplate 
      title="Sales Role Interview"
      prompt="Create 5 scenario-based questions to assess a sales representative's verbal charisma and ability to handle objections."
      questions={[
        "Imagine I'm a potential customer showing interest but concerned about price. How would you address my objections?",
        "How would you introduce our product to a client who has never heard of our company before?",
        "Describe how you'd recover and maintain rapport after a client points out a significant flaw in your proposal.",
        "Walk me through how you would close a sale that has been in the pipeline for months with a client who keeps postponing decisions.",
        "How would you respond to a client who says they're happy with a competitor's product and sees no reason to switch?"
      ]}
      icon="💼"
      description="Testing verbal charisma and spontaneity. Voice responses reveal persuasiveness, ability to think on their feet, and how they build rapport with potential clients."
      isPersonal={false}
    />
  );
};

export default SalesRole;
