import {useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import congrats from '../../assets/design/dashboard/congrats.png';
import { getBuyerObjections, getSellerObjections, addBuyerFeedback, addSellerFeedback, getBuyerDemoFromRef, getDemoQualifierQuestions, answerQualifierQuestion } from '../../utils/api';
import imgScreenshot from '../../assets/components/3-1.png';
function SectionRightImage() {


return (
  
<div class="overflow-hidden bg-white py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
      <div class="lg:pr-8 lg:pt-4">
        <div class="lg:max-w-lg">
          <h2 class="text-base/7 font-semibold text-indigo-600">Storytelling</h2>
          <p class="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Empower Your Storytelling Journey</p>
          <p class="mt-6 text-lg/8 text-gray-600">Craft authentic and engaging narratives with our AI-powered interviewing assistant. Effortlessly generate text outputs tailored to your unique voice and goals.</p>
          <dl class="mt-10 max-w-xl space-y-8 text-base/7 text-gray-600 lg:max-w-none">
            <div class="relative pl-9">
              <dt class="inline font-semibold text-gray-900">
              <svg class="absolute left-1 top-1 size-5 text-indigo-600" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>


                Take Interviews in Your Own Time.
              </dt>
              <dd class="inline ml-1">No need to rush—our AI interviewing assistant works on your schedule. Pause, resume, and conduct interviews whenever it suits you.</dd>
            </div>

            <div class="relative pl-9">
              <dt class="inline font-semibold text-gray-900">
              <svg class="absolute left-1 top-1 size-5 text-indigo-600" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9.53 16.122a3 3 0 0 0-5.78 1.128 2.25 2.25 0 0 1-2.4 2.245 4.5 4.5 0 0 0 8.4-2.245c0-.399-.078-.78-.22-1.128Zm0 0a15.998 15.998 0 0 0 3.388-1.62m-5.043-.025a15.994 15.994 0 0 1 1.622-3.395m3.42 3.42a15.995 15.995 0 0 0 4.764-4.648l3.876-5.814a1.151 1.151 0 0 0-1.597-1.597L14.146 6.32a15.996 15.996 0 0 0-4.649 4.763m3.42 3.42a6.776 6.776 0 0 0-3.42-3.42" />
</svg>

Personalized and Flexible Interview Process.
              </dt>
              
              <dd class="inline ml-1">Our AI interviewing assistant adapts to your style, asking tailored questions designed to uncover your unique story. Take your time to respond, pause when needed, and return whenever you're ready.</dd>
            </div>

            <div class="relative pl-9">
              <dt class="inline font-semibold text-gray-900">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute left-1 top-1 size-5 text-indigo-600">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
</svg>



Transformative Interview Experience.
              </dt>
              <dd class="inline ml-1">Dive deeper into your narrative with intelligent follow-ups and insightful prompts. The assistant helps you explore ideas and perspectives you may not have considered, making every interview session meaningful and engaging.</dd>
            </div>
          </dl>
        </div>
      </div>

      <img src={imgScreenshot} alt="Product screenshot" class="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" style={{width: '100%'}} />
    </div>
  </div>
</div>
)
}

export default SectionRightImage;