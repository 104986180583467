import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlug, FaPlus, FaTimes } from 'react-icons/fa';
import './UploadTranscript.css';
import { createUserTranscript, getUserTranscripts } from '../../utils/api';
import Spinner from '../../components/layout/Spinner';
import useSubscriberStatus from '../../hooks/useSubscriberStatus';
import useLicenseStatus from '../../hooks/useLicenseStatus';
import { Link } from 'react-router-dom';

function UploadTranscript() {
  const [email, setEmail] = useState('');
  const [showUpload, setShowUpload] = useState(false);
  const [name, setName] = useState('');
  const [transcript, setTranscript] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [transcripts, setTranscripts] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { subscriberLoading, subscriberData } = useSubscriberStatus();
  const { licenseLoading, licenseData, licenseError, refetch: fetchLicenseStatus } = useLicenseStatus();
  const [hasAccess, setHasAccess] = useState(false);
  const [accessLoaded, setAccessLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {

    if (subscriberLoading == false && licenseLoading == false && licenseData != null && subscriberData != null){
      if (subscriberData.length > 0 && subscriberData[0].name == 'Unlimited'){
        setHasAccess(true);
      }

      if (licenseData.length > 0 && licenseData[0].type == 'Pro'){
        setHasAccess(true);
      }

      if (licenseData.length > 0 && licenseData[0].type == 'Unlimited'){
        setHasAccess(true);
      }


      setAccessLoaded(true);
    }

  }, [licenseData, subscriberData, licenseLoading, subscriberLoading]);

  async function loadTranscripts(){
    let res = await getUserTranscripts();

    if (res.success){
      setTranscripts(res.data);
      setLoaded(true);
    }
  }

  useEffect(() => {
    async function loadTranscripts(){
      let res = await getUserTranscripts();

      if (res.success){
        setTranscripts(res.data);
        setLoaded(true);
      }
    }

    loadTranscripts();
  }, []);

  async function handleUpload(){
    setSubmitting(true);
    let res = await createUserTranscript(name, transcript);

    if (res.success){
      setSubmitting(false);
     // alert('Transcript uploaded successfully');
      setShowUpload(false);
      loadTranscripts();

      setName('');
      setTranscript('');
    }
  }

  async function handleClick(reference_id){
    navigate('/transcript/studio/' + reference_id + '/4');
  }

  return (
   <div className='uploadtranscript'>
    <div className='flex align-center justify-between'>
      <h2 class="flex items-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
      <svg style={{height: '20px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z"/></svg>
        <span className='ml-10'>Upload your own content</span></h2>

      {accessLoaded && hasAccess == true && (
        <>
        <button className='py-3 px-4 inline-flex items-center gap-x-2 text-sm font-bold rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700' onClick={() => setShowUpload(true)}><FaPlus /> Upload</button>
        </>
      )}

      {/* {subscriberLoading == false && subscriberData && subscriberData.length > 0 && subscriberData[0].name == 'Unlimited' && (
        <div>
          <button className='py-3 px-4 inline-flex items-center gap-x-2 text-sm font-bold rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700' onClick={() => setShowUpload(true)}><FaPlus /> Upload</button>
        </div>
      )}

      {subscriberLoading == false && subscriberData && subscriberData.length == 0 && licenseLoading == false && licenseData != null && licenseData.length > 0 && licenseData[0].type == 'Pro' && (
        <div>
          <button className='py-3 px-4 inline-flex items-center gap-x-2 text-sm font-bold rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700' onClick={() => setShowUpload(true)}><FaPlus /> Upload</button>
        </div>
      )} */}
    </div>


        {accessLoaded && hasAccess == false && (
          <>
           <div className='mt-20'>
          <p>Upgrade your account in order to upload your own content.</p>

          <div className='mt-10'>
          <Link class="rounded-md bg-indigo-600 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" to="/upgrade">UPGRADE</Link>
            </div>
        </div>
          </>
        )}

        {accessLoaded && hasAccess == true && (
          <>
            <div className='mt-20'>
        <h2 className='text-2xl font-bold'>Your transcripts {`(${transcripts.length})`}</h2>

        <div className='mt-10 masonry'>
          {transcripts.map((transcript, index) => (
            <div key={index} className='transcript masonry-item' onClick={() => handleClick(transcript.reference_id)}>
              <div className='text-sm mb-2'>{new Date(transcript.date_created).toLocaleString()}</div>

              <h1 className='uploadtranscript__name'>{transcript.name}</h1>
              <p className='uploadtranscript__transcript'>{transcript.transcript}</p>

              <p className='mt-4 truncate inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-800/30 dark:text-blue-500 absolute bottom-4 right-4'>
                {transcript.content_count} items
              </p>
            </div>
          ))}
        </div>

        {transcripts.length == 0 && (
          <div className='mt-10'>
            <p className='text-sm'>You have not uploaded any transcripts yet.</p>
          </div>
          )}
      </div>
          </>
        )}

    {/* {loaded && subscriberLoading == false && subscriberData && subscriberData.length == 0 && (
      <>
        <div className='mt-20'>
          <p>Upgrade your account in order to upload your own transcripts.</p>

          <div className='mt-10'>
          <Link class="rounded-md bg-indigo-600 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" to="/upgrade">UPGRADE</Link>
            </div>
        </div>
      </>
    )}

    {loaded && subscriberLoading == false && subscriberData && subscriberData.length > 0 && subscriberData[0].name == 'Pro'&& (
      <>
      <div className='mt-20'>
          <p className='text-sm'>Transcript uploads are only available for <span>Unlimited</span> users currently.</p>

          <div className='mt-10'>
          <Link class="rounded-md bg-indigo-600 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" to="/upgrade">UPGRADE</Link>
            </div>
        </div>
      </>
    )}

    {(loaded && subscriberLoading == false && subscriberData && subscriberData.length > 0 && subscriberData[0].name == 'Unlimited' || (licenseLoading == false && licenseData != null && licenseData.length > 0 && licenseData[0].type == 'Pro')) && (
      <div className='mt-20'>
        <h2 className='text-2xl font-bold'>Your transcripts {`(${transcripts.length})`}</h2>

        <div className='mt-10 masonry'>
          {transcripts.map((transcript, index) => (
            <div key={index} className='transcript masonry-item' onClick={() => handleClick(transcript.reference_id)}>
              <div className='text-sm mb-2'>{new Date(transcript.date_created).toLocaleString()}</div>

              <h1 className='uploadtranscript__name'>{transcript.name}</h1>
              <p className='uploadtranscript__transcript'>{transcript.transcript}</p>

              <p className='mt-4 truncate inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-800/30 dark:text-blue-500 absolute bottom-4 right-4'>
                {transcript.content_count} items
              </p>
            </div>
          ))}
        </div>

        {transcripts.length == 0 && (
          <div className='mt-10'>
            <p className='text-sm'>You have not uploaded any transcripts yet.</p>
          </div>
          )}
      </div>
    )} */}

    {showUpload && (
      <>
        <div className='overlay'></div>
        <div className='modal uploadtranscript__modal'>
          <div className='modal__close' onClick={() => setShowUpload(false)}>
            <FaTimes />
          </div>

          <h2 className='flex items-center modal__h2'><svg style={{height: '20px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128l-368 0zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39L296 392c0 13.3 10.7 24 24 24s24-10.7 24-24l0-134.1 39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"/></svg> <span className='ml-10'>Upload your transcript</span></h2>

          <div className='mt-10'>
            <div>
              <label>Name</label>

              <div>
                <input type='text' value={name} onChange={(e) => setName(e.target.value)} className='input' placeholder='Enter name' />
              </div>
            </div>

            <div className='mt-10'>
              <label>Transcript</label>

              <div>
                <textarea className='input' value={transcript} onChange={(e) => setTranscript(e.target.value)} placeholder='Enter transcript'></textarea>
              </div>
            </div>

            <div className='mt-30'>
              {submitting == false && (
                <button className='btn btn--primary' onClick={() => handleUpload()}>Upload</button>
              )}

              {submitting && (
                <>
                  <Spinner />  
                </>
              )}
            </div>
          </div>
          </div>
      </>
    )}
   </div>
  );
}

export default UploadTranscript;
