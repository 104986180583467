import { useState, useEffect } from 'react';
import React from "react";
import { createRoot } from 'react-dom/client'
import { useLocation } from 'react-router-dom';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { buyerConfirmsDemo, getJourneySteps, updateUser, getOnboardingStatus, getPreferredName, getCompletedInterviews, getCallReport, getContentStudioItems, getCompleteInterview, updateLikeStatus, generateContent, deleteContent, testPrompt, generateAnthropicContentForTranscript, getInterviewModifiers, addInterviewModifier, removeInterviewModifier, getOutputTypesForInterview, getOutputTypeById, getAllOutputTypes, getAvailableOutputTypes, getUserTranscript, deleteInterview, deleteContentItem, getCustomUserTranscript, getTranscriptContentItems, deleteUserTranscript, updateUserTranscript } from '../../utils/api';
import { FaBriefcase, FaCalendarAlt, FaCaretDown, FaCaretLeft, FaCheckCircle, FaCloud, FaCopy, FaHeart, FaInfoCircle, FaLinkedin, FaLock, FaMagic, FaMicrophoneAlt, FaRegCalendarCheck, FaRegCopy, FaRegEdit, FaRegHeart, FaRegLightbulb, FaRegThumbsDown, FaRegThumbsUp, FaSoundcloud, FaTimes, FaTrashAlt, FaTrophy, FaUnlockAlt } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import Markdown from 'react-markdown';
import * as FaIcons from 'react-icons/fa';
import useSubscriberStatus from '../../hooks/useSubscriberStatus';
import useUsage from '../../hooks/useUsage';
import ScrollToTop from '../../components/layout/ScrollToTop';
import useLicenseStatus from '../../hooks/useLicenseStatus';

const TranscriptStudio = () => {
  const [interview, setInterview] = useState(null);
  const [items, setItems] = useState([]);
  const [journeyStep, setJourneyStep] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [loadedItems, setLoadedItems] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [status, setStatus] = useState('');
  const [modifyPrompt, setModifyPrompt] = useState('');
  const [hasContent, setHasContent] = useState(false);
  const [modifiers, setModifiers] = useState([]);
  const [outputTypes, setOutputTypes] = useState([]);
  const [loadedOutputTypes, setLoadedOutputTypes] = useState(false);
  const [isModifying, setIsModifying] = useState(false);
  const [selectedOutputType, setSelectedOutputType] = useState(null);
  const [selectedFilterType, setSelectedFilterType] = useState('');
  const [isRendered, setIsRendered] = useState(false);
  const [showTypeDropdown, setShowTypeDropdown] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedContentId, setSelectedContentId] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);
  const [showTranscript, setShowTranscript] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [showItemDelete, setShowItemDelete] = useState(false);
  const [uniqueTypes, setUniqueTypes] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [userTranscript, setUserTranscript] = useState(null);
  const [loadedUserTranscript, setLoadedUserTranscript] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [nameEdit, setNameEdit] = useState('');
  const [transcriptEdit, setTranscriptEdit] = useState('');
  const [submitting, setSubmitting] = useState(false);

  let { id, outputid } = useParams();
  const navigate = useNavigate();
  const { subscriberStatus, subscriberLoading, subscriberData } = useSubscriberStatus();
  const { usageStatus, usageLoading, usageData, usageFetch } = useUsage();
  const { licenseStatus, licenseLoading, licenseData, licenseError, refetch: fetchLicenseStatus } = useLicenseStatus();

  const [hasAccess, setHasAccess] = useState(false);
  const [accessLoaded, setAccessLoaded] = useState(false);
  const [planName, setPlanName] = useState('');

  useEffect(() => {

    if (subscriberLoading == false && licenseLoading == false && licenseData != null && subscriberData != null) {
      let _plan = '';

      if (subscriberData.length > 0) {
        setHasAccess(true);
        setPlanName(subscriberData[0].name);
        _plan = subscriberData[0].name;
      }

      if (licenseData.length > 0) {
        setHasAccess(true);
        setPlanName(licenseData[0].type);
        _plan = licenseData[0].type;
      }

      setAccessLoaded(true);
    }

  }, [licenseData, subscriberData, licenseLoading, subscriberLoading]);

  const IconDisplay = ({ iconName }) => {
    if (iconName == null || iconName.length == 0) {
      return <p>No icon.</p>
    }

    // Dynamically get the icon component from the FaIcons object
    const IconComponent = FaIcons[iconName];

    // If the icon is found, render it
    if (IconComponent) {
      return <IconComponent />;
    }

    // Fallback if icon is not found
    return <p>Icon not found</p>;
  };

  async function loadItems() {
    let res = await getTranscriptContentItems(id, outputid);

    if (res.success) {
      setItems(res.data);
      setLoadedItems(true);
      setLoaded(true);

      if (res.data.length > 0) {
        setHasContent(true);
        const uniqueTypes = [...new Set(res.data.map(item => item.output_type))];
        setUniqueTypes(uniqueTypes);
      }
    }
  }

  async function loadItemsByType(output_type_id) {
    let res = await getTranscriptContentItems(id, output_type_id);

    if (res.success) {
      setItems(res.data);
      setLoadedItems(true);

      if (res.data.length > 0) {
        setHasContent(true);
      }
    }
  }

  async function loadUserTranscript() {
    let res = await getCustomUserTranscript(id);

    if (res.success && res.data != null) {
      setUserTranscript(res.data);
      setLoadedUserTranscript(true);
      setTranscriptEdit(res.data.transcript);
      setNameEdit(res.data.name);

      console.log('transcript', res.data);
    }

    setLoadedUserTranscript(true);
  }

  useEffect(() => {
    async function loadOutputTypes() {
      if (accessLoaded == true && hasAccess == false) {
        let res = await getAvailableOutputTypes();

        if (res.success) {
          setOutputTypes(res.data);
        }
      }

      else if (accessLoaded == true && hasAccess == true) {
        let res = await getAllOutputTypes();

        if (res.success) {
          setOutputTypes(res.data);
        }
      }
    }

    loadOutputTypes();


  }, [accessLoaded, hasAccess]);

  useEffect(() => {
    async function loadUserTranscript() {
      let res = await getCustomUserTranscript(id);

      if (res.success && res.data != null) {
        setUserTranscript(res.data);
        setLoadedUserTranscript(true);
        setTranscriptEdit(res.data.transcript);
        setNameEdit(res.data.name);

        console.log('transcript', res.data);
      }

      setLoadedUserTranscript(true);
    }

    async function loadOutputType() {
      let res = await getOutputTypeById(outputid);

      if (res.success) {
        setLoadedOutputTypes(true);
        setSelectedOutputType(res.data);
      }
    }

    async function loadInterview() {
      let res = await getCompleteInterview(id);
      console.log('res', res);
      if (res.success && res.data != null) {
        setInterview(res.data);
        // if (res.data.content_generated == true)
        //  setShowBtn(false);
        // else
        setShowBtn(true);
        setLoaded(true);
      }

      else {
        navigate('/transcript/upload');
      }
    }

    async function loadItems() {
      let res = await getTranscriptContentItems(id, outputid);

      if (res.success) {
        setItems(res.data);
        setLoadedItems(true);
        setLoaded(true);

        console.log('items', res.data);

        if (res.data.length > 0) {
          setHasContent(true);

          const uniqueTypes = [...new Set(res.data.map(item => item.output_type))];
          setUniqueTypes(uniqueTypes);
        }
      }

      else {
        console.log('no items');
      }
    }

    loadUserTranscript();
    //loadInterview();
    //loadCallReport();
    loadItems();
    // loadModifiers();
    // loadOutputTypes();
    //setSelectedOutputType(outputid);
    loadOutputType();
  }, []);

  async function handleGenerate() {
    setIsGenerating(true);

    let res = await generateAnthropicContentForTranscript(id, selectedOutputType.output_type_id);

    if (res.success) {
      setTimeout(function () {
        loadItemsByType(selectedOutputType.output_type_id);
        setIsGenerating(false);
        usageFetch();
      }, 3000)

      setShowBtn(false);
    }
  }

  async function handleCopy(text, id) {
    navigator.clipboard.writeText(text)
      .then(() => {
        document.getElementById('btncopy_' + id).innerHTML = 'Copied!';
        setTimeout(function (e) {
          document.getElementById('btncopy_' + id).innerHTML = `Copy <i class='fa fa-copy'></i>`;
        }, [1000])
        // alert("Text copied to clipboard!");
      })
      .catch(err => {
        //   alert("Failed to copy text.");
      });
  }

  async function handleLike(id) {
    let res = await updateLikeStatus(id, true);
    loadItems();
  }

  async function handleUnlike(id) {
    let res = await updateLikeStatus(id, false);
    loadItems();
  }

  async function handleDelete(id) {
    let res = await deleteContent(id);

    if (res.success) {
      loadItems();
    }
  }

  async function handleDeleteItem(id) {
    setSelectedItemId(id);
    setShowItemDelete(true);
  }

  function RenderWithLineBreaks({ text }) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: text.replace(/\n/g, '<br />'),
        }}
      />
    );
  }

  async function handleAddModifier() {
    let res = await addInterviewModifier(id, modifyPrompt);

    if (res.success) {
      //  loadModifiers();
    }
  }

  async function handleChangeType(val) {
    setSelectedOutputType(val);
    setShowTypeDropdown(false);

    //await loadItemsByType(val.output_type_id);
  }

  async function handleContentClick(id, item) {
    setSelectedContentId(id);
    setSelectedContent(item);
    setShowDetails(true);

    navigate(`/content/editor/${id}`);
  }

  async function handleShowTranscript() {
    setShowTranscript(true);
  }

  async function handleErase() {
    setShowDelete(true);
  }

  async function confirmDelete() {
    let res = await deleteUserTranscript(id);

    if (res.success) {
      navigate('/transcript/upload');
    }
  }

  async function handleButtonClick(type) {
    setSelectedFilterType(type);
  }

  async function handleConfirmItemDelete() {
    let res = await deleteContentItem(selectedItemId);

    if (res.success) {
      let newContent = items.filter(x => x.journey_interview_content_id != selectedItemId);
      setItems(newContent);
      setShowItemDelete(false);
      usageFetch();
    }
  }

  async function handleEdit() {
    setShowEdit(true);
  }

  async function handleUpdate() {
    let res = await updateUserTranscript(id, nameEdit, transcriptEdit);
    setSubmitting(true);
    if (res.success) {
      setShowEdit(false);
      setSubmitting(false);
      setTranscript(transcriptEdit);
      loadUserTranscript();
    }
  }

  return (
    <div className="contentstudioitem relative">
      <ScrollToTop />
      <div className="mb-20">
        <button onClick={() => navigate(-1)} type="button" className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-bold rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
          <FaIcons.FaAngleLeft /> Back
        </button>

        <button onClick={() => navigate('/transcript/upload')} type="button" className="ml-2 py-3 px-4 inline-flex items-center gap-x-2 text-sm font-bold rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
          <FaIcons.FaRegNewspaper /> All Transcripts
        </button>

        <button onClick={() => handleErase()} type="button" className="ml-2 py-3 px-4 inline-flex items-center gap-x-2 text-sm font-bold rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
          <FaIcons.FaTrashAlt /> Erase This Transcript
        </button>

        <button onClick={() => handleEdit()} type="button" className="ml-2 py-3 px-4 inline-flex items-center gap-x-2 text-sm font-bold rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
          <FaIcons.FaRegEdit /> Edit
        </button>
      </div>

      <div>
        {loadedUserTranscript == true && (
          <div className='text-center'>
            <div className='contentstudioitem__step-name'>
              {userTranscript.name}
            </div>

            <div className='contentstudioitem__step-description'>
              {userTranscript.description}
            </div>

            <div className="contentstudioitem__step-description">
              <strong><FaIcons.FaCloudUploadAlt /> Upload date:</strong> {new Date(userTranscript.date_created).toLocaleDateString()}
            </div>

            <div>
              <button onClick={() => handleShowTranscript()} className='inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 focus:outline-none focus:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:text-blue-400'>View transcript</button>
            </div>

           {accessLoaded == true && hasAccess == false && usageLoading == false && (
                        <div
                          className={`absolute top-[10px] right-[10px] mt-20 mt-4 truncate inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium ${usageData.total_content > 10
                              ? "bg-red-100 text-red-800 dark:bg-red-800/30 dark:text-red-500"
                              : "bg-blue-100 text-blue-800 dark:bg-blue-800/30 dark:text-blue-500"
                            }`}
                        >
                          <strong>{usageData.total_content} / 10 Items</strong> <span><FaInfoCircle /></span>
                        </div>
                      )}
          
                      {accessLoaded == true && hasAccess == true && usageLoading == false && (
                        <div
                          className={`absolute top-[10px] right-[10px] mt-20 mt-4 truncate inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium`}
                        >
                          <strong>{usageData.total_content}</strong>
          
                          <>
                            {planName == 'Pro' && (
                              <span className='text-xs font-bold'>
                                / 150 items
                              </span>
                            )}
          
                            {planName == 'Unlimited' && (
                              <span className='text-xs/5 text-gray-500 font-normal'>
                                / unlimited
                              </span>
                            )}
                          </>
                          <span><FaInfoCircle /></span>
                        </div>
          
                      )}
          </div>
        )}

        <div style={{ position: 'relative', textAlign: 'center', marginTop: '20px' }}>
          {loadedOutputTypes == true && (
            <>
              <span onClick={() => setShowTypeDropdown(!showTypeDropdown)} className='contentstudioitem__output-type-label'>{selectedOutputType.name} <FaCaretDown /></span>

              {showTypeDropdown && (
                <div className='contentstudioitem__output-type-dropdown'>
                  {outputTypes.map((item, index) => (
                    <div className='text-left' key={index} style={{
                      textAlign: 'left',
                      display: 'flex',
                      alignItems: 'center'
                    }} onClick={() => handleChangeType(item)}>
                      <IconDisplay iconName={item.fa_icon}></IconDisplay>
                      <span className='ml-10'>{item.name}</span>

                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>

        {loadedUserTranscript == true && isGenerating == false && (
          <div className="center contentstudioitem__btn-generate-wrapper">
            {/* {hasContent == true && (
            <input className='input' placeholder="How would you like to modify this content? Leave blank to regenerate content without modifications." value={modifyPrompt} onChange={(e) => setModifyPrompt(e.target.value)} />
            )} */}

            {/* No susbscriptions found and user has reached limit */}
            {accessLoaded == true && hasAccess == false && usageLoading == false && usageData != null && usageData.total_content >= 10 && (
              <div className="center mt-20">
                <strong>You have reached the maximum number of content items.</strong>

                <div className="mt-10">
                  <Link to="/pricing" className="rounded-md text-white bg-indigo-600 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Upgrade</Link>
                </div>
              </div>
            )}

            {accessLoaded == true && hasAccess == false && usageLoading == false && usageData != null && usageData.total_content < 10 && (
              <div className="center mt-20">

                <div>
                  <button className='inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 hover:from-violet-600 hover:to-blue-600 border border-transparent text-white text-sm font-medium rounded-md focus:outline-none focus:from-violet-600 focus:to-blue-600 py-3 px-4' onClick={() => handleGenerate()}><FaMagic style={{ fontSize: '22px' }} /> <span className='ml-10'>Generate Content</span></button>
                </div>
              </div>
            )}

            {accessLoaded == true && hasAccess == true && planName == 'Pro' && usageLoading == false && usageData != null && usageData.total_content >= 150 && (
              <div className="center mt-20">
                <strong>You have reached the maximum number of content items.</strong>

                <div className="mt-10">
                  <Link to="/pricing" className="rounded-md text-white bg-indigo-600 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Upgrade</Link>
                </div>
              </div>
            )}

            {accessLoaded == true && hasAccess == true && planName == 'Unlimited' && (
              <button className='inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 hover:from-violet-600 hover:to-blue-600 border border-transparent text-white text-sm font-medium rounded-md focus:outline-none focus:from-violet-600 focus:to-blue-600 py-3 px-4' onClick={() => handleGenerate()}><FaMagic style={{ fontSize: '22px' }} /> <span className='ml-10'>Generate Content</span></button>
            )}

            {/* Subscriptions found */}
            {/* {subscriberLoading == false && subscriberData != null && subscriberData.length > 0 && subscriberData[0].name == 'Pro' && usageLoading == false && usageData != null && usageData.total_content < 150 && (
              <button className='inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 hover:from-violet-600 hover:to-blue-600 border border-transparent text-white text-sm font-medium rounded-md focus:outline-none focus:from-violet-600 focus:to-blue-600 py-3 px-4' onClick={() => handleGenerate()}><FaMagic style={{ fontSize: '22px' }} /> <span className='ml-10'>Generate Content</span></button>
            )} */}

            {/* {subscriberLoading == false && subscriberData != null && subscriberData.length > 0 && subscriberData[0].name == 'Pro' && usageLoading == false && usageData != null && usageData.total_content >= 150 && (
              <div className="center mt-20">
                <strong>You have reached the maximum number of content items.</strong>

                <div className="mt-10">
                  <Link to="/pricing" className="rounded-md text-white bg-indigo-600 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Upgrade</Link>
                </div>
              </div>
            )} */}

            {/* {subscriberLoading == false && subscriberData != null && subscriberData.length > 0 && subscriberData[0].name == 'Unlimited' && (
              <button className='inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 hover:from-violet-600 hover:to-blue-600 border border-transparent text-white text-sm font-medium rounded-md focus:outline-none focus:from-violet-600 focus:to-blue-600 py-3 px-4' onClick={() => handleGenerate()}><FaMagic style={{ fontSize: '22px' }} /> <span className='ml-10'>Generate Content</span></button>
            )} */}
          </div>
        )}

        {loaded == true && isGenerating == false && showBtn == true && status != 'ended' && (
          <div className="center" style={{ marginTop: '50px' }}>
            <i>CONTENT IS STILL BEING GENERATED. PLEASE WAIT 1-2 MINUTES.</i>
          </div>
        )}

        {loaded == true && isGenerating == true && (
          <div className="center contentstudioitem__btn-generate-wrapper">
            <Spinner /> <span className='ml-10'><i>Generating Content...</i></span>
          </div>
        )}

        <div>
          {loadedItems == false && (
            <div className="center">
              <Spinner />
            </div>
          )}

          {loadedItems == true && items.length == 0 && (
            <div className='mt-30 center'>
              <i>No content generated yet...</i>
            </div>
          )}

          {loadedItems == true && items.length > 0 && (
            <>
              {selectedOutputType == null && loaded && items.length > 0 && (
                <Spinner />
              )}


              {loaded == true && items.length > 0 && (
                <FadeIn delay={300} duration={300}>
                  <div className='mt-2'>
                    <button
                      key={'all'}
                      onClick={() => handleButtonClick('')}
                      className={`inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-md text-xs font-medium border border-gray-200  text-gray-800 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white ${selectedFilterType == '' ? 'bg-blue-100' : 'bg-white'}`}
                    >
                      All
                    </button>
                    {uniqueTypes.map((type) => (
                      <button
                        key={type}
                        onClick={() => handleButtonClick(type)}
                        className={`inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-md text-xs font-medium border border-gray-200  text-gray-800 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white ${selectedFilterType == type ? 'bg-blue-100' : 'bg-white'}`}
                      >
                        {type}
                      </button>
                    ))}

                  </div>
                  <div className='masonry'>
                    {items.filter(x => (selectedFilterType == '' || x.output_type == selectedFilterType)).map((item, index) => (
                      <div
                        key={index}
                        style={{
                          margin: '5px',
                          borderBottom: 'solid 1px #ddd',
                          fontSize: '14px'
                        }}
                        className=" masonry-item"
                      >

                        <div className="flex min-w-0 gap-x-4" onClick={() => navigate(`/content/editor/${item.journey_interview_content_id}`)}>
                          <div className="min-w-0 flex-auto">
                            <p className='inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-800 text-white dark:bg-white dark:text-neutral-800'>
                              {item.output_type}
                            </p>

                            <p className='mt-2 text-xs/5 text-gray-500 font-bold mb-4'>
                              Date Generated: {new Date(item.date_created).toLocaleDateString()}
                            </p>

                            <p className="mt-1 text-xs/5 text-gray-500 text-sm">
                              <Markdown>{item.markdown_content}</Markdown>

                            </p>
                          </div>
                        </div>

                        <div className='flex items-center gap-x-2 mt-2'>
                          <button onClick={() => handleDeleteItem(item.journey_interview_content_id)} id="btnCopy1" type="button" className="absolute top-[0px] right-0 bg-white-200 py-2 px-3 inline-flex items-center gap-x-2 text-lg border border-transparent text-gray-500 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"><FaIcons.FaTimesCircle /> </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </FadeIn>
              )}
            </>
          )}
        </div>
      </div>

      {showTranscript && (
        <>
          <div className='overlay'></div>
          <div className='modal' style={{ width: '50%', marginleft: '-25%' }}>
            <h2 className='modal__h2'>Transcript</h2>

            <div className='modal__close' onClick={() => setShowTranscript(false)}>
              <FaTimes />
            </div>

            <p className='mt-20'>
              {userTranscript.transcript}
            </p>
          </div>
        </>
      )}

      {showDelete && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowDelete(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>
              Are you sure you want to delete this content?
            </h2>

            <p className='mt-20'>
              By deleting this content, it will be permanently removed from your account.
            </p>

            <div className='mt-20'>
              <button className='cursor-pointer bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500 mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10' onClick={() => confirmDelete()}>Delete</button>
            </div>
          </div>
        </>
      )}

      {showItemDelete == true && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowItemDelete(false)}>
              <FaIcons.FaTimes />
            </div>
            <div className='modal__content'>
              <h2 className='text-xl font-bold'>Are you sure you want to delete this content?</h2>
              <div className='mt-4'>

                <button onClick={() => {
                  handleConfirmItemDelete()
                }} className='cursor-pointer bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500 mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10'>Confirm</button>
              </div>
            </div>
          </div>
        </>
      )}

      {showEdit == true && (
        <>
          <div className='overlay'></div>

          <div className='modal uploadtranscript__modal'>
            <div className='modal__close' onClick={() => setShowEdit(false)}>
              <FaTimes />
            </div>

            <h2 className='flex items-center modal__h2'><svg style={{ height: '20px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128l-368 0zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39L296 392c0 13.3 10.7 24 24 24s24-10.7 24-24l0-134.1 39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z" /></svg> <span className='ml-10'>Edit your transcript</span></h2>

            <div className='mt-10'>
              <div>
                <label>Name</label>

                <div>
                  <input type='text' value={nameEdit} onChange={(e) => setNameEdit(e.target.value)} className='input' placeholder='Enter name' />
                </div>
              </div>

              <div className='mt-10'>
                <label>Transcript</label>

                <div>
                  <textarea className='input' value={transcriptEdit} onChange={(e) => setTranscriptEdit(e.target.value)} placeholder='Enter transcript'></textarea>
                </div>
              </div>

              <div className='mt-30'>
                {submitting == false && (
                  <button className='btn btn--primary' onClick={() => handleUpdate()}>Update</button>
                )}

                {submitting && (
                  <>
                    <Spinner />
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TranscriptStudio;
