import React from "react";
import { useEffect, useState } from "react";
import { getContentItem, addContentQuery, generateAnthropicRevision, deleteContentVersion, deleteContentItem } from "../../../utils/api";
import { useParams } from "react-router-dom";
import Spinner from "../../../components/layout/Spinner";
import Markdown from "react-markdown";
import './ContentEditor.css';
import { FaAngleRight, FaAngleLeft, FaRegCopy, FaTrashAlt, FaTrash, FaRegEdit } from "react-icons/fa";
import { useRef } from "react";
import useSubscriberStatus from "../../../hooks/useSubscriberStatus";
import useLicenseStatus from "../../../hooks/useLicenseStatus";
import { Link, useNavigate } from "react-router-dom";
import * as FaIcons from 'react-icons/fa';


const ContentEditor = ({ content_id }) => {
  const [content, setContent] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [query, setQuery] = useState('');
  const { id } = useParams();
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [currentVersion, setCurrentVersion] = useState(0);
  const refMessages = useRef(null);
  const navigate = useNavigate();
  const {
    subscriberStatus,
    subscriberLoading,
    subscriberError,
    subscriberData,
    refetchSubscriberStatus,
  } = useSubscriberStatus();
  const { licenseLoading, licenseData } = useLicenseStatus();
  const [hasAccess, setHasAccess] = useState(false);
  const [accessLoaded, setAccessLoaded] = useState(false);
  const [planName, setPlanName] = useState('');

  useEffect(() => {

    if (subscriberLoading == false && licenseLoading == false && licenseData != null && subscriberData != null) {
      if (subscriberData.length > 0) {
        setHasAccess(true);
        setPlanName(subscriberData[0].name);
      }

      if (licenseData.length > 0) {
        setHasAccess(true);
        setPlanName(licenseData[0].type);
      }

      setAccessLoaded(true);
    }

  }, [licenseData, subscriberData, licenseLoading, subscriberLoading]);



  async function loadContent() {
    let res = await getContentItem(content_id != null ? content_id : id);

    if (res.success) {
      setContent(res.data);
      setLoaded(true);

      if (res.data.revisions && res.data.revisions.length > 0) {
        setCurrentVersion(res.data.revisions.length - 1);
      }

      if (res.data.revisions && res.data.revisions.length > 0) {
        setContent((prev) => {
          // Always create a new queries array to avoid mutation
          let newContent = {
            ...prev,
            markdown_content: res.data.revisions[res.data.revisions.length - 1].content_body,
            queries: [...(prev.queries || [])], // Ensure queries remains intact
          };

          return newContent;
        });
      }
    }
  }

  useEffect(() => {
    async function loadContent() {
      let res = await getContentItem(content_id != null ? content_id : id);

      if (res.success) {
        setContent(res.data);
        setLoaded(true);

        if (res.data.revisions && res.data.revisions.length > 0) {
          setCurrentVersion(res.data.revisions.length - 1);
        }

        if (res.data.revisions && res.data.revisions.length > 0) {
          setContent((prev) => {
            // Always create a new queries array to avoid mutation
            let newContent = {
              ...prev,
              markdown_content: res.data.revisions[res.data.revisions.length - 1].content_body,
              queries: [...(prev.queries || [])], // Ensure queries remains intact
            };

            return newContent;
          });
        }
      }
    }

    loadContent();
  }, []);

  useEffect(() => {
    const textarea = document.getElementById('txtInput');
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }, [query]);

  async function handleAddQuery() {
    let res = await addContentQuery(content_id != null ? content_id : id, query);

    if (res.success) {
      setContent((prev) => {
        // Always create a new queries array to avoid mutation
        let newContent = {
          ...prev,
          queries: [...(prev.queries || []), { query }]
        };

        return newContent;
      });
    }

    await handleCreateRevision();
  }

  async function handleCreateRevision() {
    setIsRegenerating(true);
    let res = await generateAnthropicRevision(content_id != null ? content_id : id, query, content.markdown_content);

    if (res.success) {
      //setContent(res.data);
      setQuery('');

      setContent((prev) => {
        // Always create a new queries array to avoid mutation
        let newContent = {
          ...prev,
          markdown_content: res.data,
          queries: [...(prev.queries || [])], // Ensure queries remains intact
        };

        return newContent;
      });

      setIsRegenerating(false);

      loadContent();
    }
  }

  useEffect(() => {
    if (refMessages.current) {
      refMessages.current.scrollTop = refMessages.current.scrollHeight;
    }
  }, [content]);

  async function handleCopy() {
    navigator.clipboard.writeText(content.markdown_content)
      .then(() => {
        document.getElementById('btnCopy1').innerHTML = 'Copied!';
        setTimeout(function (e) {
          document.getElementById('btnCopy1').innerHTML = `<i class='fa fa-copy'></i> Copy`;
        }, [1000])
        // alert("Text copied to clipboard!");
      })
      .catch(err => {
        //   alert("Failed to copy text.");
      });
  }

  async function loadVersionDown() {
    if (currentVersion > 0) {
      setCurrentVersion(currentVersion - 1);

      setContent((prev) => {
        // Always create a new queries array to avoid mutation
        let newContent = {
          ...prev,
          markdown_content: content.revisions[currentVersion - 1].content_body,
          queries: [...(prev.queries || [])], // Ensure queries remains intact
        };

        return newContent;
      });
    }
  }

  async function loadVersionUp() {
    if (currentVersion < content.revisions.length - 1) {
      setCurrentVersion(currentVersion + 1);

      setContent((prev) => {
        // Always create a new queries array to avoid mutation
        let newContent = {
          ...prev,
          markdown_content: content.revisions[currentVersion + 1].content_body,
          queries: [...(prev.queries || [])], // Ensure queries remains intact
        };

        return newContent;
      });
    }
  }

  async function handleDelete() {
    if (content.revisions && content.revisions.length > 0) {
      let res = await deleteContentVersion(content.revisions[currentVersion].journey_interview_content_version_id);

      if (res.success) {

        loadContent();
      }
    }

    else {
      handleDeleteAll();
    }
  }

  async function handleDeleteAll() {
    let res = await deleteContentItem(content_id != null ? content_id : id);

    if (res.success) {
      navigate(-1);
    }
  }

  return (
    <div>
      <div className='mb-2'>
        <button onClick={() => navigate(-1)} type="button" className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-bold rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
          <FaIcons.FaAngleLeft /> Back
        </button>

        {content != null && content.content_type == 'interview' && (
          <button onClick={() => navigate(`/account/studio/${content.call_id}`)} type="button" className="ml-2 py-3 px-4 inline-flex items-center gap-x-2 text-sm font-bold rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700">
            <FaIcons.FaMicrophoneAlt /> View Interview
          </button>
        )}
      </div>

      {loaded == false && (
        <Spinner />
      )}

      {loaded == true && (
        <>
          <div className="contenteditor__wrapper" style={{ display: "flex", marginBottom: "10px" }}>
            <div className="contenteditor__input">
              <div className="contenteditor__input-messages" ref={refMessages}>

                {content.queries != null && content.queries.filter(x => x.query != '').length == 0 && accessLoaded == true && hasAccess == true && (
                  <div className="contenteditor__input-message max-w-4xl mb-6 mx-auto flex sm:gap-x-4">
                    <span className="shrink-0 inline-flex items-center justify-center size-[38px] rounded-full bg-indigo-600">
                      <span className="text-sm font-medium text-white leading-none">YI</span>
                    </span>

                    <span>
                      <p className="font-medium text-gray-800 dark:text-white">How would you like to edit this content?</p>
                    </span>
                  </div>
                )}

                {accessLoaded == true && hasAccess == false && (
                  <div className="contenteditor__input-message max-w-4xl mb-6 mx-auto flex sm:gap-x-4">
                    <span className="shrink-0 inline-flex items-center justify-center size-[38px] rounded-full bg-indigo-600">
                      <span className="text-sm font-medium text-white leading-none">YI</span>
                    </span>

                    <span>
                      <p className="font-medium text-gray-800 dark:text-white">Upgrade to PRO and start finetuning your content instantly.</p>

                      <p className="mt-2">
                        <Link to='/pricing' className='rounded-md bg-indigo-600 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>Upgrade</Link>
                      </p>
                    </span>
                  </div>
                )}

                {content.queries != null && content.queries.filter(x => x.query != '').map((item, index) => (
                  <div key={index} className="contenteditor__input-message max-w-4xl mb-6 mx-auto flex sm:gap-x-4 items-center">
                    <span className="shrink-0 inline-flex items-center justify-center size-[38px] rounded-full bg-gray-600">
                      <span className="text-sm font-medium text-white leading-none">
                        <FaRegEdit />
                      </span>
                    </span>

                    <div className="contenteditor__input-message-content">
                      <p className="font-medium text-sm text-gray-800 dark:text-white">{item.query}</p>
                    </div>

                  </div>
                ))}
              </div>

              {accessLoaded == true && hasAccess == true && (
                <div className="relative">
                  <textarea
                    id='txtInput'
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="p-4 pb-12 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                    placeholder="How would you like to modify this content?"
                    style={{ overflow: 'hidden', resize: 'none' }}
                  ></textarea>

                  <div className="absolute bottom-px inset-x-px p-2 rounded-b-lg bg-white dark:bg-neutral-900">
                    <div className="flex justify-between items-center">
                      <span className="text-xs px-1 py-0 text-gray-500 dark:text-neutral-400">Leave blank to generate a new version.
                      </span>

                      <div className="flex items-center gap-x-1">
                        {isRegenerating == false && (
                          <button onClick={() => handleAddQuery()} type="button" className="ml-auto inline-flex shrink-0 justify-center items-center size-8 rounded-lg text-white bg-blue-600 hover:bg-blue-500 focus:z-10 focus:outline-none focus:bg-blue-500">
                            <svg className="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                              <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"></path>
                            </svg>
                          </button>
                        )}

                        {isRegenerating == true && (
                          <Spinner />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="contenteditor__output relative">
              <div className="absolute left-5 top-5 inline-flex items-center gap-x-1.5 py-1 px-3 rounded-full text-xs font-medium bg-gray-800 text-white dark:bg-white dark:text-neutral-800">
                {content.output_type}
              </div>

              {content.revisions != null && content.revisions.length > 0 && (
                <div className="contenteditor__output-revisions">
                  <div className="text-right flex items-center justify-end gap-x-2">
                    <span className="text-sm">Version: {currentVersion + 1}</span>

                    <button onClick={() => loadVersionUp()} type="button" className="inline-flex shrink-0 justify-center items-center size-8 rounded-full text-gray-500 hover:bg-blue-100 hover:text-blue-800 focus:z-10 focus:outline-none focus:bg-blue-100 focus:text-blue-800 dark:text-neutral-500 dark:hover:bg-blue-900 dark:hover:text-blue-200 dark:focus:bg-blue-900 dark:focus:text-blue-200">
                      <FaAngleLeft />
                    </button>

                    <button onClick={() => loadVersionDown()} type="button" className="inline-flex shrink-0 justify-center items-center size-8 rounded-full text-gray-500 hover:bg-blue-100 hover:text-blue-800 focus:z-10 focus:outline-none focus:bg-blue-100 focus:text-blue-800 dark:text-neutral-500 dark:hover:bg-blue-900 dark:hover:text-blue-200 dark:focus:bg-blue-900 dark:focus:text-blue-200">
                      <FaAngleRight />
                    </button>

                  </div>
                </div>
              )}

              <div className="contenteditor__output-markdown">

                <div>
                  <p><Markdown>{content.markdown_content}</Markdown></p>
                </div>
              </div>

              <div className="contenteditor__output-menu">
                <div>
                  <button id='btnCopy1' type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm rounded-full border border-transparent text-gray-500 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800" onClick={() => handleCopy()}>
                    <FaRegCopy />
                    Copy
                  </button>
                </div>

                <div>
                  <button id='btnCopy1' type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm rounded-full border border-transparent text-gray-500 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800" onClick={() => handleDelete()}>
                    <FaTrashAlt />
                    Delete Version
                  </button>
                </div>

                <div>
                  <button id='btnCopy1' type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm rounded-full border border-transparent text-gray-500 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800" onClick={() => handleDeleteAll()}>
                    <FaTrash />
                    Delete All
                  </button>
                </div>

              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ContentEditor;
