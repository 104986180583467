
import React from "react";
import UseCaseCard from "../components/UseCaseCard.tsx";
import { personalUseCases, professionalUseCases } from "../components/UseCaseData.ts";

interface UseCaseListProps {
  isPersonal: boolean;
}

const UseCaseList = ({ isPersonal }: UseCaseListProps) => {
  const useCases = isPersonal ? personalUseCases : professionalUseCases;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {useCases.map((useCase, index) => (
        <UseCaseCard
          key={index}
          title={useCase.title}
          description={useCase.description}
          icon={useCase.icon}
          link={useCase.path}
          isPersonal={isPersonal}
        />
      ))}
    </div>
  );
};

export default UseCaseList;
