
import React from "react";
import { Button } from "../components/ui/button.tsx";
import { ArrowRight, CheckCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";

const CallToAction = () => {
  const navigate = useNavigate();
  const benefits = [
    "No credit card required",
    "Free personal plan available",
    "Easy setup in minutes",
    "Works for both professional & personal needs"
  ];

  return (
    <section className="py-16 px-4">
      <div className="container mx-auto max-w-5xl">
        <div className="bg-gradient-to-r from-brand-purple to-brand-blue rounded-2xl overflow-hidden shadow-xl">
          <div className="px-6 py-12 md:p-12 bg-white/10 backdrop-blur-sm">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div>
                <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
                  Ready to hear what people really think?
                </h2>
                <p className="text-white/90 mb-8">
                  Whether you're hiring a team member or finding a babysitter, voice tells you what text can't.
                </p>
                <Button onClick={() => navigate('/join-now')} className="bg-white text-brand-purple hover:bg-white/90 text-lg py-6 px-8 rounded-xl flex items-center">
                  Try for free
                  <ArrowRight className="ml-2 h-5 w-5" />
                </Button>
                <p className="mt-4 text-sm text-white/80">Join 10,000+ people already collecting voice responses</p>
              </div>
              
              <div className="bg-white/20 backdrop-blur-md rounded-xl p-6 md:p-8">
                <h3 className="text-xl font-semibold text-white mb-6">
                  What you'll get:
                </h3>
                <ul className="space-y-3">
                  {benefits.map((benefit, index) => (
                    <li key={index} className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-white mr-3 flex-shrink-0" />
                      <span className="text-white">{benefit}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
