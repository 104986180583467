
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const RsvpConfirmation = () => {
  return (
    <UseCaseTemplate 
      title="RSVP Confirmation with Dietary Info"
      prompt="Create 5 questions for wedding guests to collect their RSVP response, dietary requirements, and a personal message for the couple."
      questions={[
        "Please confirm if you will be attending our wedding celebration on [date] at [venue].",
        "Do you have any dietary restrictions or allergies we should be aware of for the meal service?",
        "Would you like to request any special accommodations to make your experience more comfortable?",
        "Is there a song that would get you on the dance floor that you'd like to request?",
        "We'd love to hear a brief personal message or well wishes for us as we begin this new chapter!"
      ]}
      icon="🎉"
      description="Collect tone, allergies, plus energy for event planning. Voice responses add a personal touch to RSVPs while gathering necessary information in an engaging way."
      isPersonal={true}
    />
  );
};

export default RsvpConfirmation;
