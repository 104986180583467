
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const BabysitterRegistry = () => {
  return (
    <UseCaseTemplate 
      title="Neighborhood Babysitter Registry"
      prompt="Create 5 standardized questions for neighborhood babysitters to introduce themselves to potential families."
      questions={[
        "Please introduce yourself and tell us about your experience with childcare.",
        "What ages of children are you most comfortable caring for, and why?",
        "How do you typically handle bedtime routines and any resistance from children?",
        "What are some of your favorite activities to do with kids of different age groups?",
        "How would you handle an emergency situation, such as a child getting injured while in your care?"
      ]}
      icon="🏘️"
      description="Create a consistent intro format across sitters. Voice responses help families assess personality and compare sitters using standardized questions while maintaining personal connection."
      isPersonal={true}
    />
  );
};

export default BabysitterRegistry;
