import "./styles/reset.css";
import "./styles/App.css";
import "./styles/forms.css";
import "./styles/spinner.css";
import "./styles/loading.css";
import "./styles/card.css";
import "./pages/home/Home.css";

import { lazy, Suspense, useEffect, useState } from "react";

import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import Login from "./pages/signup/Login";
import EmailVerification from "./pages/signup/EmailVerification";
// import RequireAuth from './RequireAuth';
import Account from "./pages/account/Account";
import AccountWrapper from "./pages/account/AccountWrapper";
// import Company from './pages/company/Company';
import Company2 from "./pages/company/Company2";
import ForgotPassword from "./pages/signup/ForgotPassword";
import ResetPassword from "./pages/signup/ResetPassword";
import Terms_S03012023 from "./pages/terms/Terms_S03012023";
import BrowseSellers from "./pages/search/BrowseSellers";
import AccountSetup from "./pages/signup/AccountSetup";


import Confirmation from "./pages/signup/Confirmation";
import DemoConfirmation from "./pages/dashboard/DemoConfirmation";
import { Route, BrowserRouter, Routes } from "react-router-dom";

import Privacy03012023 from "./pages/privacy/Privacy_03012023";
import ExtensionPrivacy from "./pages/privacy/ExtensionPrivacy";
import AddPaymentMethod from "./pages/payment/AddPaymentMethod";

import Policies from "./pages/policy/Policies";
import { Helmet } from "react-helmet";

import { getIP } from "./utils/api";
import Captcha from "./pages/search/Captcha";

// import SellerRescheduleConfirm from "./pages/reschedule/SellerRescheduleConfirm";
// import Home4 from "./pages/home4/Home4";
import SelectPlan from "./pages/plan/SelectPlan";
import PlanCheckout from "./pages/plan/PlanCheckout";
import ContactForm from "./pages/contact/ContactForm";
// import CompanyReviews from "./pages/company/CompanyReviews";
// import EventDashboard from "./pages/events/EventDashboard";
// import CreateEvent from "./pages/events/CreateEvent";
// import EditEvent from "./pages/events/EditEvent";
// import ManageEvent from "./pages/events/ManageEvent";
// import MyEvents from "./pages/events/MyEvents";
// import Redirect from "./pages/events/Redirect";

// import Homepage from "./pages/homepage/Homepage";
// import EventHome from "./pages/events/EventHome";
// import BlogHome from "./pages/contenthub/blog/BlogHome";
import BlogDetails from "./pages/contenthub/blog/BlogDetails";
import BlogDetailsTC from "./pages/contenthub/blog/BlogDetailsTC";
// import Rewards from "./pages/rewards/Rewards";
import JoinNow from "./pages/joinnow/JoinNow";

import NewsPage from './pages/contenthub/news/NewsPage';

import Dashboard from "./pages/dashboard/Dashboard";
import DashboardOutputTypes from "./pages/dashboard/DashboardOutputTypes";
import HomepageJourney from "./pages/homepage/HomepageJourney";
import Upgrade from './pages/upgrade/Upgrade';
import Page from './components/Page';
import Examples from "./pages/examples/Examples";
import JourneyStep from "./pages/dashboard/JourneyStep";
import Interviews from "./pages/dashboard/Interviews";
import JourneyInterviewContent from "./pages/journeyinterviewcontent/JourneyInterviewContent";
import JourneyInterviewContentItem from "./pages/journeyinterviewcontent/JourneyInterviewContenItem";
import JourneyInterviewContentDetails from "./pages/journeyinterviewcontent/JourneyInterviewContentDetails";
import ContentStudioItemEditor from "./pages/dashboard/ContentStudioItemEditor";
import JourneyInterviewCompleted from "./pages/journeyinterviewcontent/JourneyInterviewCompleted";
import JourneyInterviewContentAll from "./pages/journeyinterviewcontent/JourneyInterviewContentAll";
import UploadTranscript from "./pages/uploadtranscript/UploadTranscript";
import TranscriptStudio from "./pages/uploadtranscript/TranscriptStudio";
import PricingLicense from "./pages/pricing/PricingLicense";
import PurchaseLicenceSuccess from "./pages/pricing/PurchaseLicenseSuccess";

import InterviewLinkHome from "./pages/interviewlink/InterviewLinkHome";
import InterviewLinkEditor from "./pages/interviewlink/InterviewLinkEditor";
import InterviewLinkList from "./pages/interviewlink/InterviewLinkList";
import InterviewLinkInvite from "./pages/interviewlink/InterviewLinkInvite";
import InterviewLinkDetails from "./pages/interviewlink/InterviewLinkDetails";
import InterviewLinkLanding from "./pages/interviewlink/InterviewLinkLanding.tsx";

// Import the use case pages
import BabysitterVetting from "./pages/use-cases/BabysitterVetting.tsx";
import DogWalkerIntro from "./pages/use-cases/DogWalkerIntro.tsx";
import RoommateIntro from "./pages/use-cases/RoommateIntro.tsx";
import HouseCleanerOnboarding from "./pages/use-cases/HouseCleanerOnboarding.tsx";
import TutorScreening from "./pages/use-cases/TutorScreening.tsx";
import FamilyMemories from "./pages/use-cases/FamilyMemories.tsx";
import RsvpConfirmation from "./pages/use-cases/RsvpConfirmation.tsx";
import NannyShare from "./pages/use-cases/NannyShare.tsx";
import ParentingCircle from "./pages/use-cases/ParentingCircle.tsx";
import BabysitterRegistry from "./pages/use-cases/BabysitterRegistry.tsx";
import BandInterview from "./pages/use-cases/BandInterview.tsx";

// Professional use cases
import JobInterview from "./pages/use-cases/JobInterview.tsx";
import FreelancerSelection from "./pages/use-cases/FreelancerSelection.tsx";
import VoiceoverAudition from "./pages/use-cases/VoiceoverAudition.tsx";
import UxResearch from "./pages/use-cases/UxResearch.tsx";
import SalesRole from "./pages/use-cases/SalesRole.tsx";
import CustomerTestimonials from "./pages/use-cases/CustomerTestimonials.tsx";
import FounderStory from "./pages/use-cases/FounderStory.tsx";
import ExitInterview from "./pages/use-cases/ExitInterview.tsx";
import ClientDiscovery from "./pages/use-cases/ClientDiscovery.tsx";
import PerformanceReview from "./pages/use-cases/PerformanceReview.tsx";
import RemoteTeamBuilding from "./pages/use-cases/RemoteTeamBuilding.tsx";
import SpeakerSelection from "./pages/use-cases/SpeakerSelection.tsx";
import PodcastGuest from "./pages/use-cases/PodcastGuest.tsx";
import PitchFeedback from "./pages/use-cases/PitchFeedback.tsx";
import TrainingFeedback from "./pages/use-cases/TrainingFeedback.tsx";
import InterviewLinkLanding2 from "./pages/interviewlink/InterviewLinkLanding2.jsx";
import VoiceInterviews from "./pages/VoiceInterviews";
import UploadContent from "./pages/UploadContent";

function App() {
  const [access, setAccess] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [currentIp, setCurrentIp] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [isUserReal, setIsUserReal] = useState(false);
  const [realCheckDone, setRealCheckDone] = useState(false);
  const [availablePoints, setAvailablePoints] = useState(0);

  // const ManageAlerts = lazy(() => import("./pages/managealerts/ManageAlerts"));
  let rand1 = parseInt(Math.random() * 100);

  function setLocalCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  if (access) {
    return (
      <div className="App">
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
              <Header authenticated={authenticated} availablePoints={availablePoints} />
              <Helmet>
                <title>{process.env.REACT_APP_SITE_NAME} - Craft your personal story</title>
                <meta name="description" content="App Description" />
              </Helmet>

              <div>
                <Routes>
                <Route path="/" element={<HomepageJourney />} />

                <Route path="/voice-interviews" element={<VoiceInterviews />} />
                <Route path="/upload-content" element={<UploadContent />} />

                <Route path='/interviewlink/create' element={<InterviewLinkHome />} />
                  <Route path="/interviewlink" element={<InterviewLinkLanding />} />
                  <Route path="/interviewlink2" element={<InterviewLinkLanding2 />} />
                  <Route path='/interviewlink/editor/:id' element={<InterviewLinkEditor /> } />

                  <Route path="/" element={<AccountWrapper view='journeys' />}>
                    <Route path='/interviewlink/list' element={<InterviewLinkList /> } />
                  </Route>

                  <Route path="/" element={<AccountWrapper view='journeys' />}>
                    <Route path='/interviewlink/details/:id' element={<InterviewLinkDetails /> } />
                  </Route>

                  <Route path='/interviewlink/invite/:id' element={<InterviewLinkInvite /> } />
                  {/* <Route path="/sellers" element={<HomeSellers />} /> */}

 {/* Personal Use Cases */}
 <Route path="/use-cases/babysitter-vetting" element={<BabysitterVetting />} />
            <Route path="/use-cases/dog-walker-intro" element={<DogWalkerIntro />} />
            <Route path="/use-cases/roommate-intro" element={<RoommateIntro />} />
            <Route path="/use-cases/house-cleaner" element={<HouseCleanerOnboarding />} />
            <Route path="/use-cases/tutor-screening" element={<TutorScreening />} />
            <Route path="/use-cases/family-memories" element={<FamilyMemories />} />
            <Route path="/use-cases/rsvp-confirmation" element={<RsvpConfirmation />} />
            <Route path="/use-cases/nanny-share" element={<NannyShare />} />
            <Route path="/use-cases/parenting-circle" element={<ParentingCircle />} />
            <Route path="/use-cases/babysitter-registry" element={<BabysitterRegistry />} />
            <Route path="/use-cases/band-interview" element={<BandInterview />} />
            
            {/* Professional Use Cases */}
            <Route path="/use-cases/job-interview" element={<JobInterview />} />
            <Route path="/use-cases/freelancer-selection" element={<FreelancerSelection />} />
            <Route path="/use-cases/voiceover-audition" element={<VoiceoverAudition />} />
            <Route path="/use-cases/ux-research" element={<UxResearch />} />
            <Route path="/use-cases/sales-role" element={<SalesRole />} />
            <Route path="/use-cases/customer-testimonials" element={<CustomerTestimonials />} />
            <Route path="/use-cases/founder-story" element={<FounderStory />} />
            <Route path="/use-cases/exit-interview" element={<ExitInterview />} />
            <Route path="/use-cases/client-discovery" element={<ClientDiscovery />} />
            <Route path="/use-cases/performance-review" element={<PerformanceReview />} />
            <Route path="/use-cases/remote-team-building" element={<RemoteTeamBuilding />} />
            <Route path="/use-cases/speaker-selection" element={<SpeakerSelection />} />
            <Route path="/use-cases/podcast-guest" element={<PodcastGuest />} />
            <Route path="/use-cases/pitch-feedback" element={<PitchFeedback />} />
            <Route path="/use-cases/training-feedback" element={<TrainingFeedback />} />

                  <Route path='/upgrade' element={<Upgrade /> } />
                  <Route path='/pricing' element={<Upgrade /> } />

                  <Route
                    path="/account-setup"
                    element={
                      <AccountSetup setAuthenticated={setAuthenticated} />
                    }
                  />

                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/verify-email" element={<EmailVerification />} />

                  <Route
                    path="/signup/confirmation"
                    element={<Confirmation />}
                  />
                  <Route path="/confirmation" element={<DemoConfirmation />} />

                  <Route path="/" element={<AccountWrapper view='journeys' />}>
                    <Route path="/journeys" element={<Dashboard />} />
                  </Route>

                  <Route path="/" element={<AccountWrapper view='journeys' />}>
                    <Route path="/dashboard" element={<DashboardOutputTypes />} />
                  </Route>

                  {/* <Route path="/" element={<AccountWrapper view='journeys' />}>
                    <Route path="/journeys/:id?" element={<Dashboard />} />
                  </Route> */}

                  {/* <Route path="/" element={<AccountWrapper view='journeys' /> }>
                    <Route path='/journeys/interviews/:id' element={<Interviews />} />
                  </Route> */}

                  <Route path="/" element={<AccountWrapper view='journeys' />}>
                    <Route path="/interview/:id" element={<JourneyStep />} />
                  </Route>

                  <Route path="/policies" element={<Policies />} />
                  <Route path="/login" element={<Login />} />


                  <Route path="/privacy" element={<Privacy03012023 />} />
                  <Route
                    path="/extension/privacy"
                    element={<ExtensionPrivacy />}
                  />
                  <Route path="/contact" element={<ContactForm />} />
                  <Route path="/terms" element={<Terms_S03012023 />} />
                  {/* <Route path='/privacy' element={<Privacy />} /> */}
{/* 
                  <Route path="/saved/buyers" element={<SavedBuyers />} />

                  <Route path="/review/*" element={<Review />} /> */}
                  <Route path="/account/*" element={<Account />} />

                  {/* <Route path="/review/*" element={<Review />} /> */}

                  {/* <Route path='/tech-stack' element={<TechStack />} /> */}

                  {/* <Route
                    path="/profile3/:slug"
                    element={<PublicBuyerProfile />}
                  /> */}

                  {/* <Route path="/about" element={<AboutUs />} /> */}


                  <Route
                    path="/add-payment-method"
                    element={<AddPaymentMethod />}
                  />


               
                  {/* <Route path="/" element={<AccountWrapper view={"feed"} />}>
                    <Route path="/feed" element={<Feed />} />
                    <Route
                      path="/feed/company/:slug"
                      element={<CompanyFeed />}
                    />
                    <Route path="/feed/:slug" element={<Feed />} />
                    <Route path="/join-feed" element={<JoinFeed />} />
                    <Route path="/feeds" element={<FeedChannels />} />
                  </Route>

                  <Route path="/linkedin/redirect" element={<LinkedIn />} />
                  <Route path="/security-check" element={<Captcha />} /> */}

               
                  {/* <Route path='/blog' element={<BlogHome />} /> */}
                  <Route path='/blog' element={<NewsPage /> } />

                  <Route path='/blog/:slug' element={<BlogDetails />} />
                  <Route path='/blog2/:slug' element={<BlogDetailsTC />} />
                 
                  <Route path="/join-now" element={<JoinNow />} />

                  <Route path='/examples' element={<Examples /> } />

                  <Route path="/" element={<AccountWrapper view='studio' />}>
                    <Route path='/content' element={<JourneyInterviewContent /> } />
                    <Route path='/content/editor/:id' element={<ContentStudioItemEditor /> } />
                  </Route>

                  <Route path="/" element={<AccountWrapper view='journeys' />}>
                    <Route path='/content/item/:id' element={<JourneyInterviewContentItem /> } />
                  </Route>

                  <Route path="/" element={<AccountWrapper view='allcontent' />}>
                    <Route path='/all-content' element={<JourneyInterviewContentAll /> } />
                  </Route>

                  <Route path="/" element={<AccountWrapper view='journeys' />}>
                    <Route path='/content/item/:id/details/:type' element={<JourneyInterviewContentDetails /> } />
                  </Route>

                  <Route path="/" element={<AccountWrapper view='interviews' />}>
                    <Route path='/interviews' element={<JourneyInterviewCompleted /> } />
                  </Route>

                  <Route path="/" element={<AccountWrapper view='transcripts' />}>
                    <Route path='/transcript/upload' element={<UploadTranscript /> } />
                    <Route path='/transcript/studio/:id/:outputid' element={<TranscriptStudio /> } />
                  </Route>

                  <Route path="/purchase-license" element={<PricingLicense />} />

                  <Route path="/purchase-license-success" element={<PurchaseLicenceSuccess />} />

                  <Route path="*" element={<Page />} />

                </Routes>
              </div>
          </Suspense>
        </BrowserRouter>

        <Footer></Footer>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default App;
