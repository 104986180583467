
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const FreelancerSelection = () => {
  return (
    <UseCaseTemplate 
      title="Freelancer Selection"
      prompt="Create 5 questions to assess freelance graphic designers' communication style and approach to projects."
      questions={[
        "Can you walk me through your creative process from initial brief to final deliverable?",
        "How do you handle feedback and revision requests from clients?",
        "Tell me about a challenging project where you had to work under tight deadlines or with limited direction.",
        "How do you stay organized and ensure you meet project milestones when juggling multiple clients?",
        "What questions do you typically ask clients before starting a new project to ensure you understand their vision?"
      ]}
      icon="👨‍💻"
      description="Getting a feel for communication and approach. Voice responses help assess how freelancers explain their process and handle client relationships."
      isPersonal={false}
    />
  );
};

export default FreelancerSelection;
