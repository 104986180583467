import './InterviewLinkList.css';
import {useEffect, useState} from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getPayoutTransactions, refreshToken, getUserProfile, getAllIndustries, getDepartmentsForFeed, getInterviewLinks } from '../../utils/api';
import {Helmet} from "react-helmet";
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import { FaClock, FaUser, FaEdit, FaListOl, FaCreditCard, FaRegEnvelopeOpen, FaUsers, FaRegCalendarAlt, FaRegEdit, FaRegCalendarCheck, FaRegCalendarTimes, FaRegCircle, FaRegThumbsUp, FaRegComment, FaPlus, FaUserCircle, FaLinkedin, FaTwitter, FaTimes } from "react-icons/fa";
import close from '../../assets/design/dashboard/close.png';
import Spinner from '../../components/layout/Spinner';
import imgKathie from '../../assets/design/kathie.png';
import DetailsPage from './InterviewLinkListItem';
import FadeIn from '../../utils/fadein';

const InterviewLinkList = () => {
  const [transactions, setTransactions] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [showPost, setShowPost] = useState(false);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isCreatingInterview, setIsCreatingInterview] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const [interviews, setInterviews] = useState([]); // Add state for interviews
  const [selectedInterview, setSelectedInterview] = useState(null);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
      // based on users current role as buyer or seller

    async function fetchInterviews() {
      let res = await getInterviewLinks();

      if (res.success){
        setData(res.data);
        setLoading(false);
      }

      else{
        alert('err');
      }

    }
    fetchInterviews();

  }, []);

  const handleCreateInterview = () => {
    setIsCreatingInterview(true);
    setIsFading(true);
    setTimeout(() => {
      navigate('/interviewlink/create');
    }, 500); // Match the fade-out duration
  };

  const handleEditInterview = (id) => {
    navigate(`/interviewlink/editor/${id}`);
  };

  const handleViewInterview = (id) => {
    console.log('View interview with ID:', id);
    const interview = data.find((item) => item.interview_link_id === id);
    console.log('Selected interview:', interview);
    setSelectedInterview(interview);
  };

  return (
    <>
      <Helmet>
        <title>Interview Links</title>
      </Helmet>

    <div className={`interviewlinkhome ${isFading ? 'fade-out' : ''}`}>
     
<div className="admin-section relative">
  <h2>Interview Links</h2>

  <div className='absolute right-0 top-0 mt-4 mr-4'>
    <button
      className="create-interview-btn"
      onClick={handleCreateInterview}
    >
      + Create Interview
    </button>
  </div>

  {loading && (
    <div>
      <Spinner /> 
    </div>
  )}

  {data.length > 0 ? (
    <table className="interview-table">
      <thead>
        <tr>
          <th>Title</th>
          <th>Invite Sent</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map((interview) => (
          <tr key={interview.interview_link_id}>

          <td style={{maxWidth: '300px'}}>
            <div className='interviewlinklist__date'>
              {new Date(interview.date_created).toLocaleDateString()}
            </div>

            <div className='interviewlinklist__status'>
              {interview.status === 'Published' ? (
                <span className='interviewlinklist__status--active inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-green-600/20 ring-inset'>
                  Published
                </span>
              ) : (
                <span className='interviewlinklist__status--inactive inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-yellow-600/20 ring-inset'>
                  Draft
                </span>
              )}
            </div>

            <div className='interviewlinklist__title' onClick={() => handleEditInterview(interview.reference_id)}>{interview.title}</div>

            <div className='interviewlinklist__description'>
              {interview.description}
            </div>
          </td>

          <td className='center' style={{textAlign: 'center'}}>
          <button
              className="py-1 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 text-gray-500 hover:border-blue-600 hover:text-blue-600 focus:outline-hidden focus:border-blue-600 focus:text-blue-600 disabled:opacity-50 disabled:pointer-events-none"
              onClick={() => handleViewInterview(interview.interview_link_id)}
            >
              View
            </button>

            <div className='text-sm font-medium text-gray-500 center mt-2'>
              {interview.invite_count} Sent
            </div>

            <div className='text-sm font-medium text-gray-500 center mt-2'>
              {interview.pending_count} Pending
            </div>

            <div className='text-sm font-medium text-gray-500 center mt-2'>
              {interview.complete_count} Complete
            </div>
          </td>

          <td>

            <button
              className="py-1 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 text-gray-500 hover:border-blue-600 hover:text-blue-600 focus:outline-hidden focus:border-blue-600 focus:text-blue-600 disabled:opacity-50 disabled:pointer-events-none"
              onClick={() => handleEditInterview(interview.reference_id)}
            >
              <FaRegEdit /> Edit
            </button>
          </td>
        </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <div className="no-records-message">
      No interview links available.
    </div>
  )}
</div>

{selectedInterview && (
  <FadeIn delay={200} duration={200}>
    <div className='overlay'></div>
    <div className='modal feed__modal-post' style={{width: '60%', marginLeft: '-30%'}}>
      <div className='modal__close' onClick={() => setSelectedInterview(null)}>
        <FaTimes />
      </div>

      <DetailsPage
    interview={selectedInterview}
    onClose={() => setSelectedInterview(null)}
  />
    </div>
  </FadeIn>
)}
    
    </div>

    {showPost && (
      <>
        <div className='overlay'></div>

        <div className='modal feed__modal-post'>
          <div className='modal__close' onClick={() => setShowPost(false)}>
            <img src={close} />
          </div>
          
          <div className='feed__modal-post-header'>
            Start a conversation
          </div>

          <div>
            <textarea className='input feed__textarea' placeholder='What would you like to ask/answer/post?'>

            </textarea>
          </div>

          <div>
            <button className='feed__modal-post-btn'>Post</button>
          </div>
        </div>
      </>
    )}
    </>
  )
}

  export default InterviewLinkList;