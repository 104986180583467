import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getJourneyInterviews, getJourneySteps, getOutputTypes } from '../../utils/api/admin';
import JourneyStepEdit from './JourneyStepEdit';
import JourneyStepAdd from './JourneyStepAdd';
import OutputTypeAdd from './OutputTypeAdd';
import OutputTypeEdit from './OutputTypeEdit';
import OutputTypeRenderer from './Components/OutputTypeRenderer';
import * as FaIcons from 'react-icons/fa';

function OutputTypes() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  const IconDisplay = ({ iconName }) => {
    if (iconName == null || iconName.length == 0){
      return <p>No icon.</p>
    }
  
    // Dynamically get the icon component from the FaIcons object
    const IconComponent = FaIcons[iconName];
  
    // If the icon is found, render it
    if (IconComponent) {
      return <IconComponent />;
    }
  
    // Fallback if icon is not found
    return <p>Icon not found</p>;
  };

  useEffect(() => {
    async function loadData(){
      let res = await getOutputTypes();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }
    }

    loadData();
  }, []);

  async function loadData(){
    let res = await getOutputTypes();

    if (res.success){
      setData(res.data);
      setLoaded(true);
    }
  }

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleEditClick(id){
    setSelectedId(id);
    setShowModal(true);
  }

  return (
    <>
      <Helmet>
        <title>Admin - Output Types</title>
      </Helmet>

      <h1>Output Types {`(${data.length})`}</h1>

      <div className='mt-20'>
        <span className='btn' onClick={(e) => setShowAdd(true)}>Add New Type</span>
      </div>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
          <table className='admindashboard__table table'>
            <tr>
              <th></th>
              <th></th>
              <th>Name</th>
              <th>Description</th>
              <th>Output prompt</th>
              <th>Status</th>
              <th>Free</th>
              <th></th>
            </tr>

            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.output_type_id}</td>
                <td>
                  <IconDisplay iconName={item.fa_icon} />
                </td>
                <td><strong>{item.name}</strong></td>
                <td>{item.description}</td>
                <td>{item.output_prompt}</td>
                <td>{item.status}</td>
                <td>{item.is_free == true ? 'Free': 'Pro'}</td>
                <td>
                  <button className='btn' onClick={() => handleEditClick(item.output_type_id)}>edit</button>
                </td>
              </tr>
            ))}
          </table>
       </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal' style={{width: '100%', margin: '0px', top: '0px', left: '0px', height: '100%', maxHeight: '100%'}}>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Edit Output Type</h2>

            <div className='mt-10'>
              <OutputTypeEdit output_type_id={selectedId} setShowModal={setShowModal} loadData={loadData} />
            </div>
          </div>
        </>
      )}

    {showAdd && (
        <>
          <div className='overlay'></div>
          <div className='modal' style={{width: '80%', marginLeft: '-40%'}}>
            <div className='modal__close' onClick={() => setShowAdd(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Add new output type</h2>

            <div className='mt-10'>
              <OutputTypeAdd setShowModal={setShowAdd} loadData={loadData} />
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default OutputTypes;