
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const RoommateIntro = () => {
  return (
    <UseCaseTemplate 
      title="New Roommate Intro"
      prompt="Generate 5 questions to understand a potential roommate's lifestyle and habits before meeting in person."
      questions={[
        "Could you describe your typical weekday and weekend routines?",
        "How would you describe your cleanliness habits and expectations for shared spaces?",
        "What are your thoughts on having guests over, especially overnight guests?",
        "How do you prefer to handle shared expenses and household responsibilities?",
        "What are some of your interests and hobbies that might impact our shared living space?"
      ]}
      icon="🏠"
      description="Capture vibe and lifestyle habits in a low-pressure way. Voice responses reveal personality, communication style, and potential compatibility as housemates."
      isPersonal={true}
    />
  );
};

export default RoommateIntro;
