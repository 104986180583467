
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const FounderStory = () => {
  return (
    <UseCaseTemplate 
      title="Founder Story for PR"
      prompt="Create 5 questions to capture an authentic founder story for press releases and company about page."
      questions={[
        "What inspired you to start this company, and what problem were you initially trying to solve?",
        "Can you share a pivotal moment or challenge in your entrepreneurial journey that shaped the company's direction?",
        "What core values drive your business decisions, and how do they reflect your personal philosophy?",
        "How has your vision for the company evolved since you first started, and what surprised you most along the way?",
        "What impact do you hope your company will have in the long term, both in your industry and beyond?"
      ]}
      icon="🚀"
      description="Capturing authenticity and passion in their own words. Voice responses preserve the personality, emotion, and unique perspective that make founder stories compelling."
      isPersonal={false}
    />
  );
};

export default FounderStory;
