
import React from "react";
import UseCaseTemplate from "../../components/UseCaseTemplate.tsx";

const TrainingFeedback = () => {
  return (
    <UseCaseTemplate 
      title="Training Feedback"
      prompt="Create 5 questions for new employees to provide feedback on their onboarding and initial training experience."
      questions={[
        "What aspects of the training program were most helpful in preparing you for your role?",
        "Were there any topics or skills you wish had been covered more thoroughly during onboarding?",
        "How would you describe the pace of the training: too fast, too slow, or just right?",
        "What questions or uncertainties do you still have after completing the initial training?",
        "How could we improve the onboarding experience for future new team members?"
      ]}
      icon="🎓"
      description="New hires recording impressions or confusion points. Voice responses capture immediate reactions and questions that might be forgotten or filtered out in later written surveys."
      isPersonal={false}
    />
  );
};

export default TrainingFeedback;
